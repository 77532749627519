import * as user from './user'
import * as document from './document'
import * as customDocument from './customDocument'
import * as documents from './documents'
import * as companies from './companies'
import * as files from './files'
import * as registry from './registry'
import * as helpers from './helpers'
import * as config from './config'
import * as sign from './sign'
import * as contacts from './contacts'

export default {
  user,
  document,
  documents,
  companies,
  files,
  registry,
  helpers,
  config,
  sign,
  contacts,
  customDocument
}
