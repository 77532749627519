import { connect } from 'react-redux'
import actions from '../../store/actions'
import Table from './Table'

const mapStateToProps = state => ({
  files: state.files,
  config: state.config,
  statuses: state.documents.documentsStatuses,
  user: state.user.data
})

const mapDispatchToProps = dispatch => ({
  sendDocumentToUser: data => dispatch(actions.documents.sendDocumentToUser(data)),
  removeDocumentById: (id, type) => dispatch(actions.document.removeDocumentById(id, type)),
  removeDocumentsByIds: (ids, type) => dispatch(actions.documents.removeDocumentsByIds(ids, type)),
  verifyFile: data => dispatch(actions.files.verifyFile(data)),
  deleteContact: (id, history) => dispatch(actions.contacts.deleteContact(id, history)),
  getContacts: params => dispatch(actions.contacts.getContacts(params)),
  getCustomDocuments: (params) => dispatch(actions.customDocument.getCustomDocuments(params))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Table)
