import React, { useReducer, useRef, useState } from 'react'
import useForm from 'rc-form-hooks'
import axios from 'axios'
import fileDownload from 'js-file-download'
import { Base64 } from 'js-base64'

import {
  Form,
  Icon,
  Input,
  message,
  Modal,
  notification
} from 'antd'

import {
  Button,
  ModalAttributeCertificate
} from '../../../../components'

import { normal } from './static'

import { api } from '../../../../services'
import { checkBrowser } from '../../../../utils'

import { Upload } from '../../../../components/UploadFiles/styled'

import { messageErrorCert } from '../../../../helpers'

import {
  Action,
  List
} from './styled'

const { TextArea } = Input

const initialState = {
  isModalVisible: false,
  modalType: '',
  selected_file: null,
  status: null
}

function reducer (state, action) {
  switch (action.type) {
    case 'SHOW_MODAL':
      return {
        ...state,
        isModalVisible: true,
        modalType: action.payload.modal_type,
        selected_file: action.payload.selected_file,
        status: action.payload.status
      }
    case 'HIDE_MODAL':
      return {
        ...initialState
      }
    default:
      return state
  }
}

export default ({
  showList,
  file,
  getDocument,
  changeStatus,
  verifyFile,
  user,
  config,
  params: {
    documentId,
    canBeSigned,
    messageId
  }
}) => {
  const [attachFile, setAttachFile] = useState({})
  const [modalACVisible, setModalACVisible] = useState(false)
  const inputRef = useRef()

  const [state, dispatch] = useReducer(
    reducer,
    initialState
  )

  const {
    isModalVisible,
    modalType,
    status
  } = state

  let commentAttachment = null

  const { getFieldDecorator, validateFields, values } = useForm()

  const handleAgreeFile = e => {
    e.preventDefault()
    const agreeMessage = values.agree_message === undefined ? '' : values.agree_message

    if (status !== 2) {
      return null
    }

    const data = new FormData()

    data.append('attachment_id', file.id)
    data.append('status', 4)
    data.append('color', '#808000')
    data.append('name', 'Согласовано')
    data.append('comment', agreeMessage)
    data.append('comment_file', attachFile)

    changeStatus(data)
      .then(({ data }) => {
        if (data.success) {
          commentAttachment = null
          message.success('Файл успешно согласован')
          dispatch({ type: 'HIDE_MODAL' })
          window.location.reload()
        } else {
          throw new Error(data.error)
        }
      })
      .catch(error => {
        message.error(error.message)
      })
  }

  const handleDeclineFile = e => {
    e.preventDefault()

    validateFields()
      .then(() => {
        if (status === 2 || status === 3) {
          const data = new FormData()

          data.append('attachment_id', file.id)
          data.append('status', 6)
          data.append('color', '#800000')
          data.append('name', 'Отклонен')
          data.append('comment', values.decline_message)
          data.append('comment_file', attachFile)
          changeStatus(data)
            .then(({ data }) => {
              if (data.success) {
                commentAttachment = null
                message.success('Файл успешно отклонен')
                dispatch({ type: 'HIDE_MODAL' })
                window.location.reload()
              } else {
                throw new Error(data.error)
              }
            })
            .catch(error => {
              message.error(error.message)
            })
        } else {
          return null
        }
      })
  }

  const onCommentAttachmentChange = event => {
    setAttachFile(event.target.files[0])

    commentAttachment = event.target.files[0]

    if (document.getElementById('commentFileNameDecline')) {
      document.getElementById('commentFileNameDecline').innerText = commentAttachment.name
    } else {
      document.getElementById('commentFileNameAccept').innerText = commentAttachment.name
    }
  }

  const clientId = config.data.co_brand_config ? config.data.co_brand_config.client_id : process.env.REACT_APP_SIM_SCEP_CLIENT_ID
  const callback = config.data.co_brand_config ? config.data.co_brand_config.callback : process.env.REACT_APP_SIM_SCEP_CALLBACK

  const newPageUrl = `${process.env.REACT_APP_SIM_SCEP_URL}?` +
    `client_id=${clientId}&` +
    `response_type=code&` +
    `state=${Base64.encode(JSON.stringify({
      'co_brand_name': config.data.co_brand_config ? 'mts' : 'quidox',
      'user_id': user.data.id
    }))}&` +
    `authentication=phone&` +
    `scope=sign&` +
    `redirect_uri=${callback}`

  const handleSimVerifyFile = (item) => {
    try {
      api.documents.attachmentSimSign(item.id)
        .then(({ data }) => {
          if (data.success) {
            window.open(data.data, '_self')
          } else {
            throw new Error(data.error)
          }
        })
        .catch(error => {
          message.error(error.message)
          window.open(newPageUrl, '_self')
        })
    } catch (error) {
      notification['error']({
        message: error.message
      })

      window.open(newPageUrl, '_self')
    }
  }

  const handleTZIVerifyFile = (item) => {
    try {
      api.files.getBase64File(item.id)
        .then(({ data }) => {
          let sign = {}
          sign.data = data.data.encoded_base64_file
          sign.isDetached = true
          sign.token_qdx = window.localStorage.getItem('authToken') || window.sessionStorage.getItem('authToken')
          sign.token_qdx = 'Bearer ' + sign.token_qdx

          const request = axios.post('http://127.0.0.1:8083/sign', sign)
            .then(({ data }) => {
              if (data.cms) {
                let signObj = {}
                signObj.raw_sign = data.cms
                signObj.comment = 'Подписано при помощи сервиса НИИ ТЗИ'
                axios.post(`${process.env.REACT_APP_BASE_URL}/attachment/${item.id}/sign/add`, signObj, {
                  headers: {
                    'Authorization': sign.token_qdx
                  }
                })
                  .then(({ data }) => {
                    getDocument()
                    message.success('Подпись успешно выработана')
                  })
                  .catch(function (error) {
                    messageErrorCert()
                  })
              }
            })
            .catch(function (error) {
              messageErrorCert()
            })
        })
        .catch(() => {
          messageErrorCert()
        })
    } catch (error) {
      messageErrorCert()
    }
  }
  const coBrand = user.co_brand_config && user.co_brand_config

  const handleVerifyFile = (item, documentId, status) => {
    if ((checkBrowser('ie') && status === 3) || canBeSigned) {
      api.files.getBase64File(item.id)
        .then(({ data }) => {
          if (data.success) {
            try {
              const isIE = /*@cc_on!@*/false || !!document.documentMode
              window.pluginLoaded()
              const sertificationObject = window.signProcess(data.data.encoded_base64_file, item.hash_for_sign)
              const newData = {
                id: item.id,
                hash: sertificationObject.signedData,
                data: sertificationObject.verifiedData,
                hash_for_sign: sertificationObject.hex,
                status: canBeSigned ? null : 5
              }

              api.documents.attachmentSignCanConfirm({
                key: sertificationObject.verifiedData.key,
                attachment_id: item.id
              })
                .then(({ data }) => {
                  if (data.success) {
                    verifyFile(newData)
                      .then((response) => {
                        if (response.success) {
                          message.success('Файл успешно подписан!')
                          getDocument()
                          window.pluginClosed()
                        } else {
                          messageErrorCert()
                        }
                      })
                      .catch(error => {
                        console.error(error.message)
                        messageErrorCert()
                      })
                  } else {
                    message.error(data.error)
                    // throw new Error(data.error)
                  }
                })
                .catch(error => {
                  console.error(error.message)
                  messageErrorCert()
                })
            } catch (error) {
              console.error(error.message)
              notification['error']({
                message: 'Списки отозванных сертификатов  (СОСы) устарели. ' +
                  'Обновите их в Персональном менеджере сертификатов в пункте меню "Сервис". ' +
                  'Как обновить СОСы подробно описано ТУТ'
              })
            }
          } else {
            message.error(data.error)
          }
        })
        .catch(error => {
          notification['error']({
            message: error.message
          })
        })
    } else {
      notification['error']({
        message: "Произошла ошибка подписания документа. Пожалуйста, обратитесь к администратору"
      })
    }
  }

  const downloadFile = file => {
    let token = window.localStorage.getItem('authToken') || window.sessionStorage.getItem('authToken')
    let path = token ? file.original_path : file.original_path + `?shared_token=${btoa(process.env.REACT_APP_SECRET_KEY)}`
    axios.get(path, {
      'responseType': 'arraybuffer',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Access-Control-Expose-Headers': 'Content-Disposition,X-Suggested-Filename'
      }
    })
      .then(({ data }) => {
        if (data) {
          fileDownload(data, file.name)
          message.success('Файл успешно загружен!')
        }
      })
      .catch(error => {
        message.error(error.message)
      })
  }

  const statusId = file.status && file.status.status_data.id
  const simButtonName = config.data.co_brand_config ? config.data.co_brand_config.co_brand_name : 'Mobile'

  return (
    <>
      <ModalAttributeCertificate
        status={canBeSigned ? null : 5}
        documentId={documentId}
        file={file}
        isNew={false}
        visible={modalACVisible}
        onCancel={() => setModalACVisible(false)}
      />

      {showList ? (
        <List>
          {statusId !== 5 && ![3, 4].includes(messageId) && (
            <>
              <List.Item disabled={[1, 2, 4, 5, 6].includes(statusId)}>
                {[1, 2, 4, 5, 6].includes(statusId) ? (
                  <List.Item.Link>Подписать в браузере</List.Item.Link>
                ) : (
                  <List.Item.Link onClick={() => handleVerifyFile(file, documentId, statusId)}>
                    Подписать в браузере
                  </List.Item.Link>
                )}
              </List.Item>

              <List.Item disabled={[1, 2, 4, 5, 6].includes(statusId)}>
                {[1, 2, 4, 5, 6].includes(statusId) ? (
                  <List.Item.Link>{simButtonName} ID</List.Item.Link>
                ) : (
                  <List.Item.Link onClick={() => handleSimVerifyFile(file, documentId, statusId)}>
                    {simButtonName} ID
                  </List.Item.Link>
                )}
              </List.Item>

              <List.Item disabled={[1, 2, 4, 5, 6].includes(statusId)}>
                {[1, 2, 4, 5, 6].includes(statusId) ? (
                  <List.Item.Link>ТЗИ</List.Item.Link>
                ) : (
                  <List.Item.Link onClick={() => handleTZIVerifyFile(file)}>ТЗИ</List.Item.Link>
                )}
              </List.Item>

              <List.Item disabled={[1, 2, 4, 5, 6].includes(statusId)}>
                {[1, 2, 4, 5, 6].includes(statusId) ? (
                  <List.Item.Link>АС</List.Item.Link>
                ) : (
                  <List.Item.Link onClick={() => setModalACVisible(true)}>АС</List.Item.Link>
                )}
              </List.Item>
            </>)}

          {statusId !== 5 && ![1, 3, 4, 9, 10].includes(messageId) && (
            <>
              <List.Item disabled={[1, 3, 4, 5, 6].includes(statusId)}>
                {[1, 3, 4, 5, 6].includes(statusId) ? (
                  <List.Item.Link>
                    <Icon type='check-circle' /> Согласовать
                  </List.Item.Link>
                ) : (
                  <List.Item.Link onClick={() => dispatch({
                    type: 'SHOW_MODAL',
                    payload: {
                      isModalVisible: true,
                      modal_type: 'agree',
                      status: statusId,
                      selected_file: file
                    }
                  })}>
                    <Icon type='check-circle' /> Согласовать
                  </List.Item.Link>
                )}
              </List.Item>

              <List.Item
                disabled={[1, 4, 5, 6].includes(statusId)}
                type='danger'
              >
                {[1, 4, 5, 6].includes(statusId) ? (
                  <List.Item.Link>
                    <Icon type='stop' /> Отклонить
                  </List.Item.Link>
                ) : (
                  <List.Item.Link onClick={() => dispatch({
                    type: 'SHOW_MODAL',
                    payload: {
                      isModalVisible: true,
                      modal_type: 'decline',
                      status: statusId,
                      selected_file: file
                    }
                  })}>
                    <Icon type='stop' /> Отклонить
                  </List.Item.Link>
                )}
              </List.Item>
            </>)}
        </List>
      ) : (
        <Action title='Скачать файл'>
          <Action.Icon
            key={4}
            type='download'
            style={normal}
            onClick={() => downloadFile(file)}
          />
        </Action>
      )}

      {isModalVisible && (
        <Modal
          visible
          onCancel={() => dispatch({ type: 'HIDE_MODAL' })}
          footer={false}
        >
          <>
            {modalType === 'decline' && (
              <Form onSubmit={handleDeclineFile}>
                <Form.Item label='Укажите причину отказа'>
                  {getFieldDecorator('decline_message', {
                    rules: [{ required: true, message: 'Текст отклонения является обязательным' }]
                  })(
                    <TextArea
                      style={{ resize: 'none' }}
                      autoSize={{ minRows: 3, maxRows: 5 }}
                      size='large'
                      placeholder='Укажите причину отказа'
                    />
                  )}
                </Form.Item>

                <Form.Item>
                  <Upload.Button
                    brand={coBrand}
                    type='primary'
                    htmlFor='commentAttachment'
                    ghost
                  >
                    <Icon type='upload' style={{ marginRight: 10 }} />
                    Прикрепить файл(ы)
                    <Upload.Input
                      type='file'
                      id='commentAttachment'
                      ref={inputRef}
                      onChange={onCommentAttachmentChange}
                      hidden
                    />
                  </Upload.Button>

                  <label id={'commentFileNameDecline'} />
                </Form.Item>

                <Button type='primary' htmlType='submit'>Отказать</Button>
              </Form>)}
          </>

          <>
            {modalType === 'agree' && (
              <Form onSubmit={handleAgreeFile}>
                <Form.Item label='Введите текст согласования'>
                  {getFieldDecorator('agree_message', {})(
                    <TextArea
                      style={{ resize: 'none' }}
                      autoSize={{ minRows: 3, maxRows: 5 }}
                      size='large'
                      placeholder='Введите текст согласования'
                    />
                  )}
                </Form.Item>

                <Form.Item>
                  <Upload.Button
                    brand={coBrand}
                    type='primary'
                    htmlFor='commentAttachment'
                    ghost
                  >
                    <Icon type='upload' style={{ marginRight: 10 }} />
                    Прикрепить файл(ы)
                    <Upload.Input
                      type='file'
                      id='commentAttachment'
                      ref={inputRef}
                      onChange={onCommentAttachmentChange}
                      hidden
                    />
                  </Upload.Button>

                  <label id={'commentFileNameAccept'} />
                </Form.Item>

                <Button type='primary' htmlType='submit'>Согласовать</Button>
              </Form>)}
          </>
        </Modal>)}
    </>
  )
}
