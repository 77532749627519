import { connect } from 'react-redux'
import actions from '../../store/actions'

import GlobalSearchPage from './GlobalSearchPage'

const mapStateToProps = state => ({
  user: state.user.data,
  isFetchingResults: state.documents.isFetching,
  results: state.documents.documents || [],
  currencies: state.documents.currencies,
  attachmentsTypes: state.attachments.attachmentsTypes
})

const mapDispatchToProps = dispatch => ({
  getDocumentsByActiveCompanyId: (id, params) => dispatch(actions.documents.getDocumentsByActiveCompanyId(id, params)),
  resetDocumentsList: () => dispatch(actions.documents.resetDocumentsList())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GlobalSearchPage)
