import React from 'react'
import classNames from 'classnames'

import './ContentBlock.scss'

const ContentBlock = ({ logWrapp, children }) => {
  const mainLayoutClassName = classNames(
    'main-content',
    {
      'main-content__public': logWrapp
    }
  )

  return (
    <div className={mainLayoutClassName}>{children}</div>
  )
}

export default ContentBlock
