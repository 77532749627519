import styled, { css } from 'styled-components'
import { Typography } from 'antd'
import { Header } from '../SingleDocumentPage/styled'
import { styleguide } from '../../constants'

const { Title } = Typography
const { Paragraph } = Typography
const { colors } = styleguide

const Layout = styled.div`
  padding: 2rem;
`

Layout.Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-bottom: 2rem;

  && {
    flex: 1;

    .ant-input-search {
      margin: 0 2rem;
    }
  }
`

Layout.Title = styled(Title)`
  && {
    margin-bottom: 0;
    white-space: nowrap;
  }
`

Header.Inner = styled.div`
`

Header.Secondary = styled.span`
  color: ${colors.gray.dark};
  font-size: 1.2rem;
`

const Details = styled.div`
  border-bottom: .1rem solid ${colors.gray.middle};
  display: flex;
  padding-bottom: 2rem;
  margin-bottom: 2.8rem;

  ${({ noStyle }) => noStyle && css`
    border: 0;
    padding-bottom: 0;
    margin-bottom: 0;

    ${Details.Inner} {
      flex: initial;
      padding-left: 0;

      &:not(:first-child) {
        border: 0;
        margin-left: 6.4rem;
      }
    }
  `}
`

Details.Inner = styled.div`
  flex: 1;

  &:not(:first-child) {
    border-left: .1rem solid ${colors.gray.middle};
    padding-left: 1.2rem;
  }
`

Details.Item = styled.div`
  display: flex;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  ${({ align }) => align === 'vertical' && css`
    flex-direction: column;
  `}
`

Details.Label = styled.div`
  width: 14rem;
  min-width: 14rem;
  color: ${colors.gray.dark};
`

Details.Info = styled.div`
`

Details.Text = styled(Paragraph)`
  && {
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }

  .ant-btn {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: .8rem;

    .anticon {
      font-size: 1.4rem;
    }
  }
`

Details.Secondary = styled.span`
  color: ${colors.gray.dark};
`

const Attached = styled.div`
  .ant-tag {
    &:last-child {
      margin-right: 0;
    }
  }
`

export {
  Layout,
  Header,
  Details,
  Attached
}
