import styled from 'styled-components'

const LayoutInner = styled.div`
  display: flex;

  justify-content: space-between;
  flex: 1;
  overflow-y: hidden;
  padding: 0 1.2rem 1.2rem 1.2rem;
`
const LayoutInnerMain = styled.div`
  overflow-y: scroll;
`

export {
  LayoutInner,
  LayoutInnerMain
}