import React from 'react'
import { Button, Tooltip } from 'antd'

import { Column } from './styled'

export default ({
  onSend,
  onShare,
  onCreateRule,
  onDownload,
  onRoamingSend
}) => (
  <Column>
    <Column.Item>
      <Tooltip
        title="Отправить"
        placement="topRight"
        arrowPointAtCenter
      >
        <Button
          type="outlined"
          icon="mail"
          onClick={onSend}
          //onClick={() => history.push(`/new-document?${queryString.stringify({ contacts: [details.contact_data.user_email] }, { arrayFormat: 'bracket' })}`)}
        />
      </Tooltip>
    </Column.Item>

    <Column.Item>
      <Tooltip
        title="Роуминг"
        placement="topRight"
        arrowPointAtCenter
      >
        <Button
          type="outlined"
          icon="trademark"
          disabled
          onClick={onRoamingSend}
          //onClick={() => history.push(`/new-document?${queryString.stringify({ contacts: [details.contact_data.user_email] }, { arrayFormat: 'bracket' })}`)}
        />
      </Tooltip>
    </Column.Item>

    <Column.Item>
      <Tooltip
        title="Скачать"
        placement="topRight"
        arrowPointAtCenter
      >
        <Button
          type="outlined"
          onClick={onDownload}
          icon="download"
        />
      </Tooltip>
    </Column.Item>

    <Column.Item>
      <Tooltip
        title="Поделиться"
        placement="topRight"
        arrowPointAtCenter
      >
        <Button icon="share-alt"
                type="outlined"
                onClick={onShare}/>
      </Tooltip>
    </Column.Item>

    <Column.Item>
      <Tooltip
        title="Создать правило"
        placement="topRight"
        arrowPointAtCenter
      >
        <Button icon="profile"
                disabled
                type="primary"
                onClick={onCreateRule}/>
      </Tooltip>
    </Column.Item>
  </Column>
)
