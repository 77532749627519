import React, { useReducer, useState } from 'react'

import { api } from '../../services'

import {
  Row,
  Col,
  Icon,
  notification,
  Typography
} from 'antd'

import {
  ESCCheckContext,
  initialState,
  reducer
} from './context'

import { DropZone } from './internal'

import { Button } from '../../components'

import { Layout } from './styled'

const {
  Title,
  Paragraph
} = Typography

const dropZones = [
  {
    type: 'file',
    text: 'Перетяните подписанный документ (.pdf/.doc файл)'
  },
  {
    type: 'sign',
    text: 'Перетяните подпись ЭЦП (.p7s файл)'
  }
]

export default function EcsCheckingPage () {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [fetch, setFetch] = useState(false)

  const handleVerifySignature = () => {
    setFetch(true)

    const data = new FormData()
    data.append('file', file.data)
    data.append('sign', sign.data)

    const config = {
      headers: { 'Content-Type': 'multipart/form-data' }
    }

    api.sign.checkSign(data, config)
      .then(response => {
        const { data: { data, success, error } } = response
        if (success) {
          setFetch(false)
          dispatch({ type: 'TOGGLE_CHECK' })

          const sharedToken = `?shared_token=${btoa(process.env.REACT_APP_SECRET_KEY)}`

          window.open(data + sharedToken, '_blank')
        } else {
          throw new Error(error)
        }
      })
      .catch(error => {
        setFetch(false)
        notification.error({
          message: error.message
        })
      })
  }

  const { file, sign, isCheckSuccess } = state

  return (
    <ESCCheckContext.Provider value={{ state, dispatch }}>
      <Layout>
        <Layout.Inner>
          <Title level={3}>Проверка подписи</Title>
          <Paragraph>C помощью встроенного сервиса «Проверка ЭЦП» можно проверить электронные цифровые подписи (ЭЦП) в электронном документе.</Paragraph>

          <Row style={{ marginTop: '3rem' }} gutter={[24, 24]}>
            {isCheckSuccess ? (
              <Col span={12} offset={6}>
                <Layout.Finished>
                  <Icon type='check-circle' />
                  <Layout.Finished.Label>ЭЦП документа верна</Layout.Finished.Label>
                </Layout.Finished>
              </Col>
            ) : (
              <>
                {dropZones.map(({ type, text }, idx) => (
                  <Col md={12} key={idx}>
                    <DropZone text={text} type={type} />
                  </Col>
                ))}
              </>
            )}
          </Row>

          {(file.isLoaded && sign.isLoaded) && (
            <Layout.Check>
              <Button
                type='primary'
                loading={fetch}
                onClick={() => handleVerifySignature()}
              >
                Проверить подлинность документа
              </Button>
            </Layout.Check>)}
        </Layout.Inner>
      </Layout>
    </ESCCheckContext.Provider>
  )
}
