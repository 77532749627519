import React, { useState } from 'react'
import moment from 'moment'

import { Statistic } from 'antd'

import { Button } from '../'

import { Layout } from './styled'

const { Countdown } = Statistic

export default function ({
  user: { data },
  config
}) {
  const [isVisible, setVisible] = useState(true)

  const expiredAt = Object.keys(data).length && data.active_company_object ? data.active_company_object.tarification.expired_at : 0
  const smartdocUri = config.data.co_brand_config && config.data.co_brand_config.logout_uri

  return (
    <Layout>
      {isVisible ? (
        <Layout.Inner>
          <Countdown
            title='До окончания действующего пакета услуг осталось'
            value={moment.utc(expiredAt)}
            format='D дней'
          />

          <Layout.Inner.Actions>
            <Button
              type='primary'
              onClick={() => window.open(`${smartdocUri}/services`, '_self')}
              block
            >
              Подключить новый
            </Button>

            <Button
              type='primary'
              onClick={() => window.open(smartdocUri ? `${smartdocUri}/free-functional` : 'https://help.quidox.by/article/4712', '_self')}
              ghost
              block
            >
              Если я не приобрету пакет
            </Button>
          </Layout.Inner.Actions>

          <Layout.Hide
            type='fullscreen-exit'
            onClick={() => setVisible(false)}
          />
        </Layout.Inner>
      ) : (
        <Button
          type='primary'
          icon='fullscreen'
          onClick={() => setVisible(true)}
          block
        >
          Срок действия тарифа
        </Button>
      )}
    </Layout>
  )
}
