import React from 'react'
import { useHistory } from 'react-router-dom'

import {
  Modal,
  Typography
} from 'antd'

import { Button } from '../../../../components'

import { Layout } from './styled'

const { Title } = Typography

export default ({
  visible,
  onCancel
}) => {
  const history = useHistory()

  return (
    <Modal
      width={550}
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Layout>
        <Title level={3}>Нужна помощь или консультация?</Title>

        <Layout.Content>
          <iframe width='470' height='315' src='https://www.youtube.com/embed/oYZq8ECz0sM' title='YouTube video player' frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullScreen />
        </Layout.Content>

        <Layout.Actions>
          <Button onClick={() => history.push('/contacts')}>Нет</Button>
          <Button type='primary'>Записаться на консультацию</Button>
        </Layout.Actions>
      </Layout>
    </Modal>
  )
}
