import React from 'react'
import { Redirect, Router, Switch } from 'react-router-dom'
import { connect } from 'react-redux'

import history from './history'

import 'typeface-roboto'

import { GlobalStyles } from './styles'

import { PrivateRoute, PublicRoute } from './components'

import {
  AttachmentsPage,
  CompaniesPage,
  CompleteRegistrationForm,
  CompleteResetForm,
  ContactDetailsPage,
  ContactsImportPage,
  ContactsPage,
  CustomDocumentCreatePage,
  CustomDocumentDetailsPage,
  CustomDocumentsPage,
  DocumentsPage,
  EcsCheckingPage,
  EsignFirstStepPage,
  EsignLoginPage,
  ExternalRedirectPage,
  GlobalSearchPage,
  LoginPage,
  NewDocumentPage,
  RegisterPage,
  RegistryPage,
  ResetPasswordPage,
  SingleCompanyPage,
  SingleDocumentPage,
  SingleSharedDocumentPage,
  StoredRegistryPage,
  TZIsignLoginPage,
  UserInfoPage
} from './views'

import ProtectedRoute from './components/ProtectedRoute'
import WhitePageRoute from './components/WhitePageRoute'

const App = ({ config }) => (
  <>
    <GlobalStyles theme={config.data.co_brand_config || null}/>

    <Router history={history}>
      <Switch>
        <PrivateRoute exact path="/"><Redirect
          to={{ pathname: '/documents', search: '?status=2', state: { id: '/documents/2' } }}/></PrivateRoute>

        <PrivateRoute path="/new-document" component={NewDocumentPage}/>

        <ProtectedRoute path="/esc-checking" component={EcsCheckingPage}/>

        <PrivateRoute exact path="/companies" component={CompaniesPage}/>
        <PrivateRoute path="/companies/:id" component={SingleCompanyPage}/>

        <PrivateRoute exact path="/documents" component={DocumentsPage}/>
        <PrivateRoute exact path="/attachments" component={AttachmentsPage}/>
        <PrivateRoute path="/documents/:id" component={SingleDocumentPage}/>
        <PrivateRoute path="/attachments/:id" component={SingleDocumentPage}/>
        <PrivateRoute path="/registry" component={RegistryPage}/>
        <PrivateRoute path="/registry-stored" component={StoredRegistryPage}/>
        <PrivateRoute path="/user-me" component={UserInfoPage}/>
        <PrivateRoute path="/global-search" component={GlobalSearchPage}/>
        <PrivateRoute path="/contacts/:id" component={ContactDetailsPage}/>
        <PrivateRoute path="/contacts" component={ContactsPage}/>
        <PrivateRoute path="/contacts-import" component={ContactsImportPage}/>
        <PrivateRoute path="/custom-documents/create" component={CustomDocumentCreatePage}/>
        <PrivateRoute path="/custom-documents/:id" component={CustomDocumentDetailsPage}/>
        <PrivateRoute path="/custom-documents" component={CustomDocumentsPage}/>

        <PublicRoute path="/login" component={LoginPage}/>
        <PublicRoute path="/register" component={RegisterPage}/>
        <PublicRoute path="/complete-registration/:id" component={CompleteRegistrationForm}/>
        <PublicRoute path="/complete-reset/:id" component={CompleteResetForm}/>
        <PublicRoute path="/password-recovery" component={ResetPasswordPage}/>
        <ProtectedRoute path="/document/:id/shared/:code" component={SingleSharedDocumentPage}/>
        <WhitePageRoute path="/e-sign-first-step" component={EsignFirstStepPage}/>
        <WhitePageRoute path="/e-sign-login" component={EsignLoginPage}/>
        <WhitePageRoute path="/tzi-sign-login" component={TZIsignLoginPage}/>
        <WhitePageRoute path="/external/auth" component={ExternalRedirectPage}/>
        <PublicRoute path="*" component={LoginPage}/>
      </Switch>
    </Router>
  </>
)

const mapStateToProps = state => ({
  config: state.config
})

export default connect(
  mapStateToProps,
  null
)(App)
