import http from './http'

const getCustomDocuments = (params) => {
  return http({
    url: 'custom_attachment',
    method: 'GET',
    params
  })
}

const getCustomDocumentById = id => {
  return http({
    url: `custom_attachment/${id}`,
    method: 'GET'
  })
}

const getCustomDocumentTypes = () => {
  return http({
    url: 'custom_attachment_types',
    method: 'GET'
  })
}

const getCustomDocumentFields = id => {
  return http({
    url: `custom_attachment_fields/${id}`,
    method: 'GET'
  })
}

const createCustomDocument = data => {
  return http({
    url: 'custom_attachment',
    method: 'POST',
    data
  })
}

export {
  getCustomDocuments,
  getCustomDocumentById,
  getCustomDocumentTypes,
  getCustomDocumentFields,
  createCustomDocument
}
