import { connect } from 'react-redux'
import actions from '../../store/actions'

import CustomDocumentDetailsPage from './CustomDocumentDetailsPage'

const mapStateToProps = state => ({
  customDocument: state.customDocument
})

const mapDispatchToProps = dispatch => ({
  getCustomDocumentById: id => dispatch(actions.customDocument.getCustomDocumentById(id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomDocumentDetailsPage)
