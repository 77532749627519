import { connect } from 'react-redux'
import actions from '../../store/actions'

import ContactsPage from './ContactsPage'

const mapStateToProps = state => ({
  user: state.user.data,
  contacts: state.contacts
})

const mapDispatchToProps = dispatch => ({
  getDocumentsByActiveCompanyId: (id, params) => dispatch(actions.documents.getDocumentsByActiveCompanyId(id, params))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactsPage)
