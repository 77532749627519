import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'

import {
  Menu,
  Icon,
  Tooltip
} from 'antd'

import {
  Button,
  TariffTimePeriod
} from '../'
import history from '../../history'
import { getActiveCompany } from '../../utils'

import * as images from './images'

import { MainMenu } from './styled'

export default withRouter(({
  location,
  unreadCount,
  config,
  user: { data },
  documentsStatuses,
}) => {
  const [menuParams, setMenuParams] = useState(documentsStatuses)
  const [subMenuKeys, setSubMenuKeys] = useState([])
  const [activeCompany, setActiveCompany] = useState(null)

  const isBrand = config.data.co_brand_config

  useEffect(() => {
    if (data) {
      setActiveCompany(data.hasOwnProperty('active_company_object') && getActiveCompany(data))
    }
  }, [data])

  const mainMenuList = [
    {
      name: 'Входящие — Почта',
      url: '/documents?status=2',
      icon: images.IconMail,
      unreadCount: +unreadCount['3'] !== 0 ? unreadCount['3'] : null
    },
    {
      name: 'Входящие — УНП',
      url: '/documents?status=11',
      icon: images.IconVerify,
      unreadCount: +unreadCount['11'] !== 0 ? unreadCount['11'] : null
    },
    {
      name: 'Отправленные',
      url: '/documents?status=3',
      icon: images.IconSend
    },
    {
      name: 'Черновики',
      url: '/documents?status=1',
      icon: images.IconDocument
    },
    {
      name: 'Архив',
      url: '/documents?status=4',
      icon: images.IconArchive
    },
    {
      name: 'Работа с реестром',
      icon: images.IconCode,
      subMenu: [
        {
          name: 'Создать по реестру',
          url: '/registry'
        },
        {
          name: 'Сообщения по реестру',
          url: '/documents?status=10'
        },
        {
          name: 'Загруженные реестры',
          url: '/registry-stored'
        }
      ]
    },
    {
      name: 'Статусы документов',
      icon: images.IconStatus,
      subMenu: menuParams ? menuParams.attachment_statuses?.map(item => ({
        ...item,
        status: `status-${item.id}`,
        url: `/attachments?status=${item.id}`
      })) : []
    },
    {
      name: 'Контакты',
      icon: images.IconContacts,
      extraClass: 'contacts',
      subMenu: [
        {
          name: 'Список контактов',
          url: '/contacts',
          disabled: activeCompany ? !activeCompany.tarification.tarification_data.has_contacts : false
        },
        {
          name: 'Импортировать',
          url: '/contacts-import',
          disabled: activeCompany ? !activeCompany.tarification.tarification_data.has_contacts : false
        }
      ]
    },
    {
      name: 'Компании',
      url: '/companies',
      icon: images.IconCompanies
    },
    {
      name: 'Сторонние источники',
      url: '/documents?status=9',
      icon: images.IconDocument
    },
    {
      name: 'Проверка ЭЦП',
      url: '/esc-checking',
      icon: images.IconVerify
    },
    {
      name: 'Генератор документов',
      url: '/',
      extUrl: 'https://smartdoc.by/?utm_source=quidox_app&utm_medium=menu',
      icon: images.IconDocument
    }
  ]

  return (
    <MainMenu>
      <MainMenu.Top>
        <MainMenu.CreateMessage>
          <Button
            type="primary"
            onClick={() => history.push('/new-document')}
            block
          >
            <Icon component={images.IconNewMessage}/> Новое сообщение
          </Button>
        </MainMenu.CreateMessage>

        <MainMenu.Inner>
          <Menu
            mode="inline"
            openKeys={subMenuKeys}
            selectedKeys={[`${location.pathname ? `${location.pathname}${location.search}` : ''}`]}
            onOpenChange={keys => setSubMenuKeys(keys)}
          >
            {mainMenuList?.map((link, i) => {
              return link.subMenu ? (
                <Menu.SubMenu
                  key={i}
                  className={link.extraClass ? `submenu-${link.extraClass}` : ''}
                  title={
                    <MainMenu.SubTitle>
                      <Icon component={link.icon}/> {link.name}
                    </MainMenu.SubTitle>
                  }
                >
                  {link.subMenu?.map(subLink => (
                    <Menu.Item
                      key={subLink.url}
                      className={subLink.status ? `menu-item-status ${subLink.status}` : ''}
                      disabled={subLink.disabled}
                    >
                      {!subLink.disabled && subLink.url ? (
                            subLink.extUrl ? (
                              <Link
                                href={subLink.extUrl}
                              >
                                {subLink.name}
                              </Link>

                            ) : (
                              <Link
                                to={subLink.url}
                              >
                                {subLink.name}
                              </Link>
                            )
                      ) : (
                        <Tooltip placement="left" title="Контакты не доступны на этом тарифе">
                          {subLink.name}
                        </Tooltip>
                      )}
                    </Menu.Item>))}
                </Menu.SubMenu>
              ) : (
                <Menu.Item
                  key={link.url}
                  onClick={() => setSubMenuKeys([])}
                >
                  <Tooltip placement="left" title="В разработке">
                    {link.url === '/custom-documents'}
                  </Tooltip>
                  { link.extUrl ? (

                  <Link
                    onClick={() => window.open(link.extUrl)}
                    to={link.url}
                  >
                    <Icon component={link.icon}/> {link.name}
                  </Link>
                  ) : (
                  <Link to={link.url}>
                    <Icon component={link.icon}/> {link.name}
                    {link.unreadCount && <MainMenu.UnreadCount>{link.unreadCount}</MainMenu.UnreadCount>}
                  </Link>

                  )
                  }
                </Menu.Item>
              )
            })}
          </Menu>
        </MainMenu.Inner>
      </MainMenu.Top>

      <div>
        <TariffTimePeriod />

        {/*{!isBrand && (*/}
        {/*  <MainMenu.List>*/}
        {/*    /!*<MainMenu.List.Link href='https://help.quidox.by' target='_blank'>*!/*/}
        {/*    /!*  <Icon component={images.IconGraduation} /> База знаний*!/*/}
        {/*    /!*</MainMenu.List.Link>*!/*/}
        {/*    <MainMenu.List.Link href='https://help.quidox.by/article/4589' target='_blank'>*/}
        {/*      <Icon component={images.IconContactsColor} /> Контакты*/}
        {/*    </MainMenu.List.Link>*/}
        {/*  </MainMenu.List>)}*/}
      </div>
    </MainMenu>
  )
})
