import styled from 'styled-components'

const Column = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  && {
    .ant-btn {
      height: 3.6rem;
      font-size: 1.3rem;
      padding: 0 .8rem;

      span {
        margin-left: .4rem;
      }
    }
  }
`

Column.Item = styled.div`
  margin-left: .8rem;
`

export {
  Column
}
