import styled from 'styled-components'
import { rgba } from 'polished'

import { styleguide } from '../../constants'

const { colors } = styleguide

const Layout = styled.div`
`

Layout.Inner = styled.div`
  padding: 2rem;
`

Layout.Check = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3.2rem;
`

Layout.Finished = styled.div`
  background-color: ${rgba(colors.primary, 0.1)};
  border-radius: .4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3.2rem;
  text-align: center;

  .anticon {
    color: ${colors.primary};
    font-size: 4.4rem;
    margin-right: 2.4rem;
  }
`

Layout.Finished.Label = styled.div`
  font-size: 2.4rem;
`

export {
  Layout
}
