import styled from 'styled-components'

import { styleguide } from '../../constants'

import { Icon } from 'antd'

const { colors } = styleguide

const Layout = styled.div`
  background-color: ${colors.white};
  border-radius: .4rem;
  padding: 2rem;
  position: relative;
  text-align: center;
`

Layout.Inner = styled.div`
`

Layout.Inner.Actions = styled.div`
  .ant-btn {
    margin-top: 1.6rem;
  }
`

Layout.Hide = styled(Icon)`
  position: absolute;
  top: .8rem;
  right: .8rem;
`

export {
  Layout
}
