import styled, { css } from 'styled-components'
import { Typography } from 'antd'

import { styleguide } from '../../constants'

const { colors } = styleguide

const { Title } = Typography

const Layout = styled.div`
  padding: 2rem;
`

Layout.Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
`

Layout.Header.Actions = styled.div`
  display: flex;
  align-items: center;

  .ant-btn {
    margin-left: 2rem;
  }
`

Layout.Title = styled(Title)`
  && {
    margin-bottom: 0;

    .anticon {
      color: ${colors.primary};
      margin-right: .8rem;
    }
  }
`

Layout.Filter = styled.div`
  display: none;

  && {
    .ant-typography {
      &-secondary {
        color: ${colors.gray.dark};
      }
    }

    .ant-form-item {
      &:last-child {
        margin-bottom: 0 !important;
      }
    }

    .ant-calendar-range-picker-separator {
      vertical-align: baseline;
    }

    .ant-card {
      margin-bottom: .8rem;

      &-body {
        padding: 2rem;
      }

      h4.ant-typography {
        .ant-typography-secondary {
          font-size: 1.6rem;
        }
      }
    }
  }

  ${({ visible }) => visible && css`
    display: block;
  `}
`

Layout.Filter.Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2rem;

  .ant-btn {
    margin-left: 2rem;
  }
`

Layout.Results = styled.div`
  margin-top: 2rem;

  .ui-footer-fixed {
    padding: 2rem 0;
    position: relative;
    height: auto;
    right: 0;
    left: 0;
    bottom: 0;

    &:after {
      display: none;
    }
  }
`

export {
  Layout
}
