import styled from 'styled-components'

import { styleguide } from '../../../../constants'

const { colors } = styleguide

const Column = styled.div`
`

Column.Empty = styled.div`
  color: ${colors.gray.dark};
`

Column.List = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-tag {
    margin: 0 .2rem;
  }
`

export {
  Column
}
