import React from 'react'

import {
  Tag,
  Tooltip
} from 'antd'

import { getAttachmentStatus } from '../../../../helpers'

import { Column } from './styled'

export default ({
  files,
  statuses
}) => {
  const filteredFiles = Object.keys(files).filter(key => files[key] !== 0)

  const getStatusName = id => statuses
    ? statuses.filter(item => item.id === +id)[0]?.name
    : 'Не определен'

  return (
    <Column>
      {filteredFiles.length ? (
        <Column.List>
          {filteredFiles.map((file, i) => (
            <Tooltip title={getStatusName(file)} key={i}>
              <Tag color={getAttachmentStatus(+file).color}>
                {files[file]}
              </Tag>
            </Tooltip>
          ))}
        </Column.List>
      ) : (
        <Column.Empty>Нет вложений</Column.Empty>
      )}
    </Column>
  )
}
