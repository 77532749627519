import styled from 'styled-components'

import { Layout as LayoutLibrary } from 'antd'

const Layout = styled(LayoutLibrary)`
  height: 97vh;
`

export {
  Layout
}
