import * as t from '../types'

import { api } from '../../services'

const getAttachmentsTypes = () => dispatch => {
  dispatch({
    type: t.GET_ATTACHMENTS_TYPES_FETCHING,
    payload: true
  })

  return api.document.getAttachmentTypes()
    .then(response => {
      dispatch({
        type: t.GET_ATTACHMENTS_TYPES,
        payload: response.data
      })

      dispatch({
        type: t.GET_ATTACHMENTS_TYPES_FETCHING,
        payload: false
      })
    })
    .catch(() => {
      dispatch({
        type: t.GET_ATTACHMENTS_TYPES_FETCHING,
        payload: false
      })
    })
}

export {
  getAttachmentsTypes,
}
