import React from 'react'
import moment from 'moment'

import {
  Tag,
  Timeline,
  Typography
} from 'antd'

import { getAttachmentStatus } from '../../../../helpers'

const {
  Title,
  Paragraph,
  Text
} = Typography

export default function ({ history }) {
  const handleDownloadFile = (e, link) => {
    e.preventDefault()

    window.open(`${link}?shared_token=${btoa(process.env.REACT_APP_SECRET_KEY)}`, '_blank')
  }

  return (
    <>
      <Title level={3}>История действий</Title>

      <Timeline>
        {history.map((item, i) => (
          <Timeline.Item key={i}>
            <Paragraph type='secondary'>Дата изменения: {moment.utc(item.created_at).format('DD/MM/YYYY HH:mm:ss')}</Paragraph>
            <Text>{item.users_companies_data.user_email}</Text>
            <Paragraph type='secondary'>{item.users_companies_data.company_name}</Paragraph>

            {item.status.map((status, statusIndex) => (
              <div key={statusIndex}>
                {(status.status_data.id !== 1 || (status.status_data.id === 1 && item.status.length === 1)) && (
                  <Tag color={getAttachmentStatus(status.status_data.id).color}>
                    {status.status_data.name}
                  </Tag>)}

                {status.comment && status.comment !== '' && (
                  <div style={{ marginTop: '1.6rem' }}>
                    <Text strong>Комментарий:</Text>
                    <Paragraph secondary>{status.comment && status.comment}</Paragraph>
                  </div>)}

                {status.comment_link_basename && status.comment_link_basename !== '' && (
                  <div style={{ marginTop: '1.6rem' }}>
                    <Paragraph strong>Прикрепленный файл:</Paragraph>

                    <a href='' onClick={e => handleDownloadFile(e, status.comment_link)}>
                      {status.comment_link_basename}
                    </a>
                  </div>)}
              </div>
            ))}
          </Timeline.Item>))}
      </Timeline>
    </>
  )
}
