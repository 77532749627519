import * as t from '../types'

const initialState = {
  isFetching: false,
  attachmentsTypes: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case t.GET_ATTACHMENTS_TYPES:
      return {
        ...state,
        attachmentsTypes: action.payload
      }
    case t.GET_ATTACHMENTS_TYPES_FETCHING:
      return {
        ...state,
        isFetching: action.payload
      }
    default:
      return state
  }
}
