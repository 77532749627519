import * as type from '../types'
import history from '../../history'

import { api } from '../../services'

const getCustomDocuments = (params) => dispatch => {
  dispatch({
    type: type.GET_CUSTOM_DOCUMENTS_FETCHING,
    payload: true
  })

  return api.customDocument.getCustomDocuments(params)
    .then(({ data }) => {
      dispatch({
        type: type.GET_CUSTOM_DOCUMENTS_FETCHING,
        payload: false
      })

      dispatch({
        type: type.GET_CUSTOM_DOCUMENTS_SUCCESS,
        payload: data
      })
    })
}

const getCustomDocumentById = id => dispatch => {
  dispatch({
    type: type.GET_CUSTOM_DOCUMENT_BY_ID_FETCHING,
    payload: true
  })

  return api.customDocument.getCustomDocumentById(id)
    .then(({ data }) => {
      dispatch({
        type: type.GET_CUSTOM_DOCUMENT_BY_ID_FETCHING,
        payload: false
      })

      dispatch({
        type: type.GET_CUSTOM_DOCUMENT_BY_ID_SUCCESS,
        payload: data
      })
    })
}

const getCustomDocumentTypes = () => dispatch => {
  dispatch({
    type: type.GET_CUSTOM_DOCUMENT_TYPES_FETCHING,
    payload: true
  })

  return api.customDocument.getCustomDocumentTypes()
    .then(({ data }) => {
      dispatch({
        type: type.GET_CUSTOM_DOCUMENT_TYPES_FETCHING,
        payload: false
      })

      dispatch({
        type: type.GET_CUSTOM_DOCUMENT_TYPES_SUCCESS,
        payload: data
      })
    })
}

const getCustomDocumentFields = id => dispatch => {
  dispatch({
    type: type.GET_CUSTOM_DOCUMENT_FIELDS_FETCHING,
    payload: true
  })

  return api.customDocument.getCustomDocumentFields(id)
    .then(({ data }) => {
      dispatch({
        type: type.GET_CUSTOM_DOCUMENT_FIELDS_FETCHING,
        payload: false
      })

      dispatch({
        type: type.GET_CUSTOM_DOCUMENT_FIELDS_SUCCESS,
        payload: data
      })
    })
}

const createCustomDocument = body => dispatch => {
  dispatch({
    type: type.CREATING_CUSTOM_DOCUMENT_FETCHING,
    payload: true
  })

  return api.customDocument.createCustomDocument(body)
    .then(({ data }) => {
      dispatch({
        type: type.CREATING_CUSTOM_DOCUMENT_FETCHING,
        payload: false
      })
      history.push('/custom-documents')
    })
}

export {
  getCustomDocuments,
  getCustomDocumentById,
  getCustomDocumentTypes,
  getCustomDocumentFields,
  createCustomDocument
}
