import { createGlobalStyle } from 'styled-components'
import { rgba } from 'polished'

import { styleguide } from '../constants'

const { colors } = styleguide

export default createGlobalStyle`
  ${({ theme }) => `
    html {
      font-size: 62.5%;
    }

    body {
      text-rendering: optimizeLegibility;

      a,
      button {
        &.ant-btn {
          display: inline-flex;
          justify-content: center;
          align-items: center;

          & > i {
            &.anticon {
              line-height: 1.2rem;
              font-size: 1.8rem;
            }
          }

          & .anticon + span {
            margin-left: 1.4rem;
          }

          &.ant-btn-primary {
            &.ant-btn-background-ghost {
              &:hover {
                background-color: ${rgba(colors.primary, 0.1)} !important;
              }
            }
          }
        }
      }

      .ant-input-number-disabled,
      .ant-input-disabled {
        background-color: #F2F5F7 !important;
      }

      .ant-tag {
        line-height: 2.2rem !important;

        &.ant-tag-orange {
          border: 0;
          color: ${colors.orange};
        }
      }

      .ui-table-inside {
        .ant-table {
          .ant-table-thead {
            th {
              background: transparent;
              border: 0;
              color: ${colors.gray.dark};
              font-size: 1.2rem;
              padding: 0 1.2rem;

              &:first-child {
                padding-left: 0;
              }

              &:last-child {
                padding-right: 0;
              }
            }
          }

          .ant-table-tbody {
            .ant-table-row {
              &:hover {
                td {
                  background-color: ${colors.white};
                }
              }

              td {
                border-color: ${colors.gray.middle};
                padding: 1.6rem 1.2rem;

                &:first-child {
                  padding-left: 0;
                }

                &:last-child {
                  padding-right: 0;
                }
              }
            }
          }
        }
      }

      .ui-table-list {
        .ant-table {
          .ant-table-thead {
            th {
              background: transparent;
              border-bottom: .1rem solid ${colors.gray.middle};
              color: ${colors.gray.dark};
              padding: 1.4rem 1.2rem;

              &:first-child {
                padding-right: 0;
                padding-left: .8rem;
                text-align: left;
              }

              &:last-child {
                padding-right: .8rem;
                text-align: right;
              }

              &.ant-table-column-sort {
                background: ${colors.white};

                &:hover {
                  background: ${colors.white};
                }
              }
            }
          }

          .ant-table-tbody {
            .ant-table-row {
              &.ant-table-row-selected,
              &:hover {
                td {
                  background-color: ${colors.white};
                }
              }

              td {
                border-color: ${colors.gray.middle};
                padding: 2rem 1.2rem;

                &:first-child {
                  padding-right: 0;
                  padding-left: .8rem;
                  text-align: left;
                }

                &:last-child {
                  padding-right: .8rem;
                  text-align: right;
                }

                &.ant-table-column-sort {
                  background-color: ${colors.white};
                }
              }
            }
          }
        }
      }

      .ant-calendar-picker {
        .anticon {
          &.anticon-calendar {
            width: 1.8rem;
            height: 1.8rem;
            color: ${colors.primary};
            font-size: 1.8rem;
            margin-top: -1rem;
          }
        }
      }

      .ant-modal {
        .ant-modal-content {
          .ant-row {
            &.ant-form-item {
              margin-bottom: 1.2rem;
            }
          }

          .ant-modal-header {
            border: 0;
            padding-top: 2.4rem;

            .ant-modal-title {
              line-height: 2.8rem;
              font-size: 2.1rem;
            }
          }

          .ant-modal-footer {
            border: 0;
            padding: 2.4rem;

            button {
              & + button {
                margin-left: 1.6rem;
              }
            }
          }
        }

        &.ui-modal-connect {
          .ant-modal-body {
            padding-top: 0;
          }
        }
      }

      ${theme && `
        color: ${theme.text_color} !important;

        a {
          color: ${theme.link_color} !important;
        }

        a,
        button {
          &.ant-btn {
            &.ant-btn-primary {
              background-color: ${theme.primary_color};
              border-color: ${theme.primary_color};

              .anticon {
                color: ${colors.white} !important;
              }

              &.ant-btn-background-ghost {
                color: ${theme.primary_color} !important;

                .anticon {
                  color: ${theme.primary_color} !important;
                }

                &:focus,
                &:active,
                &:hover {
                  background-color: ${rgba(theme.primary_color, 0.2)} !important;
                  border-color: ${theme.primary_color} !important;
                }
              }
            }

            &-link {
              .anticon {
                color: ${theme.brand_color};

                path {
                  fill: ${theme.brand_color};
                }
              }

              &:hover {
                color: ${theme.brand_color} !important;
              }
            }
          }
        }

        .anticon {
          &-edit,
          &-download,
          &-down,
          &-up,
          &-caret-,
          &-caret-down {
            color: ${theme.brand_color} !important;
          }
        }

        [for="uploadInput"] {
          .anticon {
            path {
              fill: ${theme.brand_color};
            }
          }

          &:hover {
            color: ${theme.brand_color} !important;
          }
        }

        .ant-menu {
          &-item {
            &-selected,
            &:active {
              background-color: ${rgba(theme.primary_color, 0.2)} !important;
            }
          }

          &-submenu {
            &-title {
              color: ${theme.primary_color} !important;

              &:active {
                background-color: ${rgba(theme.primary_color, 0.2)} !important;
              }
            }

            &-arrow {
              &:after,
              &:before {
                background: ${theme.brand_color} !important;
              }
            }
          }

          &-sub {
            border-left: .1rem solid ${theme.brand_color} !important;
          }

          a {
            color: ${theme.primary_color} !important;
          }

          .anticon {
            path {
              fill: ${theme.brand_color};
            }
          }
        }

        .ant-tabs {
          .ant-tabs-nav-container {
            .ant-tabs-nav {
              .ant-tabs-tab {
                color: ${theme.primary_color};

                &-active {
                  color: ${theme.brand_color};
                }
              }

              .ant-tabs-ink-bar {
                background-color: ${theme.brand_color};
              }
            }
          }
        }

        .ant-input {
          &:hover {
            border-color: ${theme.brand_color};
          }

          &:focus {
            border-color: ${theme.brand_color};
            box-shadow: 0 0 0 .2rem ${rgba(theme.brand_color, 0.2)};
          }
        }

        .ant-select {
          &-focused {
            .ant-select-selection {
              border-color: ${theme.brand_color};
              box-shadow: 0 0 0 .2rem ${rgba(theme.brand_color, 0.2)};

              &:hover {
                border-color: ${theme.brand_color};
              }
            }
          }

          .ant-select-selection {
            &:hover {
              border-color: ${theme.brand_color};
            }
          }
        }

        .ant-pagination {
          &.ant-table-pagination {
            .ant-pagination-item {
              &:hover {
                border-color: ${theme.brand_color};
              }

              &-active {
                border-color: ${theme.brand_color};
              }
            }
          }

          &-simple {
            input {
              &:hover {
                border-color: ${theme.brand_color} !important;
              }

              &:focus {
                border-color: ${theme.brand_color};
                box-shadow: 0 0 0 .2rem ${rgba(theme.brand_color, 0.2)};
              }
            }
          }
        }

        .ant-spin {
          color: ${theme.brand_color};

          &-dot-item {
            background-color: ${theme.brand_color};
          }
        }

        .ui-btn-goback {
          border-color: ${rgba(theme.brand_color, 0.5)};
          color: ${theme.brand_color};

          &:hover {
            border-color: ${theme.brand_color};
          }
        }

        .ui-custom-pagination {
          &--active {
            background-color: ${theme.primary_color};
            color: ${colors.white} !important;
          }
        }
      `}
    }
  `}
`
