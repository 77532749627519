import React from 'react'
import { useHistory } from 'react-router-dom'

import {
  LayoutScroll,
  Table,
  Button
} from '../../components'

import { Layout } from './styled'

export default ({
  user,
  customDocument: {
    isFetchingDocuments,
    documents
  },
  getCustomDocuments
}) => {
  const history = useHistory()

  return (
    <LayoutScroll withFooter>
      <Layout>
        <Layout.Header>
          <Layout.Title level={3}>Документы</Layout.Title>

          <Button
            type='primary'
            onClick={() => history.push('/custom-documents/create')}
          >
            Создать документ
          </Button>
        </Layout.Header>

        <Layout.Table>
          <Table
            className='ui-table-list'
            tableData={documents}
            loading={isFetchingDocuments}
            getDocumentsWithParams={getCustomDocuments}
            activeCompany={user.active_company_id}
            type='/documents'
            status='customDocuments'
          />
        </Layout.Table>
      </Layout>
    </LayoutScroll>
  )
}
