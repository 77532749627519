import * as t from '../types'

const initialState = {
  isFetchingList: false,
  isFetchingDetails: false,
  isFetchingUpdate: false,
  isFetchingDelete: false,
  isFetchingImport: false,

  contacts: {},
  contactDetails: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case t.GET_CONTACTS_FETCHING:
      return {
        ...state,
        isFetchingList: action.payload
      }

    case t.GET_CONTACT_BY_ID_FETCHING:
      return {
        ...state,
        isFetchingDetails: action.payload
      }

    case t.UPDATE_CONTACT_FETCHING:
      return {
        ...state,
        isFetchingUpdate: action.payload
      }

    case t.DELETE_CONTACT_FETCHING:
      return {
        ...state,
        isFetchingDelete: action.payload
      }

    case t.IMPORT_CONTACT_FETCHING:
      return {
        ...state,
        isFetchingImport: action.payload
      }

    case t.GET_CONTACTS_SUCCESS:
      return {
        ...state,
        contacts: action.payload
      }

    case t.GET_CONTACT_BY_ID_SUCCESS:
      return {
        ...state,
        contactDetails: action.payload
      }

    case t.DELETE_CONTACT_SUCCESS:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          data: state.contacts.data.filter(item => item.id !== action.payload)
        }
      }

    default:
      return state
  }
}
