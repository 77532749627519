import React, { useState, useEffect } from 'react'
import moment from 'moment'
import queryString from 'query-string'
import api from '../../services/api'

import {
  Row,
  Col,
  Card,
  Form,
  Radio,
  Checkbox,
  Input,
  InputNumber,
  DatePicker,
  Typography,
  Icon,
  Tag
} from 'antd'

import {
  LayoutScroll,
  Table,
  Button
} from '../../components'

import { getAttachmentStatus } from '../../helpers'

import { getActiveCompany } from '../../utils'

import {
  Layout
} from './styled'

const { TextArea } = Input

const {
  Title,
  Text
} = Typography

export default Form.create()(({
  isFetchingResults,
  user,
  results,
  currencies,
  getDocumentsByActiveCompanyId,
  resetDocumentsList,
  form: {
    getFieldValue,
    getFieldDecorator,
    resetFields,
    validateFields,
    setFieldsValue
  },
  history,
  attachmentsTypes,
}) => {
  const [filterVisible, setFilterVisible] = useState(false)
  const [documentTypes, setDocumentTypes] = useState(attachmentsTypes)
  const [queryParams, setQueryParams] = useState({})

  const searchParams = history.location.search.replace('?', '')

  useEffect(() => {
    if (user.id) {
      const activeCompany = user.hasOwnProperty('active_company_object') && getActiveCompany(user)

      if (!activeCompany.tarification.tarification_data.has_global_search) {
        history.push('/documents?status=2')
      }
    }
  }, [user])

  useEffect(() => {
    if(!queryParams.length) {
      setFilterVisible(true)
    }
  }, [queryParams])

  useEffect(() => {
    const fetchDocumentType = async () => {
      const response = await api.document.getAttachmentTypes()

      setDocumentTypes(response.data)
    }

    if (!searchParams) {
      resetDocumentsList()
    }

    return () => {
      resetFields()
    }
  }, [])

  useEffect(() => {
    if (searchParams && currencies.length && documentTypes.length) {
      setFilterVisible(true)

      const params = queryString.parse(searchParams, {
        arrayFormat: 'bracket',
        parseNumbers: true
      })

      if (params.typeDate !== 'chooseDate') {
        params.date_from = undefined
        params.date_to = undefined
      } else {
        params.date_from = moment(params.date_from)
        params.date_to = moment(params.date_to)
      }

      if (params.should_be_signed_from && params.should_be_signed_to) {
        params.should_be_signed_from = moment(params.should_be_signed_from)
        params.should_be_signed_to = moment(params.should_be_signed_to)
      }

      setTimeout(() => {
        setFieldsValue(params)
      }, 100)
    }
  }, [currencies.length, documentTypes?.length])

  const handleChangeCheckboxGroup = (statuses, fieldName) => {
    if (statuses.includes('not')) {
      setTimeout(() => {
        setFieldsValue({
          [fieldName]: statuses[statuses.length - 1] === 'not' ? ['not'] : statuses.filter(status => status !== 'not')
        })
      }, 100)
    }
  }

  const handleChangeStatus = statuses => {
    if (statuses.includes('all')) {
      setTimeout(() => {
        setFieldsValue({
          status: statuses[statuses.length - 1] === 'all' ? ['all'] : statuses.filter(status => status !== 'all')
        })
      }, 100)
    }
  }

  const handleResetFilter = () => {
    resetFields()

    history.push('/global-search')
  }

  const handleSubmit = e => {
    e.preventDefault()

    validateFields((err, values) => {
      if (!err) {
        let date = {}
        let shouldBeSigned = {}

        if (values.typeDate === 'chooseDate') {
          date = {
            date_from: moment(values.date_from).format('YYYY-MM-DD'),
            date_to: moment(values.date_to).format('YYYY-MM-DD')
          }
        } else if (values.typeDate === 'threeMonth') {
          date = {
            date_from: moment().subtract(90, 'days').format('YYYY-MM-DD'),
            date_to: moment().format('YYYY-MM-DD')
          }
        } else {
          date = {}
        }

        if (values.isShouldBeSigned === 'chooseDate') {
          shouldBeSigned = {
            should_be_signed_from: moment(values.should_be_signed_from).format('YYYY-MM-DD'),
            should_be_signed_to: moment(values.should_be_signed_to).format('YYYY-MM-DD')
          }
        }

        const params = {
          ...date,
          ...shouldBeSigned,
          status: values.status.includes('all')
            ? [1, 2, 3, 4, 9, 10, 11]
            : values.status.includes('exclude_chain') ? ['3', 'exclude_chain', ...values.status] : values.status,
          email: values.email,
          company_number: values.company_number,
          document_name: values.document_name,
          document_description: values.document_description,
          filename: values.filename,
          cost: values.cost,
          with_attachment_statuses: !values.with_attachment_statuses.includes('not') ? values.with_attachment_statuses : [],
          type_ids: !values.type_ids.includes('not') ? values.type_ids : [],
          currency_ids: !values.currency_ids.includes('not') ? values.currency_ids : [],
          per_page: 15,
          page: 1
        }

        getDocumentsByActiveCompanyId(user.active_company_id, params)

        setQueryParams(params)

        history.push(`/global-search?${queryString.stringify({
          ...values,
          ...date,
          ...shouldBeSigned
        }, { arrayFormat: 'bracket' })}`)
      }
    })
  }

  if (!currencies.length && !documentTypes?.length) {
    return null
  }

  return (
    <LayoutScroll>
      <Layout>
        <Layout.Header>
          <Layout.Title level={3}>
            <Icon type='setting' /> Расширенный поиск
          </Layout.Title>

          <Layout.Header.Actions>
            <Button
              type='primary'
              onClick={handleResetFilter}
              ghost
            >
              Сбросить фильтр
            </Button>

            <Button
              type='primary'
              onClick={() => setFilterVisible(!filterVisible)}
              ghost
            >
              {filterVisible ? 'Закрыть фильтр' : 'Открыть фильтр'} <Icon type={filterVisible ? 'up' : 'down'} />
            </Button>
          </Layout.Header.Actions>
        </Layout.Header>

        <Layout.Filter visible={filterVisible}>
          <Form
            onSubmit={handleSubmit}
            colon={false}
            hideRequiredMark
          >
            <Card>
              <Title level={4}>За период</Title>

              <Form.Item>
                {getFieldDecorator('typeDate', {
                  initialValue: 'threeMonth'
                })(
                  <Radio.Group onChange={() => resetFields(['date_from', 'date_to'])}>
                    <Radio value='chooseDate'>Выбрать дату</Radio>
                    <Radio value='threeMonth'>За предыдущие 90 дней</Radio>
                    <Radio value='all'>За весь период <Text type='secondary'>(это может занять некоторое время)</Text></Radio>
                  </Radio.Group>
                )}
              </Form.Item>

              <Row gutter={[20, 12]}>
                <Col span={12}>
                  <Form.Item label='От'>
                    {getFieldDecorator('date_from', {
                      rules: [{
                        required: getFieldValue('typeDate') === 'chooseDate',
                        message: 'Необходимо выбрать начальную дату'
                      }]
                    })(
                      <DatePicker
                        style={{ width: '100%' }}
                        placeholder='Начальная дата'
                        disabled={getFieldValue('typeDate') !== 'chooseDate'}
                      />
                    )}
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item label='До'>
                    {getFieldDecorator('date_to', {
                      rules: [{
                        required: getFieldValue('typeDate') === 'chooseDate',
                        message: 'Необходимо выбрать конечную дату'
                      }]
                    })(
                      <DatePicker
                        style={{ width: '100%' }}
                        placeholder='Конечная дата'
                        disabled={getFieldValue('typeDate') !== 'chooseDate'}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            <Card>
              <Title level={4}>Искать в папках <Text type='secondary'>(использует логический параметр "И")</Text></Title>

              <Form.Item>
                {getFieldDecorator('status', {
                  initialValue: ['2'],
                  rules: [{
                    required: true,
                    message: 'Выберите в каких папках произвести поиск'
                  }]
                })(
                  <Checkbox.Group
                    style={{ width: '100%' }}
                    onChange={handleChangeStatus}
                  >
                    <Row gutter={[20, 0]}>
                      <Col span={12}>
                        <Row gutter={[0, 12]}>
                          <Col span={24}>
                            <Checkbox value='2' disabled={getFieldValue('status').includes('all')}>
                              Входящие
                            </Checkbox>
                          </Col>
                          <Col span={24}>
                            <Checkbox value='11' disabled={getFieldValue('status').includes('all')}>
                              Входящие <Text type='secondary'>(включая УНП)</Text>
                            </Checkbox>
                          </Col>
                          <Col span={24}>
                            <Checkbox value='exclude_chain' disabled={getFieldValue('status').includes('all') || getFieldValue('status').includes('3')}>
                              Отправленные
                            </Checkbox>
                          </Col>
                          <Col span={24}>
                            <Checkbox value='3' disabled={getFieldValue('status').includes('all') || getFieldValue('status').includes('exclude_chain')}>
                              Отправленные <Text type='secondary'>(включая цепочки)</Text>
                            </Checkbox>
                          </Col>
                          <Col span={24}>
                            <Checkbox value='1' disabled={getFieldValue('status').includes('all')}>
                              Черновики
                            </Checkbox>
                          </Col>
                        </Row>
                      </Col>

                      <Col span={12}>
                        <Row gutter={[0, 12]}>
                          <Col span={24}>
                            <Checkbox value='10' disabled={getFieldValue('status').includes('all')}>
                              Сообщения реестра <Text type='secondary'>(не отправленные)</Text>
                            </Checkbox>
                          </Col>
                          <Col span={24}>
                            <Checkbox value='9' disabled={getFieldValue('status').includes('all')}>
                              Сторонние источники <Text type='secondary'>(не отправленные)</Text>
                            </Checkbox>
                          </Col>
                          <Col span={24}>
                            <Checkbox value='4' disabled={getFieldValue('status').includes('all')}>
                              Архив
                            </Checkbox>
                          </Col>
                          <Col span={24}>
                            <Checkbox value='all'>Везде</Checkbox>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                )}
              </Form.Item>
            </Card>

            <Card>
              <Title level={4}>Где участник переписки</Title>

              <Row gutter={[20, 12]}>
                <Col span={12}>
                  <Form.Item label='Электронная почта'>
                    {getFieldDecorator('email', {
                      rules: [
                        {
                          type: 'email',
                          message: 'Введен неверный адрес электронной почты'
                        }
                      ]
                    })(
                      <Input placeholder='Введите электронную почту' />
                    )}
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item label='УНП'>
                    {getFieldDecorator('company_number', {
                      rules: [{
                        pattern: /^\d+$/,
                        message: 'Разрешены только цифры'
                      }]
                    })(
                      <Input placeholder='Введите УНП' maxLength={9} />
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[20, 12]}>
                <Col span={12}>
                  <Form.Item label='GLN'>
                    {getFieldDecorator('gln')(
                      <Input placeholder='Введите GLN' disabled />
                    )}
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item label='ILN'>
                    {getFieldDecorator('iln')(
                      <Input placeholder='Введите ILN' disabled />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            <Card>
              <Title level={4}>Тема сообщения содержит текст</Title>

              <Form.Item>
                {getFieldDecorator('isDocumentName', {
                  initialValue: 'no'
                })(
                  <Radio.Group onChange={() => resetFields(['document_name'])}>
                    <Radio value='no'>Не важно</Radio>
                    <Radio value='yes'>Содержит текст</Radio>
                  </Radio.Group>
                )}
              </Form.Item>

              <Form.Item>
                {getFieldDecorator('document_name', {
                  rules: [{
                    required: getFieldValue('isDocumentName') === 'yes',
                    message: 'Необходимо ввести тему'
                  }]
                })(
                  <Input
                    placeholder='Введите тему'
                    disabled={getFieldValue('isDocumentName') === 'no'}
                  />
                )}
              </Form.Item>
            </Card>

            <Card>
              <Title level={4}>Комментарий в сообщении содержит текст</Title>

              <Form.Item>
                {getFieldDecorator('isDocumentDescription', {
                  initialValue: 'no'
                })(
                  <Radio.Group onChange={() => resetFields(['document_description'])}>
                    <Radio value='no'>Не важно</Radio>
                    <Radio value='yes'>Содержит текст</Radio>
                  </Radio.Group>
                )}
              </Form.Item>

              <Form.Item>
                {getFieldDecorator('document_description', {
                  rules: [{
                    required: getFieldValue('isDocumentDescription') === 'yes',
                    message: 'Необходимо ввести комментарий'
                  }]
                })(
                  <TextArea
                    rows={4}
                    placeholder='Введите комментарий'
                    disabled={getFieldValue('isDocumentDescription') === 'no'}
                  />
                )}
              </Form.Item>
            </Card>

            <Card>
              <Title level={4}>Содержит вложения <Text type='secondary'>(использует логический параметр "ИЛИ")</Text></Title>

              <Form.Item label='Название файла'>
                {getFieldDecorator('filename')(
                  <Input placeholder='Введите название файла' />
                )}
              </Form.Item>

              <Row gutter={[20, 20]}>
                <Col span={12}>
                  <Form.Item label='Учитывать статус вложения/документа'>
                    {getFieldDecorator('with_attachment_statuses', {
                      initialValue: ['not'],
                      rules: [{
                        required: true,
                        message: 'Выберите статус вложения/документа'
                      }]
                    })(
                      <Checkbox.Group
                        style={{ width: '100%' }}
                        onChange={value => handleChangeCheckboxGroup(value, 'with_attachment_statuses')}
                      >
                        <Row gutter={[0, 12]}>
                          <Col span={24}>
                            <Checkbox value='not'>Нет</Checkbox>
                          </Col>
                          <Col span={24}>
                            <Checkbox value='1'>
                              <Tag color={getAttachmentStatus(1).color}>Простая доставка</Tag>
                            </Checkbox>
                          </Col>
                          <Col span={24}>
                            <Checkbox value='2'>
                              <Tag color={getAttachmentStatus(2).color}>Согласование</Tag>
                            </Checkbox>
                          </Col>
                          <Col span={24}>
                            <Checkbox value='3'>
                              <Tag color={getAttachmentStatus(3).color}>Требуется подпись</Tag>
                            </Checkbox>
                          </Col>
                          <Col span={24}>
                            <Checkbox value='4'>
                              <Tag color={getAttachmentStatus(4).color}>Согласовано</Tag>
                            </Checkbox>
                          </Col>
                          <Col span={24}>
                            <Checkbox value='5'>
                              <Tag color={getAttachmentStatus(5).color}>Завершено/Подписано всеми</Tag>
                            </Checkbox>
                          </Col>
                          <Col span={24}>
                            <Checkbox value='6'>
                              <Tag color={getAttachmentStatus(6).color}>Отклонен</Tag>
                            </Checkbox>
                          </Col>
                        </Row>
                      </Checkbox.Group>
                    )}
                  </Form.Item>

                  <Form.Item label='Учитывать "Тип вложения"'>
                    {getFieldDecorator('type_ids', {
                      initialValue: ['not'],
                      rules: [{
                        required: true,
                        message: 'Выберите тип вложения'
                      }]
                    })(
                      <Checkbox.Group
                        style={{ width: '100%' }}
                        onChange={value => handleChangeCheckboxGroup(value, 'type_ids')}
                      >
                        <Row gutter={[0, 12]}>
                          <Col span={24}>
                            <Checkbox value='not'>Нет</Checkbox>
                          </Col>

                          {documentTypes?.map((item, i) => (
                            <Col span={24} key={i}>
                              <Checkbox value={item.id}>
                                <span style={{ textTransform: 'capitalize' }}>{item.name}</span>
                              </Checkbox>
                            </Col>))}
                        </Row>
                      </Checkbox.Group>
                    )}
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item label='Учитывать параметр вложения "Валюта"'>
                    {getFieldDecorator('currency_ids', {
                      initialValue: ['not'],
                      rules: [{
                        required: true,
                        message: 'Выберите параметр вложения для валюты'
                      }]
                    })(
                      <Checkbox.Group
                        style={{ width: '100%' }}
                        onChange={value => handleChangeCheckboxGroup(value, 'currency_ids')}
                      >
                        <Row gutter={[0, 12]}>
                          <Col span={24}>
                            <Checkbox value='not'>Нет</Checkbox>
                          </Col>

                          {currencies.map((item, i) => (
                            <Col span={24} key={i}>
                              <Checkbox value={item.id}>{item.name}</Checkbox>
                            </Col>))}
                        </Row>
                      </Checkbox.Group>
                    )}
                  </Form.Item>

                  <Form.Item label='Учитывать параметр вложения "Стоимость"'>
                    {getFieldDecorator('isCost', {
                      initialValue: 'no'
                    })(
                      <Radio.Group onChange={() => resetFields(['cost'])}>
                        <Radio value='no'>Нет</Radio>
                        <Radio value='yes'>Стоимость указана</Radio>
                      </Radio.Group>
                    )}
                  </Form.Item>

                  <Form.Item>
                    {getFieldDecorator('cost', {
                      rules: [{
                        required: getFieldValue('isCost') === 'yes',
                        message: 'Необходимо указать сумму'
                      }]
                    })(
                      <InputNumber
                        precision={2}
                        placeholder='0.00'
                        disabled={getFieldValue('isCost') === 'no'}
                        style={{ width: '100%' }}
                      />
                    )}
                  </Form.Item>

                  <Form.Item label='Срок для подписания/согласования был установлен'>
                    {getFieldDecorator('isShouldBeSigned', {
                      initialValue: 'notDate'
                    })(
                      <Radio.Group onChange={() => resetFields(['should_be_signed_from', 'should_be_signed_to'])}>
                        <Radio value='notDate'>Не учитывать</Radio>
                        <Radio value='chooseDate'>Выбрать дату</Radio>
                      </Radio.Group>
                    )}
                  </Form.Item>

                  <Row gutter={[20, 12]}>
                    <Col span={12}>
                      <Form.Item label='От'>
                        {getFieldDecorator('should_be_signed_from', {
                          rules: [{
                            required: getFieldValue('isShouldBeSigned') === 'chooseDate',
                            message: 'Необходимо выбрать начальную дату'
                          }]
                        })(
                          <DatePicker
                            style={{ width: '100%' }}
                            placeholder='Начальная дата'
                            disabled={getFieldValue('isShouldBeSigned') === 'notDate'}
                          />
                        )}
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item label='До'>
                        {getFieldDecorator('should_be_signed_to', {
                          rules: [{
                            required: getFieldValue('isShouldBeSigned') === 'chooseDate',
                            message: 'Необходимо выбрать конечную дату'
                          }]
                        })(
                          <DatePicker
                            style={{ width: '100%' }}
                            placeholder='Конечная дата'
                            disabled={getFieldValue('isShouldBeSigned') === 'notDate'}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>

            <Layout.Filter.Actions>
              <Button onClick={() => setFilterVisible(false)}>Закрыть</Button>

              <Button
                type='primary'
                onClick={handleResetFilter}
                ghost
              >
                Сбросить фильтр
              </Button>

              <Button
                type='primary'
                htmlType='submit'
                icon='search'
                loading={isFetchingResults}
              >
                Найти
              </Button>
            </Layout.Filter.Actions>
          </Form>
        </Layout.Filter>

        <Layout.Results>
          {results.data?.length ? <Title level={3}>Найдено: {results.total}</Title> : ''}

          <Table
            className='ui-table-list'
            tableData={results}
            loading={isFetchingResults}
            getDocumentsWithParams={getDocumentsByActiveCompanyId}
            activeCompany={user.active_company_id}
            type='/documents'
            status='all'
            queryParams={queryParams}
          />
        </Layout.Results>
      </Layout>
    </LayoutScroll>
  )
})
