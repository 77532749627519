import * as types from '../types'

const initialState = {
  isFetchingDocuments: false,
  documents: [],

  isFetchingDocument: false,
  documentDetails: {},

  isFetchingDocumentTypes: false,
  types: [],

  isFetchingDocumentFields: false,
  fields: {
    required: [],
    optional: []
  },

  isFetchingDocumentCreating: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CUSTOM_DOCUMENTS_FETCHING:
      return {
        ...state,
        isFetchingDocuments: action.payload
      }

    case types.GET_CUSTOM_DOCUMENT_BY_ID_FETCHING:
      return {
        ...state,
        isFetchingDocument: action.payload
      }

    case types.GET_CUSTOM_DOCUMENT_TYPES_FETCHING:
      return {
        ...state,
        isFetchingDocumentTypes: action.payload
      }

    case types.GET_CUSTOM_DOCUMENT_FIELDS_FETCHING:
      return {
        ...state,
        isFetchingDocumentFields: action.payload
      }

    case types.CREATING_CUSTOM_DOCUMENT_FETCHING:
      return {
        ...state,
        isFetchingDocumentCreating: action.payload
      }

    case types.GET_CUSTOM_DOCUMENTS_SUCCESS:
      return {
        ...state,
        isFetchingDocuments: false,
        documents: action.payload.data
      }

    case types.GET_CUSTOM_DOCUMENT_BY_ID_SUCCESS:
      return {
        ...state,
        isFetchingDocument: false,
        documentDetails: action.payload.data
      }

    case types.GET_CUSTOM_DOCUMENT_TYPES_SUCCESS:
      return {
        ...state,
        isFetchingDocumentTypes: false,
        types: action.payload.data
      }

    case types.GET_CUSTOM_DOCUMENT_FIELDS_SUCCESS:
      return {
        ...state,
        isFetchingDocumentFields: false,
        fields: action.payload.data
      }

    default:
      return state
  }
}
