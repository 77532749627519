import { connect } from 'react-redux'
import actions from '../../store/actions'

import ContactDetailsPage from './ContactDetailsPage'

const mapStateToProps = state => ({
  contacts: state.contacts
})

const mapDispatchToProps = dispatch => ({
  getContactById: (id, history) => dispatch(actions.contacts.getContactById(id, history)),
  deleteContact: (id, history) => dispatch(actions.contacts.deleteContact(id, history)),
  updateContact: (id, data) => dispatch(actions.contacts.updateContact(id, data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactDetailsPage)
