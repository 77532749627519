import React from 'react'
import queryString from 'query-string'

import {
  Button,
  Tooltip,
  Popconfirm
} from 'antd'

import history from '../../../../history'

import { Column } from './styled'

export default ({
  onInvite,
  onDelete,
  onEdit,
  details
}) => (
  <Column>
    {(details.contact_data.user_data.is_emailed && details.contact_data.user_data.last_login) || details.contact_data.company_number === '000000000' ? (
      <>
        <Column.Item>
          <Tooltip
            title='Написать контакту'
            placement='topRight'
            arrowPointAtCenter
          >
            <Button
              type='primary'
              icon='mail'
              onClick={() => history.push(`/new-document?${queryString.stringify({ contacts: [details.contact_data.user_email] }, { arrayFormat: 'bracket' })}`)}
            />
          </Tooltip>
        </Column.Item>

        <Column.Item>
          <Tooltip
            title='Редактировать контакт'
            placement='topRight'
            arrowPointAtCenter
          >
            <Button icon='edit' onClick={onEdit} />
          </Tooltip>
        </Column.Item>

        <Column.Item>
          <Tooltip
            title='Удалить контакт'
            placement='topRight'
            arrowPointAtCenter
          >
            <Popconfirm
              title='Удалить контакт?'
              okText='Да, удалить'
              cancelText='Отмена'
              onConfirm={onDelete}
            >
              <Button icon='delete' />
            </Popconfirm>
          </Tooltip>
        </Column.Item>
      </>
    ) : (
      <Column.Item>
        <Tooltip
          title='Отправить приглашение к обмену документами в электронном виде'
          placement='topRight'
          arrowPointAtCenter
        >
          <Button
            type='primary'
            icon='user-add'
            onClick={onInvite}
          >
            Пригласить
          </Button>
        </Tooltip>
      </Column.Item>
    )}
  </Column>
)
