import React, { useState, useEffect } from 'react'

import { Checkbox, Col, Form, Icon, Input, InputNumber, Row, Select, Table } from 'antd'

import { Button, FooterFixed, LayoutScroll } from '../../components'

import { Column, Layout } from './styled'
import { Link } from 'react-router-dom'

const { Option } = Select

export default Form.create()(({
  customDocument: {
    isFetchingDocumentTypes,
    isFetchingDocumentFields,
    isFetchingDocumentCreating,
    types,
    fields: {
      required,
      optional
    }
  },
  currency,
  form: {
    getFieldDecorator,
    validateFields
  },
  getCustomDocumentTypes,
  getCustomDocumentFields,
  createCustomDocument,
  getCurrencies
}) => {
  const [isVAT, setIsVAT] = useState(false)
  const [shouldBeSigned, setShouldBeSigned] = useState(false)
  const [isDouble, setIsDouble] = useState(false)
  const [selectedType, setSelectedType] = useState(null)
  const [columns, setColumns] = useState({})
  const [dataSource, setDataSource] = useState({})

  useEffect(() => {
    getCustomDocumentTypes()
  }, [])

  useEffect(() => {
    getCurrencies()
  }, [])

  useEffect(() => {
    if (selectedType !== null) {
      const dataSourceList = {}

      optional.map((item, i) => {
        dataSourceList[`table-${i}`] = [{ key: 1, ...item }]
      })

      setDataSource(dataSourceList)
    }
  }, [optional])

  useEffect(() => {
    if (selectedType !== null) {
      const columns = {}

      const staticColumn = tableIndex => ({
        width: 40,
        render: record => (
          <Button
            type="danger"
            onClick={() => handleDeleteColumn(record.key, tableIndex)}
            icon="close"
            size="small"
          />
        )
      })

      optional.map((item, i) => {
        const tableIndex = `table-${i}`

        columns[tableIndex] = [{
          title: item.name,
          render: record => {
            if (item.datatype === 'form') {
              return (
                <Column>
                  <Column.Inner>

                    <Input style={{ width: 700 }}
                           onChange={e => handleChangeField(e.target.value, 'field_title', tableIndex, record.key)}/>

                    <InputNumber
                      placeholder="Кол-во ед"
                      onChange={value => handleChangeField(value, 'field_value', tableIndex, record.key)}
                      min={0}
                    />

                    {item.postfix_array.length ? (
                      <Select placeholder="Тип"
                              onChange={value => handleChangeField(value, 'field_postfix', tableIndex, record.key)}>
                        {item.postfix_array.map((el, i) => (
                          <Option key={i} value={el}>{el}</Option>
                        ))}
                      </Select>
                    ) : null}

                    <InputNumber
                      placeholder="Цена за ед"
                      onChange={value => handleChangeField(value, 'field_price', tableIndex, record.key)}
                      min={0}
                    />

                    {currency ? (
                      <Select placeholder="Выберите валюту"
                              onChange={value => handleChangeField(value, 'field_currency', tableIndex, record.key)}>
                        {currency.map((el, i) => (
                          <Option key={i} value={el.name}>{el.name}</Option>
                        ))}
                      </Select>
                    ) : null}

                    {isVAT && (
                      <InputNumber
                        placeholder="НДС"
                        onChange={value => handleChangeField(value, 'vat', tableIndex, record.key)}
                        min={0}
                      />
                    )}
                  </Column.Inner>
                </Column>
              )
            }
            if (item.datatype === 'area') {
              return <Input.TextArea/>
            }
            if (item.datatype === 'text') {
              return <Input/>
            }
          }
        }, staticColumn(tableIndex)]
      })

      setColumns(columns)
    }
  }, [isVAT, selectedType, optional, dataSource])

  const handleChangeType = typeId => {
    getCustomDocumentFields(typeId)
    setSelectedType(typeId)
  }

  const setFieldType = item => {
    if (item.datatype === 'area') {
      return <Input.TextArea placeholder={`Введите ${item.name}`}/>

    }
    if (item.datatype === 'text') {
      return <Input placeholder={`Введите ${item.name}`}/>
    }
  }

  const handleAddColumn = index => {
    const newData = {
      key: dataSource[index].length + 1,
      id: dataSource[index][0]?.id ?? 0
    }

    setDataSource({
      ...dataSource,
      [index]: [
        ...dataSource[index],
        newData
      ]
    })
  }

  const handleDeleteColumn = (key, tableIndex) => {
    setDataSource({
      ...dataSource,
      [tableIndex]: dataSource[tableIndex].filter(item => item.key !== key)
    })
  }

  const handleChangeField = (value, field, table, key) => {
    setDataSource({
      ...dataSource,
      [table]: dataSource[table].map((item, i) => {
        if (item.key === key) {
          return {
            ...item,
            [field]: value
          }
        }

        return item
      })
    })
  }

  const handleSubmit = e => {
    e.preventDefault()

    let fields = []

    optional.map((_, i) => {
      fields = [...fields, ...dataSource[`table-${i}`]]
    })

    validateFields((err, values) => {
      if (!err) {
        createCustomDocument({
          ...values,
          type_id: selectedType,
          with_vat: isVAT,
          should_be_signed: shouldBeSigned,
          is_double: isDouble,
          fields: fields.map(item => {
            return {
              field_id: item.id,
              field_title: item.field_title || '',
              field_value: item.field_value || 0,
              field_price: item.field_price || 0,
              field_postfix: item.field_postfix || '',
              field_currency: item.field_currency || '',
              vat: item.vat || ''
            }
          })
        })
      }
    })
  }

  return (
    <LayoutScroll withFooter>
      <Layout>
        <Layout.Header>
          <Button
            hidden={selectedType === null}
            className="ui-btn-goback"
            onClick={() => setSelectedType(null)}
          >
            <Icon type="left"/>
          </Button>
          <Layout.Title level={3}>Создание документа</Layout.Title>

          <Layout.Header.Inner>
            <Checkbox onChange={e => setIsVAT(e.target.checked)}>С НДС</Checkbox>
            <Checkbox onChange={e => setShouldBeSigned(e.target.checked)}>Обязателен к подписанию</Checkbox>
            <Checkbox onChange={e => setIsDouble(e.target.checked)}>Составлен в двух экземплярах</Checkbox>
          </Layout.Header.Inner>
        </Layout.Header>

        {selectedType !== null && (
          <Layout.Fields>
            <Form
              onSubmit={handleSubmit}
              colon={false}
              //hideRequiredMark
            >
              <Row gutter={[20, 12]}>
                {required.map((item, i) => (
                  <Col span={12} key={i}>
                    <Form.Item label={item.name}>
                      {getFieldDecorator(item.validator_name, {
                        rules: [{
                          required: true,
                          message: 'Это поле обязательно'
                        }]
                      })(
                        setFieldType(item)
                      )}
                    </Form.Item>
                  </Col>
                ))}
              </Row>

              {!isVAT && (
                <Row>
                  <Col>
                    <Form.Item label={'Причина отсутствия НДС'}>
                      {getFieldDecorator('vat_comment', {
                        rules: [{
                          required: false,
                        }]
                      })(
                        <Input.TextArea name={'vat_comment'}/>
                      )}
                    </Form.Item>
                  </Col>
                </Row>)}

              {optional.map((_, i) => (
                <Layout.Table key={i}>
                  <Table
                    dataSource={dataSource[`table-${i}`]}
                    columns={columns[`table-${i}`]}
                    pagination={false}
                    size="small"
                    bordered
                  />

                  <Button
                    type="primary"
                    onClick={() => handleAddColumn(`table-${i}`)}
                    icon="plus"
                    style={{ marginTop: '1.6rem' }}
                    ghost
                  >
                    Добавить значение
                  </Button>
                </Layout.Table>
              ))}

              <FooterFixed>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isFetchingDocumentCreating}
                >
                  Сохранить
                </Button>
              </FooterFixed>
            </Form>
          </Layout.Fields>)}

        <Layout.Box>
          {
            selectedType === null &&
            <>
            <Layout.ButtonBox>
              <Table
                bordered={false}
                loading={false}
                pagination={false}
                style={{ width: '100%', height: '100%' }}
                showHeader={false}
                columns={[
                  {
                    title: 'Name',
                    dataIndex: 'name',
                  }
                ]}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: event => {handleChangeType(record.id)},
                  }
                }}
                dataSource={types}
                scroll={true}
              />
            </Layout.ButtonBox>

            <Layout.ButtonBox>
              <Button
                style={{float: 'right'}}
                type="primary"
                onClick={() => {}}
                hidden={selectedType !== null}
                size="large"
              >
                <Link>Запросить разработку шаблона и формы для нового документа</Link>
              </Button>
            </Layout.ButtonBox>
            </>
          }
        </Layout.Box>
      </Layout>
    </LayoutScroll>
  )
})
