import React, { useEffect, useState } from 'react'
import { Redirect, Route, useLocation } from 'react-router-dom'

import { Modal, notification } from 'antd'

import {
  Button,
  Layout,
  LayoutInner,
  LayoutContent,
  Header,
  Footer,
  MainMenu
} from '../'

import history from '../../history'
import { checkActiveTariff, getActiveCompany } from '../../utils'

import { faviconIcon } from '../../resources/img'

import { getGeneralAlert } from '../../helpers'
import { LayoutInnerMain } from '../LayoutInner/styled'

const favicon = document.querySelector(('[rel=icon]'))

const PrivateRoute = ({
  component: Component,
  config,
  user: { data, isFetching },
  getUser,
  getTariffications,
  getDocumentsStatuses,
  documentsStatuses,
  getAttachmentsTypes,
  getDocumentsUnreadCount,
  getCurrenciesList,
  getCompanies,
  ...rest
}) => {
  const [availableTariff, setAvailableTariff] = useState(false)
  const [activeCompany, setActiveCompany] = useState(null)

  // eslint-disable-next-line spaced-comment
  const isIE = /*@cc_on!@*/false || !!document.documentMode
  const authToken = window.localStorage.getItem('authToken') || window.sessionStorage.getItem('authToken')

  const location = useLocation()

  const pathname = location.pathname.toLowerCase()

  useEffect(() => {
    if(!documentsStatuses?.length) {
      getDocumentsStatuses()
    }

    if(pathname.includes('global') || pathname.includes('new-document') || pathname.includes('documents/')) {
      getAttachmentsTypes()
      getCurrenciesList()
    }

    if(pathname.includes('companies')) {
      getCompanies()
    }
  }, [pathname])

  useEffect(() => {
    let token = window.localStorage.getItem('authToken') || window.sessionStorage.getItem('authToken')
    if (token) {
      getDocumentsUnreadCount()
    }
  }, [location.pathname, location.search])

  useEffect(() => {
    if (authToken) {
      getUser()
      getTariffications()
      if (isIE) {
        // setTimeout(() => {
        //   if (window.conn === null || window.conn === undefined) {
        //     window.pluginLoaded()
        //   }
        // }, 1500)
      }
    }
  }, [getUser, isIE])

  useEffect(() => {
    if (config.data.co_brand_config) {
      document.title = 'MTC SmartDoc'
      favicon.href = faviconIcon
    } else {
      document.title = 'Quidox'
      favicon.href = `${process.env.REACT_APP_URL}/favicon.ico`
    }
  }, [config.data.co_brand_config])

  useEffect(() => {
    if (data) {
      setActiveCompany(data.hasOwnProperty('active_company_object') && getActiveCompany(data))
    }
  }, [data])

  useEffect( () => {
    const handleRedirectToLogin = () => {
      setAvailableTariff(false)
      localStorage.removeItem('authToken')
      notification['error']({
        message: 'Ошибка тарификации. Пожалуйста, обратитесь к администратору'
      })
    }

    if (activeCompany && Object.keys(activeCompany).length) {
      if(activeCompany.tarification){
        setAvailableTariff(checkActiveTariff(activeCompany.tarification))
      } else {
        handleRedirectToLogin()
      }
      if(!activeCompany) {
        handleRedirectToLogin()
      }
    }
  }, [activeCompany && Object.keys(activeCompany).length, activeCompany?.tarification])

  const handleChangeTariff = () => {
    history.push(history.push(`/companies/${activeCompany.company_id}`))
    setAvailableTariff(false)
  }

  const handleStayOnPage = () => {
    setAvailableTariff(false)
  }

  if (isFetching) {
    return 'Loading...'
  }

  return (
    <Route
      {...rest}
      render={props =>
        (window.localStorage.getItem('authToken') || window.sessionStorage.getItem('authToken')) ? (
          <Layout>
            {availableTariff && (
              <Modal
                visible
                footer={null}
                closable={false}
              >
                {getGeneralAlert(config.data.co_brand_config, activeCompany)}

                <div style={{ marginTop: '2rem' }}>
                  {config.data.co_brand_config !== null && activeCompany.is_owner === true && (
                    <Button type='primary' onClick={handleChangeTariff}>
                      Перейти к продлению тарифа
                    </Button>)}

                  {config.data.co_brand_config === null && (
                    <Button type='primary' onClick={handleChangeTariff}>
                      Перейти к пополнению баланса
                    </Button>)}

                  <Button type='link' onClick={handleStayOnPage}>
                    {config.data.co_brand_config ? 'Отмена' : 'Пополнить позже'}
                  </Button>
                </div>
              </Modal>)}

            <Header />

            <LayoutInner>
              <LayoutInnerMain>
                <MainMenu />
              </LayoutInnerMain>

              <LayoutContent>
                <Component {...props} />
              </LayoutContent>
            </LayoutInner>

            <Footer />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )}
    />
  )
}

export default PrivateRoute
