import React, { useState } from 'react'
import axios from 'axios'
import fileDownload from 'js-file-download'

import { message, Tooltip } from 'antd'

import { Button } from '../../../../components'
import { api } from '../../../../services'

export default ({ document }) => {
  const [isFetching, setIsFetching] = useState([false, false])

  const downloadArchive = (document, withCert = false) => {
    setIsFetching([!withCert, withCert])
    let token = window.localStorage.getItem('authToken') || window.sessionStorage.getItem('authToken')
    withCert = token ? withCert : withCert + `?shared_token=${btoa(process.env.REACT_APP_SECRET_KEY)}`

    api.document.downloadDocument(document.id, withCert)
      .then(({ data }) => {
        if (data.success) {
          let token = window.localStorage.getItem('authToken') || window.sessionStorage.getItem('authToken')
          let path = token ? data.data : data.data + `?shared_token=${btoa(process.env.REACT_APP_SECRET_KEY)}`
          axios.get(path, {
            'responseType': 'arraybuffer',
            headers: {
              'Authorization': 'Bearer ' + token,
              'Access-Control-Expose-Headers': 'Content-Disposition,X-Suggested-Filename'
            }
          })
            .then(({ data }) => {
              if (data) {
                fileDownload(data, `${document.author.company_number}_${document.created_at.split(' ')[0].split('-').join('')}_${document.name}.zip`)
                message.success('Архив успешно загружен!')

                setIsFetching([false, false])
              }
            })
            .catch(error => {
              message.error(error.message)
            })
        } else {
          throw new Error(data.error)
        }
      })
      .catch(error => {
        message.error(error.message)
      })
  }

  return (
    <Button
      type='primary'
      icon={isFetching[1] ? 'loading' : 'download'}
      onClick={() => downloadArchive(document, true)}
    >
      Скачать всe
    </Button>
  )
}
