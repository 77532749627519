import React, { useEffect, useState } from 'react'
import { checkBrowser } from '../../utils'

import {
  Modal,
  Radio,
  Typography,
  message, notification
} from 'antd'

import { Button } from '../'

import { api } from '../../services'

import { explorer } from './images'

import {
  Layout,
  Explorer
} from './styled'

const { Title } = Typography

export default ({
  isNew,
  status,
  documentId,
  visible,
  file,
  onCancel,
  verifyFile,
  getDocumentById
}) => {
  const [selectedAttr, setSelectedAttr] = useState(null)
  const [certList, setCertList] = useState([])
  const [selectedCert, setSelectedCert] = useState(0)

  useEffect(() => {
    if (visible && checkBrowser('ie')) {
      window.pluginAttrLoaded()
    }
  }, [visible])

  const handleChangeAttribute = e => {
    let certs = []
    const value = e.target.value

    setSelectedAttr(value)

    certs = window.findSelectedAttr(value)

    setTimeout(function () {
      setCertList(certs)
    }, 500)
  }

  const handleSubmit = () => {
    if (!selectedAttr) {
      return message.error('Необходимо выбрать атрибут')
    }

    api.files.getBase64File(file.id)
      .then(({ data }) => {
        if (isNew === false) {
          //window.location.reload();
        }
        if (data.success) {
          try {
            const attrData = window.signAttrProcess(data.data.encoded_base64_file, selectedAttr, selectedCert)

            const newData = {
              id: file.id,
              hash: attrData.signedData,
              data: attrData.verifiedData,
              status
            }

            api.documents.attachmentSignCanConfirm({
              key: attrData.verifiedData.key,
              is_attribute: true,
              attachment_id: file.id
            })
              .then(({ data }) => {
                if (data.success) {
                  verifyFile(newData)
                    .then((response) => {
                      if (response.success) {
                        message.success('Файл успешно подписан!')

                        window.pluginClosed()
                        onCancel()

                        if (isNew === false) {
                          window.location.reload();
                        }

                      } else {
                        notification['error']({
                          message: 'Списки отозванных сертификатов  (СОСы) устарели. ' +
                              'Обновите их в Персональном менеджере сертификатов в пункте меню "Сервис". ' +
                            'Как обновить СОСы подробно описано ТУТ'
                        })
                      }
                    })
                    .catch(error => {
                      notification['error']({
                        message: error.message
                      })
                    })
                } else {
                  throw new Error(data.error)
                }
              })
              .catch(error => {
                notification['error']({
                  message: error.message
                })
              })
          } catch (error) {
            notification['error']({
              message: error.message
            })
          }
        } else {
          notification['error']({
            message: data.error
          })
        }
      })
      .catch(error => {
        notification['error']({
          message: error.message
        })
      })
  }

  return (
    <Modal
      width={600}
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Layout>
        {checkBrowser('ie') ? (
          <>
            <Title level={2}>Атрибутный сертификат</Title>

            <Layout.Attrs>
              <Title level={4}>Выберите атрибут</Title>

              <Radio.Group onChange={handleChangeAttribute}>
                <Radio.Button value='1.2.112.1.2.1.1.1.1.2'>
                  Уникальный номер налогоплательщика (1.2.112.1.2.1.1.1.1.2)
                </Radio.Button>
              </Radio.Group>
            </Layout.Attrs>

            <Layout.Attrs>
              <Title level={4}>Выберите сертификат</Title>

              <Radio.Group onChange={e => setSelectedCert(e.target.value)}>
                {certList.map((item, i) => (
                  <Radio.Button key={i} value={i}>
                    {item.GetAttributeByOid('1.2.112.1.2.1.1.1.1.2', 0).ValueAsString}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Layout.Attrs>

            <Layout.Submit>
              <Button
                type='primary'
                onClick={handleSubmit}
              >
                Подписать
              </Button>

              <Button onClick={onCancel}>Отмена</Button>
            </Layout.Submit>
          </>
        ) : (
          <Explorer>
            <Explorer.Picture src={explorer} />

            <Explorer.Inner>
              <Explorer.Title>Атрибутный сертификат</Explorer.Title>
              <Explorer.Details>Подписание возможно только в браузере Internet Explorer</Explorer.Details>
            </Explorer.Inner>
          </Explorer>
        )}
      </Layout>
    </Modal>
  )
}
