import React from 'react'
import axios from 'axios'

import {
  message,
  notification
} from 'antd'

import { Button } from '../../components'

import { api } from '../../services'
import history from '../../history'

import { Layout } from './styled'

export default ({
  config: { data }
}) => {
  const isConfig = data.co_brand_config
  const isIE = /*@cc_on!@*/!!document.documentMode;

  const handleSignLogin = () => {
    let token = window.localStorage.getItem('authToken') || window.sessionStorage.getItem('authToken')
      try {
        let sign = {}
        sign.data = 'bmV3IGNvbXBhbnkK'
        sign.isDetached = true
        sign.token_qdx = 'Bearer ' + token
        const request = axios.post('http://127.0.0.1:8083/sign', sign)
          .then(({ data }) => {
            if (data.cms) {
              let signObj = {}
              signObj.raw_sign = data.cms
              api.user.loginByEsign(signObj)
                .then(({ data }) => {
                  if (data.success) {
                    notification['success']({
                      message: 'Вы успешно вошли!'
                    })
                    window.sessionStorage.setItem('authToken', data.data.token)
                    history.push({ pathname: '/' })
                  } else {
                    notification['error']({
                      message: 'Ошибка входа!'
                    })
                  }
                })
            }
          })
          .catch(function (error) {
            message.error(error.message)
          })
      } catch (error) {
        notification['error']({
          message: 'Утилита ТЗИ не запущена!'
        })
      }
  }

  return (
    <Layout>
      <Layout.Inner>
        <Layout.Details>
          Продолжая процедуру входа Вы подтверждаете факт ознакомления и принятия условий {isConfig
            ? <a href={data.co_brand_config.terms_link}>Правил использования.</a>
            : <><a href='https://quidox.by/agreement/'>Публичного договора</a>, согласие с <a href='https://quidox.by/privacy_policy/'>Политикой конфиденциальности</a></>}
        </Layout.Details>

        <Button
          type='primary'
          onClick={handleSignLogin}
          block
        >
          Продолжить
        </Button>
      </Layout.Inner>
    </Layout>
  )
}
