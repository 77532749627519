import styled from 'styled-components'

const Layout = styled.div`
`

Layout.Inner = styled.div`
  padding: 2rem;
`

export {
  Layout
}
