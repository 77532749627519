import http from './http'

const getContacts = params => {
  return http({
    url: 'contacts',
    method: 'GET',
    params
  })
}

const getContactById = id => {
  return http({
    url: `contacts/${id}`,
    method: 'GET'
  })
}

const createContact = data => {
  return http({
    url: 'contacts',
    method: 'POST',
    data
  })
}

const updateContact = (id, data) => {
  return http({
    url: `contacts/${id}`,
    method: 'PATCH',
    data
  })
}

const deleteContact = id => {
  return http({
    url: `contacts/${id}`,
    method: 'DELETE'
  })
}

const importContacts = data => {
  return http({
    url: 'contacts/import',
    method: 'POST',
    data
  })
}

export {
  getContacts,
  getContactById,
  createContact,
  updateContact,
  deleteContact,
  importContacts
}
