import styled from 'styled-components'

const Layout = styled.div`
  padding: 1.6rem;
`

Layout.Content = styled.div`
  iframe {
    width: 100%;
  }
`

Layout.Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2rem;

  && {
    .ant-btn {
      margin-left: 2rem;
    }
  }
`

export {
  Layout
}
