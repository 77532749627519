import { connect } from 'react-redux'

import actions from '../../../../store/actions'

import HeaderSearch from './HeaderSearch'

const mapStateToProps = state => ({
  user: state.user,
  config: state.config
})

const mapDispatchToProps = dispatch => ({
  processGlobalSearch: value => dispatch(actions.config.processGlobalSearch(value))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderSearch)
