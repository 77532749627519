import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Icon, message, notification } from 'antd'

import { Button, LayoutScroll, Table } from '../../components'

import { api } from '../../services'

import { IconSend, IconSendDisable } from './images'

import { ModalProcess } from './components'

import { Layout } from './styled'

const defaultState = {
  isVisible: false,
  fetching: false,
  messages: [],
  processedFiles: 0,
  disabled: false,
  disabledCloseButton: false,
  buttonsFetching: [false, false, false],
  type: '',
  not_applied_attachments_count: 0,
  idsForRemove: null,
  idsForSend: null,
  tariff_was_expired: false,
  send_all_with_status: null,
}

const defaultQueueStatus = {
  queue_transaction_status: 0
}

export default ({
  user: { data },
  documents: {
    documents,
    isFetching
  },
  getDocumentsByActiveCompanyId,
  location
}) => {
  const history = useHistory()

  const [state, setState] = useState({ ...defaultState, tariff_was_expired: data?.active_company_object?.tariff_was_expired })
  const [queue, setQueue] = useState({ ...defaultQueueStatus })

  const params = new URLSearchParams(location.search)
  const status = params.get('status')

  useEffect(() => {
    checkTransactionStatus()
  }, [])

  const updStatus = () => {
    api.user.getUser()
      .then((response) => {
        let transactionStatus = response.data.data.queue_transaction_status
        setQueue({
          ...queue,
          queue_transaction_status: transactionStatus
        })
        if (transactionStatus === 1 && localStorage.getItem('startInterval') === null) {
          localStorage.setItem('startInterval', setInterval(updStatus, 10000))
        }
        if (transactionStatus === 2) {
          notification['success']({
            message: 'Отправка сообщений завершена'
          })
          api.user.updateUser({ 'queue_transaction_status': 0 })
            .then(({ data }) => {
              if (!data.success) {
                throw new Error(data.error)
              }
              setQueue({
                ...queue,
                queue_transaction_status: response.data.data.queue_transaction_status
              })
            })
            .catch(error => {
              message.error(error.message)
            })
            .finally(() => window.location.reload())
          clearInterval(localStorage.getItem('startInterval'))
          localStorage.removeItem('startInterval')
        }
      })
  }

  const checkTransactionStatus = () => {
    updStatus()
  }

  const showVerifyModal = () => {
    setState({
      ...state,
      buttonsFetching: [true, false, false]
    })
    api.documents.getDocumentsByActiveCompanyId(data.active_company_id, {
      status: +status,
      selection_type: 'document',
      is_minified: true
    })
      .then(({ data }) => {
        if (data.success) {
          const notApplied = data.data.data.reduce((acc, el) => acc + (el.document.not_applied_attachments_count), 0)
          setState({
            ...state,
            isVisible: true,
            fetching: false,
            type: 'verify',
            messages: data.data.data,
            not_applied_attachments_count: notApplied
          })
        }
      })
      .catch(error => {
        notification['error']({
          message: error.message
        })
      })
  }

  const showRemoveModal = () => {
    setState({
      ...state,
      buttonsFetching: [false, true, false]
    })
    api.documents.getDocumentsByActiveCompanyId(data.active_company_id, {
      status: +status,
      selection_type: 'document',
      is_minified: true
    })
      .then(({ data }) => {
        if (data.success) {
          const notApplied = data.data.data.length
          const ids = data.data.data.map(i => i.id)
          setState({
            ...state,
            isVisible: true,
            fetching: false,
            type: 'remove',
            messages: data.data.data,
            idsForRemove: ids,
            not_applied_attachments_count: notApplied
          })
        }
      })
      .catch(error => {
        console.error(error)
        notification['error']({
          message: error.message
        })
      })
  }

  const showSendModal = () => {
    setState({
      ...state,
      buttonsFetching: [false, false, true]
    })

    if (+status === 9 || +status === 10) {
      setState({
        ...state,
        isVisible: true,
        fetching: false,
        type: 'send',
        messages: [],
        idsForSend: null,
        not_applied_attachments_count: 0
      })
    } else {
      api.documents.getDocumentsByActiveCompanyId(data.active_company_id, {
        status: +status,
        selection_type: 'document',
        is_minified: true
      })
        .then(({ data }) => {
          if (data.success) {
            const notApplied = data.data.data.length
            const ids = [...new Set(data.data.data.map(i => i.document_id))]

            setState({
              ...state,
              isVisible: true,
              fetching: false,
              type: 'send',
              messages: data.data.data,
              idsForSend: ids,
              not_applied_attachments_count: notApplied,
              send_all_with_status: status
            })
          }
        })
        .catch(error => {
          console.error(error)
          notification['error']({
            message: error.message
          })
        })
    }
  }

  const proccesFilesForVerifyFile = async (bool, files) => {
    for (const file of files) {
      if (bool || file.status.status_data.id === 3) {
        const base64 = await api.files.getBase64File(file.id)

        try {
          const sertificationObject = await window.signProcess(base64.data.data.encoded_base64_file, file.hash_for_sign, true)

          const verifiedData = {
            id: file.id,
            hash: sertificationObject.signedData,
            data: sertificationObject.verifiedData,
            hash_for_sign: sertificationObject.hex,
            status: bool ? null : 5
          }

          const confirm = await api.documents.attachmentSignCanConfirm({
            key: sertificationObject.verifiedData.key,
            attachment_id: file.id
          })

          if (confirm.data.success) {
            api.files.verifyFile(verifiedData)
          }
        } catch (error) {
          notification['error']({
            message: error.message
          })
        }
      }
    }
  }

  const proccesMessageForVerifyFiles = async messages => {
    for (const [index, message] of messages.entries()) {
      await proccesFilesForVerifyFile(message.can_be_signed, message.document.attachments)
    }
  }

  const multipleVerifyPreparation = () => {
    window.pluginLoaded()

    // setTimeout(() => {
    multipleVerify()
    // }, 3000)
  }

  const multipleVerify = async () => {
    setState({
      ...state,
      disabled: true,
      disabledCloseButton: true
    })

    proccesMessageForVerifyFiles(state.messages)
      .then(() => {
      setState({
        ...state,
        isVisible: false,
        disabledCloseButton: false
      })

      notification.success({
        message: 'Подпись завершена! закройте окно и перезагрузите страницу'
      })
    })
      .catch(error => {
        notification['error']({
          message: error.message
        })
      })
  }

  const multipleRemove = () => {
    setState({
      ...state,
      disabled: true,
      disabledCloseButton: true
    })

    api.documents.removeDocumentsByIds({
      ids: state.idsForRemove
    }).then(() => window.location.reload())
  }

  const multipleSend = () => {
    setState({
      ...state,
      disabled: true,
      disabledCloseButton: true
    })

    let chain = Promise.resolve()

    if (+status === 9 || +status === 10) {
      chain = chain.then(() => asyncSend(null))
      notification['success']({
        message: 'Выполняется групповая отправка'
      })
      checkTransactionStatus()
    } else {
      state.idsForSend.forEach(id => {
        chain = chain.then(() => asyncSend(id))
      })
    }

    chain.finally(() => {
      window.location.reload()
    })
  }

  const asyncSend = async id => {
    if (id !== null) {
      await api.documents.sendDocumentToUser({
        document_ids: [id],
        user_company_id: JSON.stringify([])
      })
    } else {
      await api.documents.sendDocumentToUser({
        send_all_with_status: status
      })
    }
  }

  return (
    <LayoutScroll withFooter>
      <Layout>
        <Layout.Inner>
          <Layout.Table>
            {!!(documents.data && documents.data.length > 0) && +status !== 3 && +status !== 4 && (
              <Layout.Table.Header>
                <Layout.Table.Column>
                  <Button
                    type="link"
                    icon={state.buttonsFetching[0] ? 'loading' : 'edit'}
                    onClick={showVerifyModal}
                    disabled={queue.queue_transaction_status === 1}
                  >
                    Подписать все
                  </Button>

                  <Button
                    type="link"
                    onClick={showSendModal}
                    disabled={state.tariff_was_expired || queue.queue_transaction_status === 1}
                    ghost={state.tariff_was_expired}
                    style={{ border: 'none' }}
                  >
                    {state.buttonsFetching[0] ? (
                      <Icon type="loading"/>
                    ) : (
                      <Icon component={state.tariff_was_expired ? IconSendDisable : IconSend}/>
                    )}

                    Отправить все
                  </Button>

                </Layout.Table.Column>

                <Layout.Table.Column>
                  <Button
                    type="link"
                    icon={state.buttonsFetching[0] ? 'loading' : 'delete'}
                    disabled={+status === 11 || queue.queue_transaction_status === 1}
                    onClick={showRemoveModal}
                  >
                    Удалить все
                  </Button>
                </Layout.Table.Column>
              </Layout.Table.Header>)}

            <Layout.Table.Body>
              <Table
                className="ui-table-list"
                tableData={documents}
                loading={isFetching}
                getDocumentsWithParams={getDocumentsByActiveCompanyId}
                activeCompany={data.active_company_id}
                type={history.location.pathname === '/documents' ? 'document' : 'attachment'}
                status={+status}
              />
            </Layout.Table.Body>
          </Layout.Table>

          <ModalProcess
            visible={state.isVisible}
            data={state}
            onVerify={multipleVerifyPreparation}
            onRemove={multipleRemove}
            onSend={multipleSend}
            onCancel={() => setState({ ...defaultState })}
          />
        </Layout.Inner>
      </Layout>
    </LayoutScroll>
  )
}
