import React, { useEffect, useState } from 'react'
import moment from 'moment'

import { notification, Tooltip } from 'antd'

import {
  getActiveCompany,
  getActiveCompanyObjectBalance,
  getActiveCompanyObjectTarificationBytes
} from '../../../../utils'

import { Tariff } from './styled'

const HeaderTariff = ({
  user: {
    data
  }
}) => {
  const [activeCompany, setActiveCompany] = useState(null)

  useEffect(() => {
    if (data) {
      setActiveCompany(data.hasOwnProperty('active_company_object') && getActiveCompany(data))
    }
  }, [data])

  return (
    <Tariff>
      <Tariff.Item>
        <Tariff.Item.Label>
          Действий:
        </Tariff.Item.Label>

        <Tariff.Item.Value>
          {activeCompany ? activeCompany.tarification.max_actions : 0}
        </Tariff.Item.Value>
      </Tariff.Item>

      <Tariff.Item>
        <Tariff.Item.Label>До окончания:</Tariff.Item.Label>

        <Tariff.Item.Value>
          {`${activeCompany ? moment.utc(activeCompany.tarification.expired_at).diff(moment(), 'days') : 0} дней`}
        </Tariff.Item.Value>

        {activeCompany && (
          <Tooltip placement='bottom' title={`Тариф: ${activeCompany.tarification?.tarification_data?.name}`}>
            <Tariff.Item.Help type='question-circle' />
          </Tooltip>)}
      </Tariff.Item>
    </Tariff>
  )
}

export default HeaderTariff
