import { connect } from 'react-redux'
import actions from '../../store/actions'

import ContactsImportPage from './ContactsImportPage'

const mapStateToProps = state => ({
  user: state.user.data,
  contacts: state.contacts
})

const mapDispatchToProps = dispatch => ({
  importContacts: (data, history) => dispatch(actions.contacts.importContacts(data, history))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactsImportPage)
