import styled from 'styled-components'

import { Layout } from 'antd'

import { styleguide } from '../../constants'

const { colors } = styleguide

const LayoutContent = styled(Layout.Content)`
  margin-left:1rem;
  width: calc(100% - 29.4rem);
  background-color: ${colors.white};
  border-radius: .4rem;
  position: relative;
`

export {
  LayoutContent
}
