import React from 'react'

import { notification } from 'antd'

import { Button } from '../../components'

import { api } from '../../services'
import history from '../../history'

import { Layout } from './styled'

export default ({
  config: { data }
}) => {
  const isConfig = data.co_brand_config
  const isIE = /*@cc_on!@*/!!document.documentMode;

  const handleSignLogin = () => {
    try {
      let sertificationObject

      if (!isIE) {
        notification['warning']({
          message: 'Вход с помощью ЭЦП может быть осуществлен только из браузера Internet Explorer '
        })
      } else {
        if(window.signProcess) {
          sertificationObject = window.signProcess('111', '111')
        } else {
          notification['error'] ({
            message: 'Ключ ЭЦП не обнаружен.'
          })
        }
      }

      if(sertificationObject?.verifiedData){
        api.user.loginByEsign(sertificationObject.verifiedData)
          .then(({ data }) => {
            if (data.success) {
              notification['success']({
                message: 'Вы успешно вошли!'
              })
              window.sessionStorage.setItem('authToken', data.data.token)
              history.push({ pathname: '/' })
            } else {
              notification['error']({
                message: 'Ошибка входа!'
              })
            }
          })
      }
    } catch (error) {
      notification['error']({
        message: error.message
      })
    }
  }

  return (
    <Layout>
      <Layout.Inner>
        <Layout.Details>
          Продолжая процедуру входа Вы подтверждаете факт ознакомления и принятия условий {isConfig
            ? <a href={data.co_brand_config.terms_link}>Правил использования.</a>
            : <><a href='https://quidox.by/agreement/'>Публичного договора</a>, согласие с <a href='https://quidox.by/agreement/'>Политикой конфиденциальности</a></>}
        </Layout.Details>

        <Button
          type='primary'
          onClick={handleSignLogin}
          block
          disabled={!isIE}
        >
          Продолжить
        </Button>
      </Layout.Inner>
    </Layout>
  )
}
