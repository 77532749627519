import React, { useEffect, useReducer, useState } from 'react'
import AddToCalendar from 'react-add-to-calendar'
import _ from 'lodash'
import moment from 'moment'
import queryString from 'query-string'

import {
  Row,
  Col,
  Radio,
  Input,
  notification,
  Select,
  Spin,
  Table,
  Typography
} from 'antd'

import {
  LayoutScroll,
  FooterFixed,
  Button,
  UploadFiles
} from '../../components'

import api from '../../services/api'

import forbiddenEmails from '../../constants/forbiddenEmails'
import history from '../../history'

import { findUsersByParams } from '../../services/api/user'

import { Layout } from './styled'

const defaultDocumentData = {
  name: '',
  description: '',
  document: {},
  data: [],
  value: [],
  fetching: false,
  message: null,
  coNumbers: ''
}

// eslint-disable-next-line spaced-comment
const isIE = /*@cc_on!@*/!!document.documentMode

const { Option } = Select
const { TextArea } = Input
const { Title, Text } = Typography

const initialState = {
  isSendingType: 'only',
  isTableVisible: false,
  users: [{
    id: 0,
    email: '',
    activeUNP: '',
    unp: [],
    status: 1,
    additionally: 1
  }]
}

function reducer (state = initialState, action) {
  switch (action.type) {
    case 'TOGGLE_SENDING_TYPE':
      return {
        ...state,
        isSendingType: action.payload
      }
    case 'SHOW_TABLE':
      return {
        ...state,
        isTableVisible: true
      }
    case 'ADD_USER':
      return {
        ...state,
        users: [...state.users, action.payload]
      }
    case 'EDIT_FIELD':
      return {
        ...state,
        users: [
          ...state.users.slice(0, state.users.findIndex(i => i.id === action.payload.id)),
          action.payload,
          ...state.users.slice(state.users.findIndex(i => i.id === action.payload.id) + 1)
        ]
      }
    case 'RESET':
      return {
        ...initialState
      }
  }
}

export default ({
  user: {
    data
  },
  createMessage,
  getUser,
  sendDocumentToUser,
  updateDocumentById,
  createContact,
  attachmentsTypes,
}) => {
  const [activeCompany, setActiveCompany] = useState({
    tariff_was_expired: data?.active_company_object?.tariff_was_expired,
    co_brand_id: data?.active_company_object?.co_brand_id
  })
  const [documentState, setDocumentState] = useState({ ...defaultDocumentData })
  const [state, dispatch] = useReducer(reducer, initialState)
  const [message, setMessage] = useState(false)
  const [documentTypes, setDocumentTypes] = useState(attachmentsTypes)

  const queryContacts = queryString.parse(history.location.search.replace('?', ''), {
    arrayFormat: 'bracket',
    parseNumbers: true
  })

  useEffect(() => {
    createMessage({ name: '[Без темы]', description: '' })
      .then(({ success, data }) => {
        if (success) {
          const messageData = data
          if (queryContacts.contacts) {
            queryContacts.contacts.forEach(contact => {
              findUsersByParams(contact)
                .then(({ data }) => {
                  const dataIds = documentState.data.map(i => i.key)

                  const dataArray = data.data
                    .map(user => ({
                      label: `${user.user_data.email} (УНП:${user.company_data.company_number}; ${user.company_data.name})`,
                      key: `${user.id}`
                    }))
                    .filter(i => !dataIds.includes(i.key))
                  setDocumentState({
                    ...documentState,
                    message: messageData,
                    data: [...documentState.data, ...dataArray],
                    value: [...documentState.value, ...dataArray]
                  })
                })
                .catch(error => {
                })
            })
          } else {
            setDocumentState({
              ...documentState,
              message: messageData
            })
          }
        }
      })
  }, [message])

  const tableColumns = [
    {
      key: '1',
      title: 'Адрес',
      width: 340,
      render: record => (
        <Text editable={{ onChange: str => editEmail(str, record) }}>
          {record.email}
        </Text>
      )
    },
    {
      key: '2',
      title: 'УНП',
      render: record => (
        <>
          {!!record.unp.length &&
            <Select
              style={{ minWidth: '24rem' }}
              onChange={value => editUNP(value, record)}
              placeholder='Выберете УНП'
            >
              {record.unp.map(i => (
                <Option value={i.id} key={i.company_number}>{i.company_number}</Option>
              ))}
            </Select>
          }
        </>
      )
    },
    // {
    //   key: '3',
    //   title: 'Тип требовония',
    //   render: record => <Select  onChange={value => editStatus(value, record)} style={{ minWidth: '20rem' }} defaultValue={record.status}>
    //     <Option value={1}>Простая доставка</Option>
    //     <Option value={2}>Согласование</Option>
    //     <Option value={3}>Подпись получателя</Option>
    //   </Select>
    // },
    {
      key: '4',
      title: 'Дополнительно',
      align: 'right',
      render: record => (
        <Select
          onChange={value => editAdditionallyStatus(value, record)}
          style={{ minWidth: '28rem' }}
          value={record.additionally}
        >
          <Option value={1}>Прервать цепочку в случае отказа</Option>
          <Option value={2}>Не прерывать цепочку в случае отказа</Option>
        </Select>
      )
    }
  ]

  const addUser = () => {
    const usMap = state.users.map(u => u.email)
    if (!usMap.includes("")) {
      dispatch({
        type: 'ADD_USER',
        payload: {
          id: state.users.length,
          email: '',
          activeUNP: '',
          unp: [],
          status: state.users[state.users.length - 1].status,
          additionally: state.users[state.users.length - 1].additionally
        }
      })
    } else {
      notification['error']({
        message: 'Пожалуйста, введите адрес получателя'
      })
    }
  }

  const editStatus = (value, record) => {
    dispatch({ type: 'EDIT_FIELD', payload: { ...record, status: value } })
  }

  const editUNP = (value, record) => {
    dispatch({ type: 'EDIT_FIELD', payload: { ...record, activeUNP: value } })
  }

  const editAdditionallyStatus = (value, record) => {
    dispatch({ type: 'EDIT_FIELD', payload: { ...record, additionally: value } })
  }

  const editEmail = (str, record) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (re.test(String(str).toLowerCase())) {
      dispatch({ type: 'EDIT_FIELD', payload: { ...record, email: str, activeUNP: str } })
      findUsersByParams(str)
        .then(({ data }) => {
          dispatch({ type: 'EDIT_FIELD', payload: { ...record, unp: data.data, email: str, activeUNP: str } })
        })
    } else {
      dispatch({ type: 'EDIT_FIELD', payload: { ...record, email: '', activeUNP: '', unp: '' } })
      notification['error']({
        message: 'Неверно указан адрес электронной почты'
      })
    }
  }

  const chainSend = () => {
    const { message } = documentState
    const { users } = state
    let incorrectArr = false
    let sendData = []
    for (const idx in users) {
      if (users[idx].activeUNP === '') {
        incorrectArr = true
      } else {
        const result = {
          document_ids: [message.id],
          user_company_id: JSON.stringify([users[idx].activeUNP]),
          continues_action: users[idx].additionally !== 1,
          is_disabled: idx > 0,
          chain_number: idx
        }
        sendData.push(result)
      }
    }
    if (sendData.length > 0 && incorrectArr === false) {
      for (let index in sendData) {
        let element = sendData[index]
        sendDocumentToUser(element)
          .then(({ success, error }) => {
            if (success) {
              if (index == sendData.length - 1) {
                notification['success']({
                  message: 'Ваше сообщение успешно отправлено'
                })

                history.push({ pathname: '/documents', search: '?status=3', state: { id: '/documents/3' } })
                setDocumentState({ ...defaultDocumentData })
                setMessage(!message)
                dispatch({ type: 'RESET' })
                getUser()
              }
            } else {
              throw new Error(error)
            }
          })
          .catch(error => {
            if (index == sendData.length - 1) {
              notification.error({
                message: error.message
              })
            }
          })
      }
    } else {
      notification['error']({
        message: 'Укажите получателей корректно'
      })
    }
  }

  const updateField = (field, v) => {
    setDocumentState({
      ...documentState,
      [field]: v
    })
  }

  const updateFieldProcess = (field, v) => {
    updateField(field, v)
    updateDocumentById(documentState.message.id, { [field]: v })
  }

  const save2DraftDMessage = is2Draft => {
    let coEmails = []
    let UCIds = []
    if (documentState.coNumbers.length > 0) {
      let coNumbersArray = documentState.coNumbers.split(',')
      coNumbersArray.forEach(element => coEmails.push(element + '@qdx.by'))
    }
    UCIds = documentState.value.length ? documentState.value.map(i => i.key).concat(coEmails) : [].concat(coEmails)

    if (UCIds.filter(UCId => forbiddenEmails.includes(UCId)).length) {
      notification.error({
        message: 'Отправка/перенаправление по реквизиту УНП для данного адресата запрещено. Укажите точный адрес (E-mail) получателя.'
      })
      return false
    }

    updateDocumentById(documentState.message.id, {
      name: documentState.name ? documentState.name : 'Без темы',
      description: documentState.description,
      user_company_ids: JSON.stringify(UCIds)
    })
      .then(({ data }) => {
        if (is2Draft) {
          history.push({ pathname: '/documents', search: '?status=1', state: { id: '/documents/1' } })
          window.location.reload()
        } else {
          if ((!documentState.value.length > 0 && !documentState.coNumbers.length > 0) || (!documentState.name.length > 0)) {
            if (!documentState.value.length > 0 && !documentState.coNumbers.length > 0) {
              notification['error']({
                message: 'Укажите получателя!'
              })
            }
            if (!documentState.name.length > 0) {
              notification['error']({
                message: 'Укажите тему сообщения!'
              })
            }
            setDocumentState({
              ...documentState,
              fetching: false
            })
            return null
          }
          sendDocumentToUser({
            document_ids: [documentState.message.id],
            user_company_id: documentState.value.length ? JSON.stringify(documentState.value.map(i => i.key)) : JSON.stringify([])
          }).then(({ success }) => {
            if (success) {
              notification['success']({
                message: 'Ваше сообщение успешно отправлено'
              })
              setDocumentState({ ...defaultDocumentData })
              setMessage(!message)
              getUser()
              history.push({ pathname: '/documents', search: '?status=3', state: { id: '/documents/3' } })
            }
          })
        }
        return data
      })
      .catch(error => console.error(error))
  }

  const fetchUser = _.debounce(email => {
    if (email.length > 2) {
      findUsersByParams(email)
        .then(({ data }) => {
          const dataIds = documentState.data.map(i => i.key)

          const dataArray = data.data
            .map(user => ({
              label: `${user.user_data.email} (УНП:${user.company_data.company_number}; ${user.company_data.name})`,
              key: `${user.id}`
            }))
            .filter(i => !dataIds.includes(i.key))

          setDocumentState({
            ...documentState,
            data: [...documentState.data, ...dataArray]
          })
        })
        .catch(error => {
        })
    }
  }, 200)

  const validateEmail = label => {
    const email = label.split(' ')[0]
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  const handleSelect = v => {
    if (documentState.fetching) {
      return
    }

    const validEmails = v.filter(i => {
      if (validateEmail(i.label)) {
        return i.key
      }
    })

    if (v.length !== validEmails.length) {
      notification['error']({
        message: 'Неверно указан адрес электронной почты'
      })
    }

    createContact({
      user_company_contact_id: validEmails[validEmails.length - 1]?.key
    })

    updateDocumentById(documentState.message.id, {
      user_company_ids: JSON.stringify(validEmails.map(i => i.key))
    })

    setDocumentState({
      ...documentState,
      value: validEmails
    })
  }

  return (
    <LayoutScroll withFooter>
      <Layout>
        <Layout.Inner>
          <Title level={3}>Новое сообщение</Title>

          <Layout.Types>
            {activeCompany.co_brand_id === null && (
            <Radio.Group
              disabled={activeCompany.tariff_was_expired}
              defaultValue={state.isSendingType}
              buttonStyle='solid'
              onChange={e => dispatch({
                type: 'TOGGLE_SENDING_TYPE',
                payload: e.target.value
              })}
            >
              <Radio.Button value='only'>
                Обычная отправка
              </Radio.Button>

              <Radio.Button value='multiple'>
                Отправка цепочкой
              </Radio.Button>
            </Radio.Group>)}

            <Button
              type='primary'
              icon='contacts'
              onClick={() => history.push('/contacts')}
              ghost
              disabled={activeCompany.tariff_was_expired}
            >
              Записная книга
            </Button>
          </Layout.Types>

          {state.isSendingType === 'only' ? (
            <Spin spinning={!!documentState.fetching}>
              <Row gutter={[12, 16]}>
                <Col span={24}>
                  <Layout.Control>
                    <label>Получатели по почте</label>

                    <Select
                      mode='tags'
                      tokenSeparators={[',']}
                      value={documentState.value}
                      onSearch={fetchUser}
                      placeholder='Введите электронную почту'
                      onChange={handleSelect}
                      style={{ width: '100%' }}
                      filterOption={false}
                      labelInValue
                      disabled={activeCompany.tariff_was_expired}
                    >
                      {documentState.data.map(item => (
                        <Option key={item.key}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  </Layout.Control>
                </Col>

                <Col span={12}>
                  <Layout.Control>
                    <label>Получатели по УНП</label>

                    <Input
                      kind='text'
                      type='text'
                      value={documentState.coNumbers}
                      placeholder='Введите УНП получателя'
                      onChange={e => updateField('coNumbers', e.target.value.replace(/\D/, ''))}
                      maxLength={9}
                      disabled={activeCompany.tariff_was_expired}
                    />
                  </Layout.Control>

                  <Layout.Control>
                    <label>Тема</label>

                    <Input
                      kind='text'
                      type='text'
                      value={documentState.name}
                      placeholder='Ёмкое название сообщения'
                      onChange={e => updateFieldProcess('name', e.target.value)}
                      disabled={activeCompany.tariff_was_expired}
                    />
                  </Layout.Control>
                </Col>

                <Col span={12}>
                  <Layout.Control>
                    <label>Комментарий</label>

                    <TextArea
                      className='control-comment'
                      autoSize={{ minRows: 5, maxRows: 12 }}
                      placeholder='Опишите все, что важно знать получателю'
                      value={documentState.description}
                      onChange={e => updateFieldProcess('description', e.target.value)}
                      disabled={activeCompany.tariff_was_expired}
                    />
                  </Layout.Control>
                </Col>

                {documentState.message && (
                  <Col span={24}>
                    <UploadFiles
                      document_id={documentState.message.id}
                      documentTypes={documentTypes}
                    />
                  </Col>)}
              </Row>
            </Spin>
          ) : (
            <Spin spinning={!!documentState.fetching}>
              <Layout.Control>
                <label>Тема</label>

                <Input
                  kind='text'
                  type='text'
                  value={documentState.name}
                  placeholder='Ёмкое название сообщения'
                  onChange={e => updateFieldProcess('name', e.target.value)}
                  disabled={activeCompany.tariff_was_expired}
                />
              </Layout.Control>

              <Layout.Control>
                <label>Комментарий</label>

                <TextArea
                  autoSize={{ minRows: 5, maxRows: 12 }}
                  value={documentState.description}
                  placeholder='Опишите все, что важно знать получателю'
                  onChange={e => updateFieldProcess('description', e.target.value)}
                  disabled={activeCompany.tariff_was_expired}

                />
              </Layout.Control>

              <Layout.TableShow>
                <Button
                  type='primary'
                  onClick={() => dispatch({ type: 'SHOW_TABLE' })}
                  icon='plus'
                  ghost
                  disabled={activeCompany.tariff_was_expired}
                >
                  Добавить файл и указать маршрут
                </Button>
              </Layout.TableShow>

              {state.isTableVisible && (
                <Layout.Table>
                  <Layout.Table.Uploads>
                    {documentState.message && (
                      <UploadFiles
                        isStatus={false}
                        document_id={documentState.message.id}
                        documentTypes={documentTypes}
                      />
                    )}
                  </Layout.Table.Uploads>

                  <Table
                    className='ui-table-inside'
                    rowKey={record => record.id}
                    columns={tableColumns}
                    dataSource={state.users}
                    pagination={false}
                  />

                  <Layout.Table.Actions>
                    <Button
                      type='primary'
                      onClick={addUser}
                      icon='plus'
                      ghost
                    >
                      Еще получатель
                    </Button>

                    <Button
                      type='primary'
                      icon='export'
                      onClick={chainSend}
                    >
                      Отправить
                    </Button>
                  </Layout.Table.Actions>
                </Layout.Table>)}
            </Spin>
          )}
        </Layout.Inner>

        {state.isSendingType === 'only' && (
          <FooterFixed>
            <Layout.Actions>
              <Button
                type='primary'
                onClick={() => save2DraftDMessage(true)}
                icon='file-text'
                ghost
                disabled={activeCompany.tariff_was_expired}
              >
                Сохранить в черновиках
              </Button>

              <Layout.Actions.Calendar hidden={activeCompany.tariff_was_expired}>
                <AddToCalendar
                  buttonClassClosed='ant-btn ant-btn-primary ant-btn-background-ghost'
                  buttonLabel='Добавить в календарь'
                  listItems={[
                    { apple: 'Apple Calendar' },
                    { google: 'Google' },
                    { outlook: 'Outlook' }
                  ]}
                  event={{
                    title: 'Контроль сообщения',
                    description: '',
                    location: 'Minsk',
                    startTime: moment().add(1, 'days').startOf('day').hour('10').minute('00'),
                    endTime: moment().add(1, 'days').startOf('day').hour('11').minute('00')
                  }}
                />
              </Layout.Actions.Calendar>
            </Layout.Actions>

            <Button
              type='primary'
              icon='export'
              onClick={() => save2DraftDMessage(false)}
              disabled={activeCompany.tariff_was_expired}
            >
              Отправить сообщение
            </Button>
          </FooterFixed>)}
      </Layout>
    </LayoutScroll>
  )
}
