import { connect } from 'react-redux'
import actions from '../../store/actions'

import ModalAttributeCertificate from './ModalAttributeCertificate'

const mapDispatchToProps = dispatch => ({
  verifyFile: data => dispatch(actions.files.verifyFile(data)),
  getDocumentById: id => dispatch(actions.document.getDocumentById(id))
})

export default connect(
  null,
  mapDispatchToProps
)(ModalAttributeCertificate)
