import styled, { css } from 'styled-components'

import { styleguide } from '../../constants'

const { colors } = styleguide

const Layout = styled.div`
`

Layout.Centering = styled.div`
  height: calc(100vh - 9.4rem);
  display: flex;
  justify-content: center;
  align-items: center;
`

Layout.Inner = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
  text-align: center;
`

Layout.Picture = styled.div`
  width: 12rem;
  height: 12rem;
  background: url(${({ src }) => src}) no-repeat center / contain;
  margin-bottom: 4rem;
`

Layout.Secondary = styled.div`
  color: ${colors.gray.dark};
`

Layout.Upload = styled.div`
`

Layout.Upload.Inner = styled.div`
  padding: 2rem;
`

Layout.Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2.4rem 0;

  && {
    .ant-btn {
      margin: 0 1rem;
    }
  }
`

const Upload = styled.div`
  && {
    position: relative;
    padding: 0;
  }
`

Upload.Field = styled.input`
  position: absolute;
`

Upload.Label = styled.label`
  height: 4.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 4.8rem;

  .anticon {
    font-size: 1.8rem;
    margin-right: 1.4rem;
  }
`

export {
  Layout,
  Upload
}
