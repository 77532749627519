import { connect } from 'react-redux'
import actions from '../../../../store/actions'
import ModalContactInvite from './ModalContactInvite'

const mapDispatchToProps = dispatch => ({
  createMessage: data => dispatch(actions.document.createMessage(data)),
  updateDocumentById: (id, data) => dispatch(actions.document.updateDocumentById(id, data)),
  sendDocumentToUser: data => dispatch(actions.documents.sendDocumentToUser(data))
})

export default connect(
  null,
  mapDispatchToProps
)(ModalContactInvite)
