const colors = {
  white: '#FFFFFF',
  black: '#333333',
  primary: '#008EFE',
  green: '#27AE60',
  orange: '#E69900',
  red: '#EB5757',
  gray: {
    light: '#F2F5F7',
    middle: '#DCDEE3',
    dark: '#949494'
  }
}

export default {
  colors
}
