import React, { useEffect, useState } from 'react'

import {
  Icon,
  Button,
  Typography
} from 'antd'

import { LayoutScroll } from '../../components'

import { ModalCancel } from './components'

import { contracts } from './images'

import { getActiveCompany } from '../../utils'

import {
  Layout,
  Upload
} from './styled'

const { Title } = Typography

export default ({
  history,
  user,
  contacts: {
    isFetchingImport
  },
  importContacts
}) => {
  const [modalCancelVisible, setModalCancelVisible] = useState(false)

  useEffect(() => {
    if (user.id) {
      const activeCompany = user.hasOwnProperty('active_company_object') && getActiveCompany(user)

      if (!activeCompany.tarification.tarification_data.has_contacts) {
        history.push('/documents?status=2')
      }
    }
  }, [user])

  const handleImportContacts = e => {
    const file = e.target.files[0]
    const data = new FormData()

    data.append('file', file)

    importContacts(data, history)
  }

  return (
    <LayoutScroll>
      <Layout>
        <Layout.Centering>
          <Layout.Inner>
            <Layout.Picture src={contracts} />

            <Title level={3}>Добавление контактов</Title>
            <Layout.Secondary>Чтобы загрузить контакты нажмите на кнопку ниже "Загрузить".<br />После успешной загрузки, вы будет перенаправлены на страницу с импортированными контактами.</Layout.Secondary>

            <Layout.Actions>
              <Upload className='ant-btn ant-btn-primary'>
                <Upload.Field
                  accept=''
                  type='file'
                  id='upload'
                  onChange={handleImportContacts}
                  hidden
                />

                <Upload.Label htmlFor='upload'>
                  <Icon type={!isFetchingImport ? 'upload' : 'loading'} /> {!isFetchingImport ? 'Загрузить контакты из файла' : 'Идет загрузка контактов...'}
                </Upload.Label>
              </Upload>

              <Button onClick={() => setModalCancelVisible(true)}>Отмена</Button>
            </Layout.Actions>

            <Button
              type='link'
              href='https://dev.api.quidox.by/contacts_import.xls'
              icon='file-excel'
            >
              Скачать файл-образец для импорта
            </Button>
          </Layout.Inner>
        </Layout.Centering>

        <ModalCancel
          visible={modalCancelVisible}
          onCancel={() => setModalCancelVisible(false)}
        />
      </Layout>
    </LayoutScroll>
  )
}
