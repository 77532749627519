import * as t from '../types'
import history from '../../history'

import { api } from '../../services'
import { message } from 'antd'

const createMessage = body => dispatch => {
  dispatch({
    type: t.CREATE_MESSAGE_FETCHING,
    payload: true
  })
  return api.document.createDocument(body)
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: t.CREATE_MESSAGE_SUCCESS,
          payload: data
        })
      }
      dispatch({
        type: t.CREATE_MESSAGE_FETCHING,
        payload: false
      })
      return data
    })
}

const getDocumentById = id => dispatch => {
  dispatch({
    type: t.GET_DOCUMENT_BY_ID_FETCHING,
    payload: true
  })
  return api.document.getDocumentById(id)
    .then(({ data }) => {
      if (data.data) {
        dispatch({
          type: t.GET_DOCUMENT_BY_ID_SUCCESS,
          payload: data
        })
        setTimeout(() => {
          dispatch({
            type: t.GET_DOCUMENT_BY_ID_FETCHING,
            payload: false
          })
        }, 1000)
      } else {
        history.push('/')
        message.error(data.error)

        dispatch({
          type: t.GET_DOCUMENT_BY_ID_FETCHING,
          payload: false
        })
      }
    }).catch(() => {
      dispatch({
        type: t.GET_DOCUMENT_BY_ID_FETCHING,
        payload: false
      })
    })
}

const updateDocumentById = (id, data) => dispatch => {
  dispatch({
    type: t.UPDATE_DOCUMENT_BY_ID_FETCHING,
    payload: true
  })
  return api.document.updateDocumentById(id, data)
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: t.UPDATE_DOCUMENT_BY_ID_SUCCESS,
          payload: data
        })
      }
      dispatch({
        type: t.UPDATE_DOCUMENT_BY_ID_FETCHING,
        payload: false
      })
      return data
    })
}

const verifyDocument = data => dispatch => {
  dispatch({
    type: t.VERIFY_DOCUMENT_FETCHING,
    payload: true
  })
  return api.document.verifyDocument(data)
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: t.VERIFY_DOCUMENT_SUCCESS,
          payload: data
        })
      }
      dispatch({
        type: t.VERIFY_DOCUMENT_FETCHING,
        payload: false
      })
      return data
    })
}

const changeStatus = status => dispatch => {
  dispatch({
    type: t.CHANGE_FILEE_STATUS_FETCHING,
    payload: true
  })
  return api.document.changeStatus(status)
    .then(data => {
      if (data) {
        dispatch({
          type: t.CHANGE_FILEE_STATUS_SUCCESS,
          payload: status
        })
      }
      dispatch({
        type: t.CHANGE_FILEE_STATUS_FETCHING,
        payload: false
      })
      return data
    })
}

const removeDocumentById = id => dispatch => {
  dispatch({
    type: t.REMOVE_DOCUMENT_BY_ID_FETCHING,
    payload: true
  })
  return api.document.removeDocumentById(id)
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: t.REMOVE_DOCUMENT_BY_ID_SUCCESS,
          payload: id
        })
      }
      dispatch({
        type: t.REMOVE_DOCUMENT_BY_ID_FETCHING,
        payload: false
      })
      return data
    })
}

const getCurrenciesList = id => dispatch => {
  dispatch({
    type: t.GET_CURRENCIES_LIST_FETCHING,
    payload: true
  })
  return api.document.getCurrenciesList()
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: t.GET_CURRENCIES_LIST_SUCCESS,
          payload: data.data
        })
      }
      dispatch({
        type: t.GET_CURRENCIES_LIST_FETCHING,
        payload: false
      })
    })
}

const resetDocumentById = () => dispatch => {
  dispatch({
    type: t.RESET_DOCUMENT_BY_ID
  })
}

export {
  createMessage,
  getDocumentById,
  removeDocumentById,
  updateDocumentById,
  changeStatus,
  verifyDocument,
  getCurrenciesList,
  resetDocumentById
}
