// Users
export const GET_USER_FETCHING = 'GET_USER_FETCHING'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const USER_LOGOUT = 'USER_LOGOUT'
export const UPDATE_USER_FETCHING = 'UPDATE_USER_FETCHING'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'

// Company
export const GET_COMPANIES_FETCHING = 'GET_COMPANIES_FETCHING'
export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS'
export const GET_COMPANY_BY_ID_FETCHING = 'GET_COMPANY_BY_ID_FETCHING'
export const GET_COMPANY_BY_ID_SUCCESS = 'GET_COMPANY_BY_ID_SUCCESS'
export const CHANGE_ACTIVE_COMPANY_BY_ID_FETCHING = 'CHANGE_ACTIVE_COMPANY_BY_ID_FETCHING'
export const CHANGE_ACTIVE_COMPANY_BY_ID_SUCCESS = 'CHANGE_ACTIVE_COMPANY_BY_ID_SUCCESS'
export const CREATE_COMPANY_FETCHING = 'CREATE_COMPANY_FETCHING'
export const CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS'

// Contacts
export const GET_CONTACTS_FETCHING = 'GET_CONTACTS_FETCHING'
export const GET_CONTACTS_SUCCESS = 'GET_CONTACTS_SUCCESS'

export const GET_CONTACT_BY_ID_FETCHING = 'GET_CONTACT_BY_ID_FETCHING'
export const GET_CONTACT_BY_ID_SUCCESS = 'GET_CONTACT_BY_ID_SUCCESS'

export const CREATE_CONTACT_FETCHING = 'CREATE_CONTACT_FETCHING'

export const UPDATE_CONTACT_FETCHING = 'UPDATE_CONTACT_FETCHING'
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS'

export const DELETE_CONTACT_FETCHING = 'DELETE_CONTACT_FETCHING'
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS'

export const IMPORT_CONTACT_FETCHING = 'IMPORT_CONTACT_FETCHING'

// Documents
export const GET_DOCUMENTS_BY_ACTIVE_COMPANY_ID_REQUEST_FETCHING = 'GET_DOCUMENTS_BY_ACTIVE_COMPANY_ID_REQUEST_FETCHING'
export const GET_DOCUMENTS_BY_ACTIVE_COMPANY_ID_REQUEST_SUCCESS = 'GET_DOCUMENTS_BY_ACTIVE_COMPANY_ID_REQUEST_SUCCESS'
export const GET_DOCUMENTS_BY_ACTIVE_COMPANY_ID_SUCCESS = 'GET_DOCUMENTS_BY_ACTIVE_COMPANY_ID_SUCCESS'

export const REMOVE_DOCUMENT_BY_ID_FETCHING = 'REMOVE_DOCUMENT_BY_ID_FETCHING'
export const REMOVE_DOCUMENT_BY_ID_SUCCESS = 'REMOVE_DOCUMENT_BY_ID_SUCCESS'
export const REMOVE_DOCUMENTS_BY_IDS_FETCHING = 'REMOVE_DOCUMENTS_BY_IDS_FETCHING'
export const REMOVE_DOCUMENTS_BY_IDS_SUCCESS = 'REMOVE_DOCUMENTS_BY_IDS_SUCCESS'

export const SEND_DOCUMENT_TO_USER_FETCHING = 'SEND_DOCUMENT_TO_USER_FETCHING'
export const SEND_DOCUMENT_TO_USER_SUCCESS = 'SEND_DOCUMENT_TO_USER_SUCCESS'

export const GET_DOCUMENTS_UNREAD_COUNT_FETCHING = 'GET_DOCUMENTS_UNREAD_COUNT_FETCHING'
export const GET_DOCUMENTS_UNREAD_COUNT_SUCCESS = 'GET_DOCUMENTS_UNREAD_COUNT_SUCCESS'

export const RESET_DOCUMENTS_LIST = 'RESET_DOCUMENTS_LIST'

export const GET_DOCUMENTS_STATUSES = 'GET_DOCUMENTS_STATUSES'

// single document
export const CREATE_DOCUMENT_FETCHING = 'CREATE_DOCUMENT_FETCHING'
export const CREATE_DOCUMENT_SUCCESS = 'CREATE_DOCUMENT_SUCCESS'
export const GET_DOCUMENT_BY_ID_FETCHING = 'GET_DOCUMENT_BY_ID_FETCHING'
export const GET_DOCUMENT_BY_ID_SUCCESS = 'GET_DOCUMENT_BY_ID_SUCCESS'
export const VERIFY_DOCUMENT_FETCHING = 'VERIFY_DOCUMENT_FETCHING'
export const VERIFY_DOCUMENT_SUCCESS = 'VERIFY_DOCUMENT_SUCCESS'
export const UPDATE_DOCUMENT_BY_ID_FETCHING = 'UPDATE_DOCUMENT_BY_ID_FETCHING'
export const UPDATE_DOCUMENT_BY_ID_SUCCESS = 'UPDATE_DOCUMENT_BY_ID_SUCCESS'
export const GET_CURRENCIES_LIST_FETCHING = 'GET_CURRENCIES_LIST_FETCHING'
export const GET_CURRENCIES_LIST_SUCCESS = 'GET_CURRENCIES_LIST_SUCCESS'
export const RESET_DOCUMENT_BY_ID = 'RESET_DOCUMENT_BY_ID'

// custom document
export const GET_CUSTOM_DOCUMENTS_FETCHING = 'GET_CUSTOM_DOCUMENTS_FETCHING'
export const GET_CUSTOM_DOCUMENTS_SUCCESS = 'GET_CUSTOM_DOCUMENTS_SUCCESS'

export const GET_CUSTOM_DOCUMENT_BY_ID_FETCHING = 'GET_CUSTOM_DOCUMENT_BY_ID_FETCHING'
export const GET_CUSTOM_DOCUMENT_BY_ID_SUCCESS = 'GET_CUSTOM_DOCUMENT_BY_ID_SUCCESS'

export const GET_CUSTOM_DOCUMENT_TYPES_FETCHING = 'GET_CUSTOM_DOCUMENT_TYPES_FETCHING'
export const GET_CUSTOM_DOCUMENT_TYPES_SUCCESS = 'GET_CUSTOM_DOCUMENT_TYPES_SUCCESS'

export const GET_CUSTOM_DOCUMENT_FIELDS_FETCHING = 'GET_CUSTOM_DOCUMENT_FIELDS_FETCHING'
export const GET_CUSTOM_DOCUMENT_FIELDS_SUCCESS = 'GET_CUSTOM_DOCUMENT_FIELDS_SUCCESS'

export const CREATING_CUSTOM_DOCUMENT_FETCHING = 'CREATING_CUSTOM_DOCUMENT_FETCHING'
export const CREATING_CUSTOM_DOCUMENT_SUCCESS = 'CREATING_CUSTOM_DOCUMENT_SUCCESS'

// files
export const UPLOAD_FILE_FETCHING = 'UPLOAD_FILE_FETCHING'
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS'
export const UPLOAD_FILE_STATUS = 'UPLOAD_FILE_STATUS'
export const REMOVE_FILE_FETCHING = 'REMOVE_FILE_FETCHING'
export const REMOVE_FILE_SUCCESS = 'REMOVE_FILE_SUCCESS'
export const VERIFY_FILE_STATUS = 'VERIFY_FILE_STATUS'
export const VERIFY_FILE_FETCHING = 'VERIFY_FILE_FETCHING'
export const VERIFY_FILE_SUCCESS = 'VERIFY_FILE_SUCCESS'
export const CHANGE_FILE_STATUS_FETCHING = 'CHANGE_FILE_STATUS_FETCHING'
export const CHANGE_FILE_STATUS_SUCCESS = 'CHANGE_FILE_STATUS_SUCCESS'
export const VERIFY_FILE_TZI = 'VERIFY_FILE_TZI'
export const CHANGE_FILE_DATA_FETCHING = 'CHANGE_FILE_DATA_FETCHING'
export const CHANGE_FILE_DATA_SUCCESS = 'CHANGE_FILE_DATA_SUCCESS'

// message
export const CREATE_MESSAGE_FETCHING = 'CREATE_MESSAGE_FETCHING'
export const CREATE_MESSAGE_SUCCESS = 'CREATE_MESSAGE_SUCCESS'

// tariff

export const GET_TARIFF_FETCHING = 'GET_TARIFF_FETCHING'
export const GET_TARIFF_SUCCESS = 'GET_TARIFF_SUCCESS'

// костыль
export const CHANGE_FILEE_STATUS_FETCHING = 'CHANGE_FILEE_STATUS_FETCHING'
export const CHANGE_FILEE_STATUS_SUCCESS = 'CHANGE_FILEE_STATUS_SUCCESS'

//config
export const GET_CONFIG_REQUEST = 'GET_CONFIG_REQUEST'
export const GET_CONFIG_SUCCESS = 'GET_CONFIG_SUCCESS'

export const PROCESS_GLOBAL_SEARCH = 'PROCESS_GLOBAL_SEARCH'

//attachments
export const GET_ATTACHMENTS_TYPES = 'GET_ATTACHMENTS_TYPES'
export const GET_ATTACHMENTS_TYPES_FETCHING = 'GET_ATTACHMENTS_TYPES_FETCHING'
