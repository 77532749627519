import React, { Fragment, useEffect, useState } from 'react'
import fileDownload from 'js-file-download'
import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'
import queryString from 'query-string'

import { api } from '../../services'
import { findUsersByParams } from '../../services/api/user'
import { copy2Clipboard } from '../../utils'
import forbiddenEmails from '../../constants/forbiddenEmails'
import { getAttachmentStatus } from '../../helpers'

import history from '../../history'

import {
  Table,
  Icon,
  message,
  Modal,
  notification,
  Select,
  Spin,
  Tag,
  Tooltip,
  Typography,
  Alert
} from 'antd'

import {
  DownloadButtons,
  FileActions,
  FileHistory
} from './internal'

import {
  LayoutScroll,
  FooterFixed,
  GoBack,
  AvestErrorHandling,
  Button,
  EscDataSlider,
  ModalViewer,
  UploadFiles
} from '../../components'

import {
  Layout,
  Header,
  Details,
  Attached
} from './styled'

import { styleguide } from '../../constants'

const { colors } = styleguide

const { Text } = Typography

const { Column } = Table

const { Option } = Select

const defaultDocumentState = {
  isVisible: false,
  fileLink: '',
  fileType: '',
  showModal: false,
  comment: '',
  commentFile: '',
  commentLink: '',
  data: [],
  value: [],
  fetching: false,
  modalType: 'ecp',
  isHistoryModalOpen: false,
  isZipModalOpen: false,
  fileHistory: [],
  base64files: '',
  certs: '',
  fileHashes: '',
  fileData: '',
  fileCerts: [],
  activeFileCert: 0,
  ecpInfo: null,
  isSelectVisible: false,
  isErrorWitchEcp: false,
  declineMessage: '',
  singleFile: null,
  isRoamingModalOpen: false,
  buttonIsFetching: false
}

const defaultSelectState = {
  value: ''
}

const SingleDocumentPage = ({
  documents: {
    isFetching,
    singleDocument
  },
  match,
  getDocumentById,
  sendDocumentToUser,
  updateDocumentById,
  resetDocumentById,
  getUser,
  user,
  documentsTypes
}) => {
  const [hasExternalArchive, setHasExternalArchive] = useState(user?.data?.active_company_object?.has_external_archive)
  const [fetch, setFetch] = useState(false)
  const [documentState, setDocumentState] = useState({ ...defaultDocumentState })
  const [selectState, setSelectState] = useState({ ...defaultSelectState })
  const [documentTypes, setDocumentTypes] = useState(documentsTypes)

  const {
    document,
    sender,
    recipient,
    statuses
  } = singleDocument

  useEffect(() => {
    let search = window.location.search
    let params = new URLSearchParams(search)
    let hash = params.get('hash')
    let attachmentId = params.get('attachment_id')

    if (hash && attachmentId) {
      try {
        setFetch(true)
        let token = window.localStorage.getItem('authToken') || window.sessionStorage.getItem('authToken')
        axios.get(`${process.env.REACT_APP_BASE_URL}/attachment/sim-sign/check/${attachmentId}?hash=${hash}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        })
          .then(response => {
            setFetch(false)

            const { data: { success } } = response

            if (success) {
              message.success('Совершено успешное подписание!')
              getDocumentById(match.params.id)
            } else {
              setFetch(false)
            }
          })
          .catch(() => {
            message.error('Извините, что-то пошло не так. Перезагрузите страницу и попробуйте еще раз.')

            // setTimeout(() => {
            // window.location.reload();
            // }, 2000)
          })
      } catch (error) {
        setFetch(false)
      }
    }
  }, [])

  useEffect(() => {
    if (match) {
      getDocumentById(match.params.id)
    }

    return () => {
      resetDocumentById()
    }
  }, [])

  useEffect(() => {
    if (documentState.isErrorWitchEcp) {
      setTimeout(() => {
        try {
          window.pluginLoaded()
        } catch (error) {
        }
      }, 3000)
    }
  }, [documentState.isErrorWitchEcp])

  const showModal = item => {
    let token = window.localStorage.getItem('authToken') || window.sessionStorage.getItem('authToken')
    axios.get(item['preview_path'], {
      'responseType': 'arraybuffer',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Access-Control-Expose-Headers': 'Content-Disposition,X-Suggested-Filename'
      }
    })
      .then(response => {
        const blob = new window.Blob([response.data], { type: 'application/pdf' })
        const blobURL = window.URL.createObjectURL(blob)
        const fileType = response.headers['content-type'].split('/').pop()

        setDocumentState({
          ...documentState,
          isVisible: true,
          fileLink: blobURL,
          fileType: fileType
        })
      })
      .catch(() => {
        message.success('Система создает копию для предпросмотра. Пожалуйста подождите!')
      })
  }

  const hideModal = () => {
    setDocumentState({
      ...documentState,
      isVisible: false
    })
  }

  const handleCloseModal = () => {
    setDocumentState({
      ...defaultDocumentState
    })
  }

  const showUserData = (type, arr = []) => {
    const dataArray = arr.filter(i => i.verification_hash)

    if (type === 'ecp') {
      setDocumentState({
        ...documentState,
        showModal: true,
        modalType: type,
        fileCerts: dataArray
      })
    }
  }

  const visibleZipModal = record => {
    if (record === false) {
      setDocumentState({
        ...documentState,
        isZipModalOpen: false
      })
    } else {
      let token = window.localStorage.getItem('authToken') || window.sessionStorage.getItem('authToken')
      axios.get(`${process.env.REACT_APP_BASE_URL}/external_archive/upload/${record['document_id']}`, {
        'responseType': 'arraybuffer',
        headers: {
          'Authorization': 'Bearer ' + token,
          'Access-Control-Expose-Headers': 'Content-Disposition,X-Suggested-Filename'
        }
      })
        .then(() => {
          setDocumentState({
            ...documentState,
            isZipModalOpen: true
          })
        })
        .catch(() => {
          message.success('Пожалуйста подождите!')
        })
    }
  }

  const openHistoryModal = history => {
    setDocumentState({
      ...documentState,
      isHistoryModalOpen: true,
      fileHistory: history
    })
  }

  const closeHistoryModal = () => {
    setDocumentState({
      ...documentState,
      isHistoryModalOpen: false,
      fileHistory: []
    })
  }

  const openRoamingModal = () => {
    setDocumentState({
      ...documentState,
      isRoamingModalOpen: true,
    })
  }

  const closeRoamingModal = () => {
    setDocumentState({
      ...documentState,
      isRoamingModalOpen: false,
    })
  }

  const openModal = type => {
    documentState.data = documentState.value = []

    if (singleDocument.actual_status === 1 && recipient.id !== sender.id) {
      let value = {
        key: recipient['id'],
        label: `${recipient['user_email']}[${recipient['company_name']}]`
      }

      documentState.value.push(value)
    }

    setDocumentState({
      ...documentState,
      modalType: type,
      showModal: true
    })
  }

  const setUsersByParams = (v) => {
    findUsersByParams(v)
      .then(({ data }) => {
        const dataIds = documentState.data.map(i => i.key)
        const dataArray = data.data
          .map(user => ({
            label: `${user.user_data.email} (УНП:${user.company_data.company_number}; Компания:${user.company_data.name})`,
            key: `${user.id}`
          }))
          .filter(i => !dataIds.includes(i.key))

        setDocumentState({
          ...documentState,
          data: [...documentState.data, ...dataArray],
          fetching: false
        })
      })
      .catch(error => {
        message.error(error.message)
      })
  }

  const fetchUser = _.debounce(v => {
    if (v.length > 2) {
      setDocumentState({
        ...documentState,
        fetching: true
      })

      setUsersByParams(v)
    }
  }, 200)

  const handleSelect = v => {
    setDocumentState({
      ...documentState,
      data: v,
      value: v
    })
  }

  const sendToUser = () => {
    if (documentState.value.filter(i => forbiddenEmails.includes(i.key)).length) {
      notification.error({
        message: 'Отправка/перенаправление по реквизиту УНП для данного адресата запрещено. Укажите точный адрес (E-mail) получателя.'
      })

      return false
    }

    const docDataToUser = {
      document_ids: [document.id],
      user_company_id: JSON.stringify(documentState.value.map(i => i.key))
    }

    sendDocumentToUser(docDataToUser)
      .then(response => {
        if (response.success) {
          message.success('Сообщение успешно отправлено!')

          getUser()

          setDocumentState({
            ...documentState,
            fetching: false,
            showModal: false,
            isSelectVisible: false
          })
        } else {
          throw new Error(response.error)
        }
      })
      .catch(error => {
        message.error(error.message)
        setDocumentState({ ...defaultDocumentState })
      })
  }

  const handleEditDocumentName = str => {
    if (str === '') {
      message.error('Поле не может быть пустым')

      return null
    }

    updateDocumentById(document.id, { name: str, description: document.description })
      .then(response => {
        if (response.success) {
          message.success('Данные обновлены')
        } else {
          throw new Error(response.error)
        }
      })
      .catch(error => {
        message.error(error.message)
      })
  }

  const handleEditDocumentDescription = str => {
    if (str === '') {
      message.error('Поле не может быть пустым')

      return null
    }

    updateDocumentById(document.id, { name: document.name, description: str })
      .then(response => {
        if (response.success) {
          message.success('Данные обновлены')
        } else {
          throw new Error(response.error)
        }
      })
      .catch(error => {
        message.error(error.message)
      })
  }

  const getEcpCount = arr => {
    if (arr.length) {
      let acpCount = arr.filter(i => i.verification_hash !== null)

      return acpCount.length
    }
  }

  const handleMessageShare = () => {
    api.document.getDocumentLink(match.params.id)
      .then(({ data }) => {
        if (data.success) {
          let link = window.location.protocol + '//' + window.location.host +
            `${'/document/' + data.data.id + '/shared/' + data.data.verification_code}`
          copy2Clipboard(link)
          message.success('Ссылка на переход к просмотру документа:\n' + link)
        } else {
          notification.error({
            message: 'Ошибка получения ссылки'
          })
        }
      })
  }

  const downloadCommentFile = () => {
    let token = window.localStorage.getItem('authToken') || window.sessionStorage.getItem('authToken')
    axios.get(documentState.commentLink, {
      'responseType': 'arraybuffer',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Access-Control-Expose-Headers': 'Content-Disposition,X-Suggested-Filename'
      }
    })
      .then(({ data }) => {
        if (data) {
          fileDownload(data, documentState.commentFile)
          message.success('Файл успешно загружен!')
        }
      })
      .catch(error => {
        message.error(error.message)
      })
  }

  const sendRoamingData = () => {
    setDocumentState({
      ...defaultDocumentState,
      isRoamingModalOpen: true,
      buttonIsFetching: true
    })
    setTimeout( () => {
      setDocumentState({
        ...defaultDocumentState,
        isRoamingModalOpen: false
      })
      notification.success({
        message: 'Cообщение роуминг-провайдеру успешно отправлено'
      })
    }, 3000)
  }

  const selectChange = (e) => {
    setSelectState({ value: e })
  }

  if (!document) {
    return null
  }

  return (
    <LayoutScroll withFooter>
      {singleDocument.id && (
        <Layout>
          {isFetching || fetch ? <div style={{marginLeft: '50%'}}>
            <Spin/>
          </div> : <>
            <Layout.Inner>
              <Header>
                <GoBack />

                <Header.Inner>
                  {(statuses && statuses.length && statuses[0].user_company_document_list_id === 1) ? (
                    <Header.Title
                      level={3}
                      editable={{ onChange: handleEditDocumentName }}
                    >
                      {document.name}
                    </Header.Title>
                  ) : (
                    <Header.Title level={3}>{document.name}</Header.Title>
                  )}

                  <Header.Secondary>
                    {moment.utc(singleDocument.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY HH:mm:ss')}
                  </Header.Secondary>
                </Header.Inner>
              </Header>

              <Details>
                <Details.Inner>
                  <Details.Item>
                    <Details.Label>Получатели:</Details.Label>

                    {recipient && (
                      <Details.Info>
                        <Details.Text>
                          <Tooltip placement='top' title='Написать сообщение'>
                            <Button
                              type='primary'
                              icon='mail'
                              onClick={() => history.push(`/new-document?${queryString.stringify({ contacts: [recipient['user_email']] }, { arrayFormat: 'bracket' })}`)}
                            />
                          </Tooltip>

                          {recipient['user_email']}
                        </Details.Text>

                        <Details.Secondary>{recipient['company_name']}</Details.Secondary>
                      </Details.Info>)}
                  </Details.Item>

                  <Details.Item>
                    <Details.Label>Отправители:</Details.Label>

                    {sender && (
                      <Details.Info>
                        <Details.Text>
                          <Tooltip placement='top' title='Написать сообщение'>
                            <Button
                              type='primary'
                              icon='mail'
                              onClick={() => history.push(`/new-document?${queryString.stringify({ contacts: [sender.user_email] }, { arrayFormat: 'bracket' })}`)}
                            />
                          </Tooltip>

                          {sender.user_email}
                        </Details.Text>

                        <Details.Secondary>{sender.company_name}</Details.Secondary>
                      </Details.Info>)}
                  </Details.Item>
                </Details.Inner>

                <Details.Inner>
                  <Details.Item align='vertical'>
                    <Details.Label>Комментарий:</Details.Label>

                    {(statuses && statuses.length && statuses[0].user_company_document_list_id === 1) ? (
                      <Details.Text editable={{ onChange: handleEditDocumentDescription }}>
                        {document.description}
                      </Details.Text>
                    ) : (
                      <Details.Text>{document.description}</Details.Text>
                    )}
                  </Details.Item>
                </Details.Inner>
              </Details>

              {document && document.attachments && document.attachments.length ? (
                <>
                  {[3, 4].includes(singleDocument.actual_status) && (
                    <Attached>
                      <Table
                        className='ui-table-inside'
                        rowKey={record => record.id}
                        dataSource={document.attachments}
                        pagination={false}
                      >
                        <Column
                          title='Вложения'
                          dataIndex='name'
                          key='name'
                          width={360}
                          render={(name, record, index) => (
                            <Attached.Name>
                              <Tooltip
                                title={name}
                                placement='top'
                                arrowPointAtCenter
                              >
                                <Attached.Name.Text>{`${index + 1}. ${name}`}</Attached.Name.Text>
                              </Tooltip>

                              <Tooltip
                                title='Тип вложения'
                                placement='top'
                                arrowPointAtCenter
                              >
                                <Attached.Name.Type>{record.extension || ''}</Attached.Name.Type>
                              </Tooltip>

                              <Tooltip
                                title='Просмотреть содержимое файла'
                                placement='top'
                                arrowPointAtCenter
                              >
                                <Attached.Action
                                  onClick={() => showModal(record)}
                                  // status={record.extension}
                                >
                                  <Icon type='eye' />
                                </Attached.Action>
                              </Tooltip>

                              <Tooltip
                                title='Просмотреть содержимое файла с подписью'
                                placement='top'
                                arrowPointAtCenter
                              >
                                <Attached.Action
                                  onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}/preview/sign/${record.id}`, '_blank')}
                                  // status={record.extension}
                                >
                                  <Icon type='file-pdf-o' />
                                </Attached.Action>
                              </Tooltip>

                              {hasExternalArchive
                                ? <Tooltip
                                  title='Добавить в электронный архив'
                                  placement='top'
                                  arrowPointAtCenter
                                >
                                  <Attached.Action
                                    onClick={() => visibleZipModal(singleDocument)}
                                    // status={item.extension}
                                  >
                                    <Icon type='file-zip' />
                                  </Attached.Action>
                                </Tooltip>
                                : null}

                              {(singleDocument.actual_status === 3 || singleDocument.actual_status === 4) && (
                                <Tooltip
                                  title='История действий'
                                  placement='top'
                                  arrowPointAtCenter
                                >
                                  <Attached.Action onClick={() => openHistoryModal(record.users_companies)}>
                                    <Icon type='history' />
                                  </Attached.Action>
                                </Tooltip>)}

                              <FileActions
                                file={record}
                                getDocument={() => getDocumentById(match.params.id)}
                                params={{
                                  documentId: singleDocument.document.id,
                                  canBeSigned: singleDocument.can_be_signed,
                                  messageId: singleDocument.actual_status
                                }}
                              />
                            </Attached.Name>
                          )}
                        />

                        <Column
                          title='Тип документа'
                          dataIndex='type_id'
                          key='type_id'
                          render={id => (
                            <Text>
                              {documentTypes.length ? documentTypes.filter(item => item.id === id)[0]?.name : ''}
                            </Text>
                          )}
                        />

                        <Column
                          title='Стоимость'
                          key='cost'
                          dataIndex='cost'
                          render={cost => <Text>{cost || 0} </Text>}
                        />

                        <Column
                          title='Валюта'
                          key='curr'
                          dataIndex='currency'
                          render={curr => <Text>{curr ? curr.name : 'Не указана'}</Text>}
                        />

                        <Column
                          title='Подписать до'
                          dataIndex='should_be_signed_before'
                          key='should_be_signed_before'
                          render={date => <Text>{date ? moment.utc(date, 'YYYY-MM-DD').format('DD.MM.YYYY') : 'Не указана'}</Text>}
                        />

                        <Column
                          title='Статус'
                          dataIndex='status'
                          key='status'
                          render={status => (
                            <Tag color={status !== null ? getAttachmentStatus(status.status_data.id).color : 'lightgray'}>
                              {status ? status.status_data.name : 'Статус неисправен'}
                            </Tag>
                          )}
                        />

                        <Column
                          title='Подписан'
                          dataIndex='users_companies'
                          key='users_companies'
                          align='right'
                          render={companies => (
                            getEcpCount(companies) > 0 && (
                              <Tag
                                color={colors.primary}
                                onClick={() => showUserData('ecp', companies)}
                                style={{ cursor: 'pointer' }}
                              >
                                ЭЦП {getEcpCount(companies)}
                              </Tag>)
                          )}
                        />
                      </Table>
                    </Attached>)
                  }

                  {[2, 11].includes(singleDocument.actual_status) && (
                    document.attachments.map((item, i) => (
                      <Attached key={i}>
                        <Attached.Header>
                          <Attached.Inner>
                            <Attached.Name>
                              <Tooltip
                                title={item.name}
                                placement='top'
                                arrowPointAtCenter
                              >
                                <Attached.Name.Text>{`${i + 1}. ${item.name}`}</Attached.Name.Text>
                              </Tooltip>

                              <Tooltip
                                title='Тип вложения'
                                placement='top'
                                arrowPointAtCenter
                              >
                                <Attached.Name.Type>{item.extension || ''}</Attached.Name.Type>
                              </Tooltip>

                              <Tooltip
                                title='Просмотреть содержимое файла'
                                placement='top'
                                arrowPointAtCenter
                              >
                                <Attached.Action
                                  onClick={() => showModal(item)}
                                  // status={item.extension}
                                >
                                  <Icon type='eye' />
                                </Attached.Action>
                              </Tooltip>

                              <Tooltip
                                title='Просмотреть содержимое файла с подписью'
                                placement='top'
                                arrowPointAtCenter
                              >
                                <Attached.Action
                                  onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}/preview/sign/${item.id}`, '_blank')}
                                  // status={item.extension}
                                >
                                  <Icon type='file-pdf-o' />
                                </Attached.Action>
                              </Tooltip>

                              {hasExternalArchive
                                ? <Tooltip
                                  title='Добавить в электронный архив'
                                  placement='top'
                                  arrowPointAtCenter
                                >
                                  <Attached.Action
                                    onClick={() => visibleZipModal(singleDocument)}
                                    // status={item.extension}
                                  >
                                    <Icon type='file-zip' />
                                  </Attached.Action>
                                </Tooltip>
                                : null}

                              <FileActions
                                file={item}
                                getDocument={() => getDocumentById(match.params.id)}
                                params={{
                                  documentId: singleDocument.document.id,
                                  canBeSigned: singleDocument.can_be_signed,
                                  messageId: singleDocument.actual_status
                                }}
                              />
                            </Attached.Name>

                            <Attached.Tag>
                              <Tag color={getAttachmentStatus(item.status.status_data.id).color}>
                                {item.status.status_data.name}
                              </Tag>
                            </Attached.Tag>
                          </Attached.Inner>

                          <Attached.Inner>
                            <FileActions
                              file={item}
                              getDocument={() => getDocumentById(match.params.id)}
                              params={{
                                documentId: singleDocument.document.id,
                                canBeSigned: singleDocument.can_be_signed,
                                messageId: singleDocument.actual_status
                              }}
                              showList
                            />
                          </Attached.Inner>
                        </Attached.Header>

                        <Attached.Body>
                          <Details noStyle>
                            <Details.Inner>
                              <Details.Item>
                                <Details.Label>Тип:</Details.Label>

                                <Details.Info>
                                  <Details.Text>
                                    {documentTypes.length ? documentTypes.filter(type => type.id === item.type_id)[0]?.name : ''}
                                  </Details.Text>
                                </Details.Info>
                              </Details.Item>

                              <Details.Item>
                                <Details.Label>Стоимость:</Details.Label>

                                <Details.Info>
                                  <Details.Text>{item.cost || 0} {item.currency ? item.currency.name : ''}</Details.Text>
                                </Details.Info>
                              </Details.Item>
                            </Details.Inner>

                            <Details.Inner>
                              <Details.Item>
                                <Details.Label>Подписано:</Details.Label>

                                <Details.Info>
                                  <Details.Text>
                                    {getEcpCount(item.users_companies) > 0 && (
                                      <Tag
                                        color={colors.primary}
                                        onClick={() => showUserData('ecp', item.users_companies)}
                                        style={{ cursor: 'pointer' }}
                                      >
                                        ЭЦП {getEcpCount(item.users_companies)}
                                      </Tag>)}
                                  </Details.Text>
                                </Details.Info>
                              </Details.Item>

                              <Details.Item>
                                <Details.Label>Решение до:</Details.Label>

                                <Details.Info>
                                  <Details.Text>{item.should_be_signed_before ? moment.utc(item.should_be_signed_before, 'YYYY-MM-DD').format('DD.MM.YYYY') : 'Дата не указана'}</Details.Text>
                                </Details.Info>
                              </Details.Item>
                            </Details.Inner>
                          </Details>
                        </Attached.Body>
                      </Attached>
                    ))
                  )}
                </>
              ) : (
                <UploadFiles
                  document_id={singleDocument.document_id}
                  statusId={singleDocument.actual_status}
                />
              )}

            {[1, 9, 10].includes(singleDocument.actual_status) && (document.attachments.length === 0 ? '' : document.attachments.length) && (
              <UploadFiles
                document_id={singleDocument.document_id}
                statusId={singleDocument.actual_status}
              />)}
          </Layout.Inner>

          <FooterFixed>
            <Layout.Actions>
              {singleDocument.actual_status === 1 && (
                <Button
                  type='primary'
                  onClick={() => openModal('send')}
                  icon='redo'
                  ghost
                >
                  Отправить
                </Button>)}

              {singleDocument.document.is_chain === 0 && (
                <Button
                  type='primary'
                  onClick={() => openModal('send')}
                  icon='redo'
                  ghost
                >
                  Отправить повторно
                </Button>)}

              <Button
                type='primary'
                disabled={singleDocument.actual_status === 1}
                onClick={() => handleMessageShare()}
                icon='share-alt'
                ghost
              >
                Поделиться
              </Button>

              <Button
                type='primary'
                disabled={singleDocument.actual_status === 1}
                onClick={() => openRoamingModal()}
                icon='trademark'
                ghost
                hidden
              >
                Роуминг
              </Button>
            </Layout.Actions>

            <Layout.Actions>
              <Button
                type='primary'
                onClick={() => visibleZipModal(singleDocument)}
                icon='file-zip'
                disabled={!hasExternalArchive}
              >
                Добавить в эл архив
              </Button>

              {document && document.attachments && !!document.attachments.length && (
                <DownloadButtons document={document} />)}
            </Layout.Actions>
          </FooterFixed>

          {documentState.isRoamingModalOpen && (
            <Modal
            visible
            footer={null}
            closable={false}
            width={650}
            >

              <p>Выберите службу для взаимодействия</p>
              <Select
                value={selectState.value}
                onChange={(e) => selectChange(e)}
                style={{width: 200, padding: 10, marginRight: 90 }}
                placeholder={'Выберите службу'}
              >
                <Option value={"Mail.com"}>Mail.com</Option>
                <Option value={"Roamings Legacy"}>Roamings Legacy</Option>
                <Option value={"GoodIdea.roam.com"}>GoodIdea.roam.com</Option>
              </Select>

              <Button
                type='primary'
                style={{ marginRight: 20 }}
                icon={documentState.buttonIsFetching ? 'loading' : ''}
                onClick={() => sendRoamingData()}
                disabled={!selectState.value}
              >
                Отправить
              </Button>

              <Button
              onClick={() => closeRoamingModal()}
              >
                Закрыть
              </Button>

            </Modal>
          )}

          {documentState.isZipModalOpen && (
            <Modal
              visible
              footer={null}
              closable={false}
            >
              <Alert
                message='Успешно'
                type='success'
                description='Документ/файл был успешно добавлен в электронный архив'
                showIcon
              />

              <Button
                style={{ marginTop: '2rem' }}
                onClick={() => visibleZipModal(false)}
              >
                Закрыть
              </Button>
            </Modal>)}

          {documentState.isVisible && (
            <ModalViewer
              document={documentState}
              onClose={hideModal}
            />)}

          {documentState.isHistoryModalOpen &&
          <Modal
            visible
            footer={null}
            closable={false}
          >
            <FileHistory history={documentState.fileHistory} />
            <Button onClick={closeHistoryModal}>Закрыть</Button>
          </Modal>
          }
          {documentState.showModal &&
          <Modal
            visible
            closable={false}
            footer={null}
          >
            {documentState.modalType === 'ecp' &&
            <EscDataSlider onCancel={handleCloseModal} data={documentState.fileCerts}/>
            }
            {documentState.modalType === 'comment' &&
            <div>
              <Text>{documentState.comment}</Text><br/>
              <Button  style={{ marginTop: 20 }} disabled={documentState.commentFile == ""} type='primary' onClick={downloadCommentFile}>Скачать вложение</Button>
              <br/>
              <Button style={{ marginTop: 20 }} type='primary' onClick={handleCloseModal}>Закрыть</Button>
            </div>
            }
            {documentState.modalType === 'send' &&
            <Fragment>
              <Text>Получатели:</Text>
              <Select
                mode='tags'
                labelInValue
                tokenSeparators={[',']}
                value={documentState.value}
                filterOption={false}
                notFoundContent={documentState.fetching ? <Spin size='small'/> : null}
                onSearch={fetchUser}
                onChange={handleSelect}
                style={{ width: '100%', margin: '2rem 0 5rem 0' }}
              >
                {documentState.data.map(element => <Option key={element.key}>{element.label}</Option>)}
              </Select>
              <p><strong>Проверьте указанных Вами получателей!</strong></p>
              <Button
                type='primary'
                style={{ marginTop: 20 }}
                onClick={sendToUser}
              >
                Отправить
              </Button>

              <Button
                type='primary'
                style={{ marginLeft: 20 }}
                onClick={() => setDocumentState({ ...documentState, showModal: false })}
                ghost
              >
                Отмена
              </Button>
            </Fragment>
            }

            {documentState.modalType === 'error' &&
            <AvestErrorHandling onCancel={handleCloseModal}/>
            }
          </Modal>
          }
          </>}
      </Layout>)}
    </LayoutScroll>
  )
}

export default SingleDocumentPage
