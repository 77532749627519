import React, { useState, useEffect, useRef } from 'react'
import { withRouter } from 'react-router'
import _ from 'lodash'

import {
  Tooltip,
  Icon
} from 'antd'

import { getActiveCompany } from '../../../../utils'

import { Search } from './styled'

export default withRouter(({
  location,
  processGlobalSearch,
  config,
  user: {
    data
  }
}) => {
  const searchRef = useRef(null)

  const [activeCompany, setActiveCompany] = useState(null)

  useEffect(() => {
    if (data) {
      setActiveCompany(data.hasOwnProperty('active_company_object') && getActiveCompany(data))
    }
  }, [data])

  useEffect(() => {
    processGlobalSearch(null)

    if (searchRef && config.search) {
      searchRef.current.handleReset()
    }
  }, [location.pathname, location.search])

  const handleSearch = _.debounce(value => {
    processGlobalSearch(value)
  }, 500)

  if (!activeCompany) {
    return null
  }

  return (
    <Search>
      <Search.Icon type='search' />

      <Search.Input
        ref={searchRef}
        placeholder='Введите тему или отправителя'
        onInput={e => handleSearch(e.target.value)}
      />

      {activeCompany.tarification.tarification_data.has_global_search ? (
        <Search.Config to='/global-search'>
          <Icon type='setting' />
        </Search.Config>
      ) : (
        <Tooltip placement='bottom' title='Глобальный поиск не доступен на этом тарифе'>
          <Search.Config.Disabled>
            <Icon type='setting' />
          </Search.Config.Disabled>
        </Tooltip>
      )}
    </Search>
  )
})
