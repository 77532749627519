import React, { useEffect } from 'react'
import moment from 'moment'
import queryString from 'query-string'

import {
  Icon,
  Row,
  Col,
  Form,
  Checkbox,
  Avatar,
  Card,
  Input,
  Button,
  Popconfirm
} from 'antd'

import {
  LayoutScroll,
  FooterFixed,
  GoBack
} from '../../components'

import {
  Layout,
  Profile
} from './styled'

export default Form.create()(({
  history,
  getContactById,
  deleteContact,
  updateContact,
  contacts: {
    isFetchingDetails,
    isFetchingUpdate,
    contactDetails
  },
  match,
  form: {
    getFieldDecorator,
    validateFieldsAndScroll
  }
}) => {
  useEffect(() => {
    getContactById(match.params.id, history)
  }, [])

  const handleSave = () => {
    validateFieldsAndScroll((err, values) => {
      if (!err) {
        updateContact(match.params.id, values)
      }
    })
  }

  if (!contactDetails.id || isFetchingDetails) {
    return null
  }

  const companyData = contactDetails.contact_data.company_data
  const userData = contactDetails.contact_data.user_data

  return (
    <LayoutScroll withFooter>
      <Layout>
        <Layout.Inner>
          <Profile>
            <Profile.Inner>
              <GoBack />
              <Avatar size={54} icon='user' />
              <Profile.Code>{+companyData.company_number !== 0 ? `УНП: ${companyData.company_number}` : companyData.name}</Profile.Code>
            </Profile.Inner>

            <Profile.Actions>
              <Popconfirm
                title='Удалить контакт?'
                okText='Да, удалить'
                cancelText='Отмена'
                onConfirm={() => deleteContact(match.params.id, history)}
              >
                <Button icon='delete'>Удалить</Button>
              </Popconfirm>

              <Button
                type='primary'
                onClick={() => history.push(`/new-document?${queryString.stringify({ contacts: [contactDetails.contact_data.user_email] }, { arrayFormat: 'bracket' })}`)}
              >
                Написать контакту
              </Button>
            </Profile.Actions>
          </Profile>

          <Form colon={false}>
            <Form.Item required={false}>
              {getFieldDecorator('is_visible_for_all_companies', {
                valuePropName: 'checked',
                initialValue: contactDetails.is_visible_for_all_companies
              })(
                <Checkbox>Доступен только мне - во всех моих компаниях</Checkbox>
              )}
            </Form.Item>

            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Card
                  type='inner'
                  title='Адрес электронной почты'
                >
                  {userData.email}
                </Card>

                <Card
                  type='inner'
                  title='ФИО/Текстовая метка'
                  extra={contactDetails.temp_name ? <Icon type='edit' /> : null}
                >

                  <Form.Item>
                    {getFieldDecorator('temp_name', {
                      initialValue: contactDetails.temp_name === null
                        ? userData.name + ' ' + (userData.lastname === null ? ' ' : null)
                        : contactDetails.temp_name
                    })(
                      <Input placeholder='Введите имя контакта' />
                    )}
                  </Form.Item>
                </Card>

                {+companyData.company_number === 0 && (
                  <Card
                    type='inner'
                    title='УНП'
                    extra={<Icon type='edit' />}
                  >
                    <Form.Item>
                      {getFieldDecorator('temp_company_number', {
                        initialValue: contactDetails.temp_company_number
                      })(
                        <Input placeholder='Введите УНП контакта' />
                      )}
                    </Form.Item>
                  </Card>)}

                <Card
                  type='inner'
                  title='Краткое наименование'
                  extra={+companyData.company_number === 0 ? <Icon type='edit' /> : null}
                >
                  {+companyData.company_number === 0 ? (
                    <Form.Item>
                      {getFieldDecorator('temp_company_name', {
                        initialValue: contactDetails.temp_company_name
                      })(
                        <Input placeholder='Введите наименование контакта' />
                      )}
                    </Form.Item>
                  ) : companyData.name}
                </Card>

                <Card
                  type='inner'
                  title='GLN'
                >
                  {contactDetails.contact_data.gln_number || 'Отсутствует'}
                </Card>

                <Card
                  type='inner'
                  title='ILN'
                >
                  {contactDetails.contact_data.iln_number || 'Отсутствует'}
                </Card>
              </Col>

              <Col span={12}>
                <Card
                  type='inner'
                  title='Мобильный телефон'
                >
                  {userData.phone}
                </Card>

                <Card
                  type='inner'
                  title='Должность'
                  extra={!contactDetails.contact_data.position ? <Icon type='edit' /> : null}
                >
                  {!contactDetails.contact_data.position ? (
                    <Form.Item>
                      {getFieldDecorator('temp_position', {
                        initialValue: contactDetails.temp_position
                      })(
                        <Input placeholder='Введите должность контакта' />
                      )}
                    </Form.Item>
                  ) : contactDetails.contact_data.position}
                </Card>

                <Card
                  type='inner'
                  title='Статус УНП'
                >
                  -
                </Card>

                <Card
                  type='inner'
                  title='Дата добавления'
                >
                  {moment.utc(contactDetails.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY, HH:mm:ss')}
                </Card>

                <Card
                  type='inner'
                  title='Дата последнего редактирования'
                >
                  {moment.utc(contactDetails.updated_at, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY, HH:mm:ss')}
                </Card>
              </Col>
            </Row>
          </Form>
        </Layout.Inner>

        <FooterFixed>
          <Button
            type='primary'
            icon='search'
            ghost
          >
            Найти переписку
          </Button>

          <Button
            type='primary'
            loading={isFetchingUpdate}
            onClick={handleSave}
          >
            Сохранить изменения
          </Button>
        </FooterFixed>
      </Layout>
    </LayoutScroll>
  )
})
