import { connect } from 'react-redux'
import actions from '../../store/actions'

import CustomDocumentsPage from './CustomDocumentsPage'

const mapStateToProps = state => ({
  user: state.user.data,
  customDocument: state.customDocument
})

const mapDispatchToProps = dispatch => ({
  getCustomDocuments: (params) => dispatch(actions.customDocument.getCustomDocuments(params))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomDocumentsPage)
