import styled from 'styled-components'
import { rgba } from 'polished'

import { Layout } from 'antd'

import { styleguide } from '../../constants'

const { colors } = styleguide

const Header = styled(Layout.Header)`
  width: 100%;
`

Header.Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`

Header.Brand = styled.div`
  width: 24.6rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

Header.Brand.Logo = styled.a`
  width: 14.6rem;
  height: 4rem;
  background: url(${({ src }) => src}) no-repeat center / contain;
  display: block;
`

Header.Params = styled.div`
  width: calc(100% - 28.2rem);
  display: flex;
  align-items: center;
  justify-content: space-between;
`

Header.Params.Inner = styled.div`
  height: 5rem;
  display: flex;
  align-items: center;
`

Header.Company = styled.div`
  max-width: 14.2rem;
  height: 5rem;
  line-height: 5rem;
  background-color: ${rgba(colors.green, 0.15)};
  border-radius: .4rem;
  color: ${colors.green};
  font-weight: 500;
  padding: 0 1.6rem;
  margin-right: 1.2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export {
  Header
}
