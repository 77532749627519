import React, { useEffect, useState } from 'react'

import { LayoutScroll } from '../../components'

import { Details, Header, Layout } from './styled'
import { Card, Input, List, Table, Typography } from 'antd'

import { styleguide } from '../../constants'
import moment from 'moment'
import { api } from '../../services'

const { Column } = Table
const { Text } = Typography
const { colors } = styleguide

const defaultDataState = {
  created_at: '',
  data: {},
  id: 0,
  is_double: 0,
  name: '',
  number: '',
  should_be_signed: 0,
  type: {},
  type_id: 0,
  updated_at: '',
  user_company_id: 0,
  with_vat: 0
}

export default ({
  match,
  customDocument: {
    isFetchingDocument,
    documentDetails
  },
  getCustomDocumentById
}) => {
  const [dataState, setDataState] = useState({ ...defaultDataState })
  useEffect(() => {
    getCustomDocumentById(match.params.id)
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const response = await api.customDocument.getCustomDocumentById(match.params.id)
      setDataState({
        ...dataState,
        data: response.data.data.data,
        type: response.data.data.type,
        created_at: response.data.data.created_at
      })
    }
    fetchData()
  }, [])

  return (
    <LayoutScroll withFooter>
      <Layout>
        <Layout.Header>
          <Layout.Title level={3}>
            {dataState.data.name}
          </Layout.Title>
          <Header.Secondary>
            {moment.utc(dataState.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY HH:mm:ss')}
          </Header.Secondary>
        </Layout.Header>

        <Details>

          <Details.Inner>
            <Details.Item align="vertical">
              <Details.Label>Получатель:</Details.Label>
              <Details.Info>
                <Details.Text>
                  {dataState.data.recipient}
                </Details.Text>
              </Details.Info>
            </Details.Item>

            <Details.Item align="vertical">
              <Details.Label>Отправитель:</Details.Label>
              <Details.Info>
                <Details.Text>
                  {dataState.data.sender}
                </Details.Text>
              </Details.Info>
            </Details.Item>
          </Details.Inner>

          <Details.Inner>
            <Details.Item align="vertical">
              <Details.Label>Комментарий: </Details.Label>
              <Details.Text>
                {dataState.data.sender_comm}
              </Details.Text>
            </Details.Item>

            <Details.Item align="vertical">
              <Details.Label>Комментарий: </Details.Label>
              <Details.Text>
                {dataState.data.recipient_comm}
              </Details.Text>
            </Details.Item>
          </Details.Inner>

        </Details>


        <div style={{ display: 'flex', marginBottom: '20px' }}>
          <div style={{ width: '50%', paddingRight: '10px' }}>
            <label>Номер</label>
            <Input value={dataState.data.number} readOnly/>
          </div>
          {dataState.type.name === 'Акт' && (
            <div style={{ width: '50%', paddingLeft: '10px' }}>
              <label>Тип документа</label>
              <Input value={dataState.type.name} readOnly/>
            </div>
          )}
        </div>


        <div style={{ marginBottom: '20px' }}>
          <label>Основание</label>
          <Input value={dataState.data.send_description} readOnly/>
        </div>

        {!dataState.data.with_vat && (
          <div style={{ marginBottom: '20px' }}>
            <label>Причина отсутствия НДС</label>
            <Input value={dataState.data.vat_comment} readOnly/>
          </div>)}

        <>
          {dataState.data.fields && (
            <Card title="Оказанные услуги">
              <List
                grid={{ gutter: 16, column: 4 }}
                // style={{maxWidth: 800}}
                split>
                {dataState.data.fields.map(field => {
                  return (
                    <List.Item>
                      <Card type="inner" title={field.field_title} size="small" style={{ minWidth: '350px' }}
                            hoverable={false}>
                        <div><b>Количество единиц:</b> {field.field_value} {field.field_postfix}</div>
                        <div><b>Цена за 1 {field.field_postfix}:</b> {field.field_price} {field.field_currency}</div>
                        {field.vat && (<div><b>Налог:</b> {field.vat}</div>)}
                        {/*{field.field_currency && (<p><b>Валюта:</b> {field.field_currency}</p>)}*/}
                      </Card>
                    </List.Item>)
                })}
              </List>
            </Card>
          )
          }
        </>

      </Layout>
    </LayoutScroll>
  )
}
