import styled from 'styled-components'

import { styleguide } from '../../constants'

const { colors } = styleguide

const Layout = styled.div`
  padding: 2rem;

  .ant-form {
    margin: 2.4rem 0;
  }
`

Layout.Inner = styled.div`
  .ant-row {
    &.ant-form-item {
      margin-bottom: 1.2rem;
    }
  }

  .ant-card {
    &-type-inner {
      margin-top: 1.6rem;

      .ant-row {
        &.ant-form-item {
          margin: .8rem 0;
        }
      }
    }
  }
`

Layout.Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Profile = styled.div`
  border-bottom: .1rem solid ${colors.gray.middle};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2rem;
  margin-bottom: 2rem;

  .ant-avatar {
    background: ${colors.gray.middle};
  }
`

Profile.Inner = styled.div`
  display: flex;
  align-items: center;
`

Profile.Actions = styled.div`
  display: flex;
  align-items: center;

  .ant-btn {
    margin-left: 1.6rem;
  }
`

Profile.Code = styled.div`
  background-color: ${colors.green};
  border-radius: .4rem;
  color: ${colors.white};
  font-size: 1.6rem;
  padding: .4rem 1.6rem;
  margin-left: 2rem;
`

export {
  Layout,
  Profile
}
