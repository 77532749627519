import React, { useEffect, useState } from 'react'
import axios from 'axios'
import moment from 'moment'

import {
  Table,
  Icon,
  List,
  Modal,
  Tag,
  Tooltip
} from 'antd'

import {
  DownloadButtons,
  FileActions
} from '../SingleDocumentPage/internal'

import {
  LayoutScroll,
  AvestErrorHandling,
  ModalViewer,
  EscDataSlider,
  FooterFixed
} from '../../components'

import {
  Layout,
  Header,
  Details,
  Attached
} from './styled'

const defaultDocumentState = {
  isVisible: false,
  fileLink: '',
  fileType: '',
  showModal: false,
  data: [],
  value: [],
  fetching: false,
  modalType: 'ecp',
  base64files: '',
  certs: '',
  fileHashes: '',
  fileData: '',
  fileCerts: [],
  activeFileCert: 0,
  ecpInfo: null,
  isSelectVisible: false,
  isErrorWitchEcp: false,
  declineMessage: '',
  singleFile: null
}

const { Column } = Table

const SingleSharedDocumentPage = props => {
  const [message, setMessage, match] = useState(null)

  useEffect(() => {
    const { id, code } = props.match.params

    axios.get(`${process.env.REACT_APP_BASE_URL}/document/${id}/shared/${code}`)
      .then(({ data }) => {
        if (data.success) {
          setMessage(data.data)
        }
      })
  }, [])

  const [documentState, setDocumentState] = useState({ ...defaultDocumentState })

  const getEcpCount = arr => {
    if (arr.length) {
      let acpCount = arr.filter(i => i.verification_hash !== null)
      return acpCount.length
    }
  }

  const showModal = item => {
    let token = window.localStorage.getItem('authToken') || window.sessionStorage.getItem('authToken')
    let path = token ? item['preview_path'] : item['preview_path'] + `?shared_token=${btoa(process.env.REACT_APP_SECRET_KEY)}`
    axios.get(path, {
      'responseType': 'arraybuffer',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Access-Control-Expose-Headers': 'Content-Disposition,X-Suggested-Filename'
      }
    })
      .then(response => {
        const blob = new window.Blob([response.data], { type: 'application/pdf' })
        const blobURL = window.URL.createObjectURL(blob)
        const fileType = response.headers['content-type'].split('/').pop()

        setDocumentState({
          ...documentState,
          isVisible: true,
          fileLink: blobURL,
          fileType: fileType
        })
      })
      .catch(error => {
        message.success('Система создает копию для предпросмотра. Пожалуйста подождите!')
      })
  }

  const showUserData = (type, arr = []) => {
    const dataArray = arr.filter(i => i.verification_hash)
    if (type === 'ecp') {
      setDocumentState({
        ...documentState,
        showModal: true,
        modalType: type,
        fileCerts: dataArray
      })
    }
  }

  const hideModal = () => {
    setDocumentState({
      ...documentState,
      isVisible: false
    })
  }

  const handleCloseModal = () => {
    setDocumentState({
      ...defaultDocumentState
    })
  }

  if (!message) {
    return null
  }

  return (
    <LayoutScroll withFooter>
      <Layout>
        <Layout.Inner>
          <Header>
            <Header.Inner>
              <Header.Title level={3}>{message.document && message.document.name}</Header.Title>

              <Header.Secondary>
                {moment.utc(message.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY HH:mm:ss')}
              </Header.Secondary>
            </Header.Inner>
          </Header>

          <Details>
            <Details.Inner>
              <Details.Item>
                <Details.Label>Получатели:</Details.Label>

                {message.recipient && (
                  <Details.Info>
                    <Details.Text>{message.recipient['user_email']}</Details.Text>
                    <Details.Secondary>{message.recipient['company_name']}</Details.Secondary>
                  </Details.Info>)}
              </Details.Item>

              <Details.Item>
                <Details.Label>Отправители:</Details.Label>

                {message.sender && (
                  <Details.Info>
                    <Details.Text>{message.sender.user_email}</Details.Text>
                    <Details.Secondary>{message.sender.company_name}</Details.Secondary>
                  </Details.Info>)}
              </Details.Item>
            </Details.Inner>

            <Details.Inner>
              <Details.Item align='vertical'>
                <Details.Label>Комментарий:</Details.Label>
                <Details.Text>{message.document && message.document.description}</Details.Text>
              </Details.Item>
            </Details.Inner>
          </Details>

          {message.document.attachments && (
            <Attached>
              <Table
                className='ui-table-inside'
                rowKey={record => record.id}
                dataSource={message.document.attachments}
                pagination={false}
              >
                <Column
                  title='Вложения'
                  dataIndex='name'
                  key='name'
                  width={360}
                  render={(name, record, index) => (
                    <Attached.Name>
                      <Tooltip
                        title={name}
                        placement='top'
                        arrowPointAtCenter
                      >
                        <Attached.Name.Text>{`${index + 1}. ${name}`}</Attached.Name.Text>
                      </Tooltip>

                      <Attached.Name.Type>{record.extension || ''}</Attached.Name.Type>

                      <Tooltip
                        title='Просмотреть содержимое файла'
                        placement='top'
                        arrowPointAtCenter
                      >
                        <Attached.Action onClick={() => showModal(record)}>
                          <Icon type='eye' />
                        </Attached.Action>
                      </Tooltip>

                      <Tooltip
                        title='Просмотреть содержимое файла с подписью'
                        placement='top'
                        arrowPointAtCenter
                      >
                        <Attached.Action onClick={
                          () =>
                            window.open(`${process.env.REACT_APP_BASE_URL}/preview/sign/${record.id}?shared_token=${btoa(process.env.REACT_APP_SECRET_KEY)}`,
                              '_blank')}>
                          <Icon type='file-pdf-o' />
                        </Attached.Action>
                      </Tooltip>

                      <FileActions
                        file={record}
                        getDocument={() => props.getDocumentById(match.params.id)}
                        params={{
                          documentId: message.document.id,
                          canBeSigned: message.can_be_signed,
                          messageId: message.status_id
                        }}
                      />
                    </Attached.Name>
                  )}
                />
              </Table>
            </Attached>)}
        </Layout.Inner>

        {message.document && message.document.attachments && (
          <FooterFixed>
            {!!message.document.attachments.length && (
              <DownloadButtons document={message.document} />)}
          </FooterFixed>)}

        {documentState.isVisible && (
          <ModalViewer
            document={documentState}
            onClose={hideModal}
          />)}

        {documentState.showModal && (
          <Modal
            visible
            closable={false}
            footer={null}
          >
            {documentState.modalType === 'ecp' && (
              <EscDataSlider
                onCancel={handleCloseModal}
                data={documentState.fileCerts}
              />
            )}

            {documentState.modalType === 'error' && (
              <AvestErrorHandling onCancel={handleCloseModal} />
            )}
          </Modal>)}
      </Layout>
    </LayoutScroll>
  )
}

export default SingleSharedDocumentPage
