import * as user from './user'
import * as document from './document'
import * as customDocument from './customDocument'
import * as documents from './documents'
import * as companies from './companies'
import * as files from './files'
import * as config from './config'
import * as contacts from './contacts'
import * as attachments from './attachments'

export default {
  user,
  document,
  customDocument,
  documents,
  companies,
  files,
  config,
  contacts,
  attachments,
}
