import styled from 'styled-components'

const Layout = styled.div`
`

Layout.Inner = styled.div`
  display: flex;
  flex-direction: column;
`

Layout.Details = styled.div`
  width: 44rem;
  font-size: 1.6rem;
  margin-bottom: 3.2rem;
  text-align: center;
`

export {
  Layout
}
