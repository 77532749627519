import { connect } from 'react-redux'

import actions from '../../store/actions'

import MainMenu from './MainMenu'

const mapStateToProps = state => ({
  user: state.user,
  unreadCount: state.documents.unreadCount,
  config: state.config,
  documentsStatuses: state.documents.documentsStatuses,
})

const mapDispatchToProps = dispatch => ({
  getDocumentsUnreadCount: () => dispatch(actions.documents.getDocumentsUnreadCount()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainMenu)
