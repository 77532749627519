import React, { useEffect, useState } from 'react'
import history from '../../../../history'

import {
  Modal,
  Input,
  notification
} from 'antd'

import { Button } from '../../../'

export default ({
  id,
  visible,
  onCancel,
  createMessage,
  sendDocumentToUser,
  updateDocumentById
}) => {
  const [messageId, setMessageId] = useState(null)
  const [fieldText, setFieldText] = useState(null)

  useEffect(() => {
    if (visible) {
      createMessage({ name: '[Без темы]', description: '' })
        .then(({ success, data }) => {
          if (success) {
            setMessageId(data.id)
          }
        })
    }
  }, [visible])

  const handleSend = () => {
    if (!messageId) {
      return
    }

    updateDocumentById(messageId, {
      name: fieldText || 'Без темы',
      user_company_ids: JSON.stringify([id]),
      description: ''
    })
      .then(({ data }) => {
        sendDocumentToUser({
          document_ids: [messageId],
          user_company_id: JSON.stringify([id])
        }).then(({ success }) => {
          if (success) {
            notification['success']({
              message: 'Ваше сообщение успешно отправлено'
            })

            history.push({ pathname: '/documents', search: '?status=3', state: { id: '/documents/3' } })
          }
        })

        return data
      })
      .catch(error => console.error(error))
  }

  return (
    <Modal
      title='Пригласить контакт'
      visible={visible}
      closable={false}
      footer={
        <>
          <Button type='primary' onClick={handleSend}>
            Отправить сообщение
          </Button>

          <Button onClick={onCancel}>Закрыть</Button>
        </>
      }
    >
      <Input.TextArea
        placeholder='Напишите текст приглашения или оставьте поле пустым'
        rows={4}
        onChange={e => setFieldText(e.target.value)}
      />
    </Modal>
  )
}
