import * as t from '../types'

import { api } from '../../services'

import { message } from 'antd'

const getContacts = params => dispatch => {
  dispatch({
    type: t.GET_CONTACTS_FETCHING,
    payload: true
  })

  return api.contacts.getContacts(params)
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: t.GET_CONTACTS_SUCCESS,
          payload: data.data
        })
      }

      dispatch({
        type: t.GET_CONTACTS_FETCHING,
        payload: false
      })

      return data
    })
}

const getContactById = (id, history) => dispatch => {
  dispatch({
    type: t.GET_CONTACT_BY_ID_FETCHING,
    payload: true
  })

  return api.contacts.getContactById(id)
    .then(({ data }) => {
      if (data.data) {
        dispatch({
          type: t.GET_CONTACT_BY_ID_SUCCESS,
          payload: data.data
        })
      } else {
        dispatch({
          type: t.GET_CONTACT_BY_ID_SUCCESS,
          payload: {}
        })

        history.push('/contacts')
      }

      dispatch({
        type: t.GET_CONTACT_BY_ID_FETCHING,
        payload: false
      })

      return data
    })
}

const createContact = data => dispatch => {
  dispatch({
    type: t.CREATE_CONTACT_FETCHING,
    payload: true
  })

  return api.contacts.createContact(data)
    .then(() => {
      dispatch({
        type: t.CREATE_CONTACT_FETCHING,
        payload: false
      })
    })
}

const updateContact = (id, data) => dispatch => {
  dispatch({
    type: t.UPDATE_CONTACT_FETCHING,
    payload: true
  })

  return api.contacts.updateContact(id, data)
    .then(({ data }) => {
      if (data) {
        message.success('Контакт успешно обновлен')

        dispatch({
          type: t.UPDATE_CONTACT_SUCCESS,
          payload: id
        })
      }

      dispatch({
        type: t.UPDATE_CONTACT_FETCHING,
        payload: false
      })
    })
}

const deleteContact = (id, history) => dispatch => {
  dispatch({
    type: t.DELETE_CONTACT_FETCHING,
    payload: true
  })

  return api.contacts.deleteContact(id)
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: t.DELETE_CONTACT_SUCCESS,
          payload: id
        })
      }

      history.push('/contacts')

      dispatch({
        type: t.DELETE_CONTACT_FETCHING,
        payload: false
      })
    })
}

const importContacts = (data, history) => dispatch => {
  dispatch({
    type: t.IMPORT_CONTACT_FETCHING,
    payload: true
  })

  return api.contacts.importContacts(data)
    .then(() => {
      dispatch({
        type: t.IMPORT_CONTACT_FETCHING,
        payload: false
      })

      history.push('/contacts')
    })
}

export {
  getContacts,
  getContactById,
  createContact,
  updateContact,
  deleteContact,
  importContacts
}
