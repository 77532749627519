import { connect } from 'react-redux'
import actions from '../../store/actions'

import CustomDocumentCreatePage from './CustomDocumentCreatePage'

const mapStateToProps = state => ({
  customDocument: state.customDocument,
  currency: state.documents.currencies,
})

const mapDispatchToProps = dispatch => ({
  getCustomDocumentTypes: () => dispatch(actions.customDocument.getCustomDocumentTypes()),
  getCustomDocumentFields: id => dispatch(actions.customDocument.getCustomDocumentFields(id)),
  createCustomDocument: body => dispatch(actions.customDocument.createCustomDocument(body)),
  getCurrencies: () => dispatch(actions.document.getCurrenciesList()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomDocumentCreatePage)
