import styled from 'styled-components'
import { Typography } from 'antd'

const { Title } = Typography

const Layout = styled.div`
  padding: 2rem;
`

Layout.Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;

  && {
    flex: 1;

    .ant-input-search {
      margin: 0 2rem;
    }
  }
`

Layout.Title = styled(Title)`
  && {
    margin-bottom: 0;
    white-space: nowrap;
  }
`

Layout.Table = styled.div`
  margin-top: 2rem;
`

export {
  Layout
}
