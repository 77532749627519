import React from 'react'

import {
  Table,
  LayoutScroll,
  PageDescription
} from '../../components'

import { Layout } from './styled'

export default ({
  getDocumentsByActiveCompanyId,
  user: {
    data
  },
  documents: {
    documents,
    isFetching
  },
  location
}) => {
  const params = new URLSearchParams(location.search)
  const status = params.get('status')

  return (
    <LayoutScroll withFooter>
      <Layout>
        <Layout.Inner>
          <Table
            className='ui-table-list'
            tableData={documents}
            loading={isFetching}
            getDocumentsWithParams={getDocumentsByActiveCompanyId}
            activeCompany={data.active_company_id}
            type='attachment'
            status={Number(status)}
          />

          <PageDescription
            // isVisible={(draftDocuments.data && !draftDocuments.data.length)}
            title='В эту папку Вы сможете сохранить черновик любого сообщения.'
            text={['Вы сможете в любой момент продолжить редактирование сообщения и отправить его адресату.']}
          />
        </Layout.Inner>
      </Layout>
    </LayoutScroll>
  )
}
