import styled from 'styled-components'
import { Typography } from 'antd'

const { Title } = Typography

const Layout = styled.div`
  padding: 2rem;

  .ant-table {
    &-body {
      margin: 0 !important;
    }
  }
`

Layout.Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;

  && {
    flex: 1;

    .ant-input-search {
      margin: 0 2rem;
    }
  }
`

Layout.Header.Inner = styled.div`
  display: flex;
  align-items: center;

  .ant-select {
    margin-right: 1.6rem;
  }
`

Layout.Title = styled(Title)`
  && {
    margin-bottom: 0;
    white-space: nowrap;
  }
`

Layout.Table = styled.div`
  margin-bottom: 1.6rem;
`

Layout.Fields = styled.div`
`

Layout.ButtonBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 40vw;
`

Layout.Box = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Column = styled.div`
  .ant-select {
    flex: 1;
    margin-left: .8rem;
  }

  .ant-input-number {
    flex: 1;

    &:not(:first-child) {
      margin-left: .8rem;
    }
  }
`

Column.Inner = styled.div`
  display: flex;
  align-items: center;
  margin-top: .4rem;
`

export {
  Layout,
  Column
}
