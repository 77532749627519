import styled from 'styled-components'

const Layout = styled.div`
  padding: 1.6rem;

  .ant-radio-group {
    width: 100%;

    .ant-radio-button-wrapper {
      width: 100%;
    }
  }
`

Layout.Attrs = styled.div`
  margin: 2.4rem 0 3.2rem 0;
`

Layout.Submit = styled.div`
  display: flex;
  align-items: center;

  .ant-btn {
    margin-right: 2rem;
  }
`

Layout.Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Explorer = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem;
`

Explorer.Picture = styled.div`
  width: 6.4rem;
  height: 6.4rem;
  background: url(${({ src }) => src}) no-repeat center / contain;
  margin-right: 2rem;
`

Explorer.Inner = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`

Explorer.Title = styled.div`
  font-size: 2rem;
`

Explorer.Details = styled.div`
`

export {
  Layout,
  Explorer
}
