import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'

import { Input } from 'antd'

import {
  LayoutScroll,
  Table,
  Button
} from '../../components'

import { getActiveCompany } from '../../utils'

import { Layout } from './styled'

const { Search } = Input

export default ({
  user,
  contacts: {
    isFetchingList,
    isFetchingDelete,
    contacts
  },
  getDocumentsByActiveCompanyId
}) => {
  const history = useHistory()

  const [searchField, setSearchField] = useState(null)

  useEffect(() => {
    if (user.id) {
      const activeCompany = user.hasOwnProperty('active_company_object') && getActiveCompany(user)

      if (!activeCompany.tarification.tarification_data.has_contacts) {
        history.push('/documents?status=2')
      }
    }
  }, [user])

  const handleSearch = _.debounce(value => {
    setSearchField(value)
  }, 500)

  return (
    <LayoutScroll withFooter>
      <Layout>
        <Layout.Header>
          <Layout.Title level={3}>Контакты</Layout.Title>

          <Search
            placeholder='Искать контакт'
            onInput={e => handleSearch(e.target.value)}
          />

          <Button
            type='primary'
            onClick={() => history.push('/contacts-import')}
          >
            Импортировать
          </Button>
        </Layout.Header>

        <Layout.Table>
          <Table
            className='ui-table-list'
            tableData={contacts}
            loading={isFetchingList || isFetchingDelete}
            getDocumentsWithParams={getDocumentsByActiveCompanyId}
            activeCompany={user.active_company_id}
            type='/documents'
            status='contacts'
            localSearch={searchField}
          />
        </Layout.Table>
      </Layout>
    </LayoutScroll>
  )
}
